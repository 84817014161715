.chevron-icon {
  background: none;
  border: none;
  width: 20px;
  box-shadow: none;
}
.side-controls-product-selection {
  position: absolute;
  right: auto;
  bottom: 0;
  display: flex;
  width: 120px;
  height: 200px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-image: linear-gradient(90deg, #f7f7f7 20%, transparent);
}
.bottom-controls-product-selection {
  height: 100%;
  overflow-y: hidden;
  position: relative;
}
.bottom-controls-product-selection-grid {
  overflow-x: scroll;
  overflow-y: hidden;
  display: grid;
  width: 100%;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 124px;
  justify-content: flex-start;
  justify-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  grid-template-columns: auto;
  grid-template-rows: auto;
}
