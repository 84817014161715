.frost-blur {
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
}
::placeholder {
  transition: all 350ms ease;
}
.input:focus::placeholder {
  transform: translate(20px, 0);
  opacity: 0;
}
.textarea:focus::placeholder {
  transform: translate(20px, 0);
  opacity: 0;
}
.customselect {
  width: 70px;
  overflow: hidden;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.customselect select {
  width: 100px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  text-underline-position: under;
}
input,
select {
  -webkit-appearance: none;
}
div#ui-datepicker-div.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  z-index: 2222 !important;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
.ui-button.ui-state-disabled:hover,
.ui-button.ui-state-disabled:active {
  border-radius: 100%;
  background: #ffffff;
  font-weight: normal;
  color: #3f3939;
  border-color: white;
}
.ui-datepicker {
  width: 300px;
  height: auto;
  margin: 0;
  padding: 10px;
  font: 12pt, inherit;
  font-family: inherit;
  border-radius: 10px;
  border: 10px !important;
  -webkit-box-shadow: 0px 10px 40px -15px rgba(0, 50, 70, 0.3);
  -moz-box-shadow: 0px 10px 40px -15px rgba(0, 50, 70, 0.3);
}
.ui-datepicker table {
  width: 100%;
}
.ui-datepicker-header {
  background: #ffffff;
  color: #3f3939;
  border: none;
}
.ui-datepicker-title {
  text-align: center;
  font-size: 15px;
}
.ui-datepicker-prev {
  float: left;
  cursor: pointer;
  background-position: center -30px;
}
.ui-datepicker-next {
  float: right;
  cursor: pointer;
  background-position: center 0px;
  border-radius: 0;
}
.ui-datepicker-prev {
  float: left;
  cursor: pointer;
  background-position: center -30px;
  border-radius: 0;
}
.ui-datepicker-next:hover,
.ui-datepicker-prev:hover {
  background: #fff;
  border: none;
}
.ui-datepicker thead {
  background-color: #ffffff;
  /*border-bottom: 1px solid #bbb;*/
}
.ui-datepicker .ui-datepicker-title {
  text-transform: uppercase;
}
.ui-datepicker th {
  background-color: #ffffff;
  text-transform: uppercase;
  font-size: 8pt;
  color: #3f3939;
  border-radius: 100%;
  /*text-shadow: 1px 0px 0px #fff;*/
  /*filter: dropshadow(color=#fff, offx=1, offy=0);*/
}
.ui-datepicker tbody td {
  padding: 0;
  /*border-right: 1px solid #808080;*/
}
.ui-datepicker tbody td:last-child {
  border-right: 0px;
}
.ui-datepicker tbody tr {
  border-bottom: none;
}
.ui-datepicker tbody tr:last-child {
  border-bottom: 0px;
}
.ui-datepicker a {
  text-decoration: none;
}
.ui-datepicker td span,
.ui-datepicker td a {
  display: inline-block;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 2.4em;
  color: #ffffff;
  /*text-shadow: 1px 1px 0px #fff;*/
  /*filter: dropshadow(color=#fff, offx=1, offy=1);*/
}
.ui-datepicker-calendar .ui-state-default {
  background: #ffffff;
  color: #3f3939;
  height: 40px;
  width: 40px;
}
.ui-datepicker-calendar .ui-state-hover {
  background: #3f3939;
  color: #ffffff;
  -webkit-transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ui-datepicker-calendar .ui-state-active {
  background: #e2d7d7;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #3f3939;
  text-shadow: none;
  border: none;
  position: relative;
  margin: -1px;
}
.ui-datepicker-unselectable .ui-state-default {
  background: #ffffff;
  color: #ffffff;
}
.ui-icon.ui-icon-circle-triangle-w {
  background: url(https://uploads-ssl.webflow.com/5e22988a0125d4c531c22977/5e24c66fcc9ad780ee4b9c2e_ic_chevron_left_24px.svg)
    no-repeat center center;
}
.ui-icon.ui-icon-circle-triangle-e {
  background: url(https://uploads-ssl.webflow.com/5e22988a0125d4c531c22977/5e24c676945b296930625b4b_ic_keyboard_arrow_right_24px.svg)
    no-repeat center center;
}
