.dropdown {
  width: 100%;
  height: auto;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: all 70ms;
  display: block;
  position: relative;
}

.dropdown-list {
  min-width: 167px;
  margin-top: 10px;
  padding: 15px 0px 0px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  left: 50px;
  top: -124px;
  right: auto;
  bottom: auto;
  min-width: 167px;
  font-size: 12px;
  position: absolute;
}
