::placeholder {
  transition: all 350ms ease;
}
.text-input-2:focus::placeholder {
  transform: translate(20px, 0);
  opacity: 0;
}
.area:focus::placeholder {
  transform: translate(20px, 0);
  opacity: 0;
}

.text-input:focus::placeholder {
  transform: translate(20px, 0);
  opacity: 0;
}
.text-input:focus::placeholder {
  transform: translate(20px, 0);
  opacity: 0;
}
