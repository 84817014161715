.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.grid {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 215px;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 7px -1px rgba(0, 0, 0, 0.13);
}

.grid-sidebar {
  position: relative;
  padding: 20px 0px 0px 15px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-sidebar._2 {
  padding-left: 0px;
}

.sidebar-button {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 9px 5px 9px 15px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 3px solid #fff;
  border-radius: 15px 0px 0px 15px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-decoration: none;
}

.sidebar-button:hover {
  padding-left: 25px;
  border-right-color: silver;
  color: #333;
}

.sidebar-button.w--current {
  border-style: none solid none none;
  border-width: 1px 3px 1px 1px;
  border-color: #000 #fff #000 #000;
  background-color: #ddd;
  color: #333;
}

.sidebar-button.notification:hover {
  border-right-color: transparent;
}

.sidebar-button.logout:hover {
  border-right-color: #f5222d;
  color: #f5222d;
}

.div-block {
  display: block;
  padding-left: 10px;
}

.body {
  background-color: #f7f7f7;
  font-family: 'Mark simonson proxima nova alt', sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.logo-img {
  width: 42px;
  margin-right: 0px;
  padding-bottom: 15px;
}

.text-span {
  font-weight: 600;
}

.text-logo {
  font-size: 26px;
  letter-spacing: -0.5px;
}

.icon {
  width: 22px;
  height: 22px;
}

.icon.small {
  width: 18px;
  height: 18px;
}

.icon.small.left-margin {
  margin-left: 15px;
}

.icon.small.right-margin {
  margin-right: 10px;
}

.icon.small.x2 {
  width: 16px;
  height: 16px;
}

.icon.small.x2.right-margin.less {
  margin-right: 5px;
}

.icon.small.x2.top-margin {
  margin-top: 1px;
}

.icon.smaller {
  width: 13px;
  height: 13px;
}

.icon.smaller.left-margin {
  margin-left: 10px;
  cursor: pointer;
}

.icon.smaller.right-margin {
  margin-right: 5px;
}

.icon.smaller.right-margin.supermini {
  width: 10px;
  height: 10px;
  margin-top: -6px;
  margin-right: 3px;
  flex-shrink: 0;
}

.icon.right-margin {
  margin-right: 10px;
}

.icon.mini {
  width: 13px;
  height: 13px;
  margin-right: 5px;
}

.icon.mini.margin-left.opacity70 {
  width: 13px;
  height: 13px;
  margin-top: -3px;
  margin-left: 5px;
  opacity: 0.7;
}

.icon.mini.no-margin {
  margin-right: 0px;
}

.icon.big {
  width: 52px;
  height: 52px;
}

.line-divider {
  width: 100%;
  height: 1px;
  background-color: hsla(0, 0%, 75.3%, 0.51);
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.img-order-summary-container {
  position: relative;
}

.div-block-8 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #85c737;
  box-shadow: none;
}

.grid-main-container {
  overflow: hidden;
  width: 100%;
  max-height: 100vh;
  padding-right: 0px;
  padding-left: 0px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.grid-container-controls {
  display: -ms-grid;
  display: grid;
  padding: 0px 0px 0px 20px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-container-controls.no-padding {
  padding-left: 0px;
}

.search {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.search.tab {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input {
  height: 50px;
  min-width: 230px;
  margin-bottom: 0px;
  padding-left: 0px;
  border: 1px none #000;
  border-radius: 10px;
  background-color: transparent;
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}

.input::-webkit-input-placeholder {
  color: #a7a7a7;
}

.input:-ms-input-placeholder {
  color: #a7a7a7;
}

.input::-ms-input-placeholder {
  color: #a7a7a7;
}

.input::placeholder {
  color: #a7a7a7;
}

.search-button {
  position: static;
  left: 3px;
  top: auto;
  right: auto;
  bottom: 4px;
  width: 45px;
  height: 45px;
  margin-right: 0px;
  border-radius: 10px;
  background-color: transparent;
  background-image: url('../images/magnifying-glass-bold-2.svg');
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
  opacity: 0.45;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: transparent;
}

.search-button:hover {
  position: static;
  margin-right: 0px;
  background-color: transparent;
  opacity: 1;
}

.link-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #333;
  box-shadow: 0 1px 3px -3px #000;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

.link-block-3:hover {
  background-color: #202020;
}

.link-block-3.green {
  background-color: #45ab54;
}

.link-block-3.green.shadow {
  background-color: #333;
  box-shadow: 0 10px 15px -6px rgba(0, 0, 0, 0.2);
  color: #fff;
}

.link-block-3.green.shadow:hover {
  background-color: #202020;
}

.sub-container-cards {
  display: -ms-grid;
  display: grid;
  padding-bottom: 60px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.main-cards-container {
  overflow: scroll;
  width: 100%;
  height: 88.3vh;
  min-height: auto;
  padding: 20px;
}

.order-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.order-card.yellow {
  background-color: #fdcc0d;
}

.order-card.yellow {
  background-color: #fdcc0d;
}

.image-5 {
  width: 100%;
  height: 180px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-detail-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.div-block-15 {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
}

.title-info-card {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;
}

.title-info-card:hover {
  color: #333;
}

.title-info-card.center {
  text-align: center;
}

.grid-6 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 4px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #333;
  font-size: 11px;
  font-weight: 600;
}

.grid-6.white {
  color: #fff;
}

.grid-6.date-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  line-height: 100%;
}

.divider {
  width: 100%;
  height: 10px;
}

.divider._24px {
  height: 24px;
}

.container-picture-card {
  position: relative;
  border-radius: 10px;
}

.link-card {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: hsla(0, 0%, 100%, 0.5);
  background-image: url('../images/ond.svg');
  background-position: 0px 0px;
  background-size: auto;
  opacity: 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-decoration: none;
}

.link-card:hover {
  background-color: hsla(0, 0%, 100%, 0.85);
  opacity: 1;
}

.grid-7 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-column-gap: 10px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #333;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.lottie-animation {
  width: 26px;
  height: 26px;
}

.overflow-menu__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 0px 1rem;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: 'Mark simonson proxima nova', sans-serif;
  color: #333;
  font-size: 12px;
  text-decoration: none;
}

.overflow-menu__link:hover {
  background-color: #ddd;
  color: #171717;
}

.overflow-menu__link.overflow-menu__link-last {
  margin-top: 0px;
  padding-bottom: 1px;
  border-top: 1px solid #dcdcdc;
  -webkit-transition-duration: 200ms;
  transition-duration: 200ms;
  color: #a7a7a7;
}

.overflow-menu__link.overflow-menu__link-last:hover {
  background-color: #dd4044;
  color: #fff;
}

.overflow-menu__wrap {
  position: absolute;
  left: auto;
  top: 5px;
  right: 5px;
  bottom: auto;
  z-index: 4;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 15px;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #565656;
  cursor: pointer;
}

.overflow-menu__wrap:hover {
  border-radius: 15px;
  background-color: hsla(0, 0%, 100%, 0.75);
}

.overflow-menu__toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 10px;
  background-color: transparent;
  background-image: url('../images/more-vertical-6.svg');
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}

.overflow-menu__toggle:hover {
  background-color: transparent;
}

.overflow-menu__toggle:active {
  background-color: transparent;
}

.overflow-menu__toggle:focus {
  background-color: transparent;
}

.overflow-menu__toggle.w--open {
  position: static;
  z-index: 10;
  border-radius: 15px 15px 0px 0px;
  background-color: #f5f5f5;
  background-image: url('../images/more-vertical-6.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  -webkit-filter: none;
  filter: none;
}

.overflow-menu__toggle.w--open:hover {
  border-radius: 15px 15px 0px 0px;
  background-color: #f5f5f5;
  -webkit-filter: none;
  filter: none;
}

.overflow-menu__toggle.frost-blur {
  background-color: hsla(0, 0%, 100%, 0.3);
}

.overflow-menu__toggle.frost-blur.w--open {
  background-color: #f7f7f7;
}

.overflow-menu {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.overflow-menu.w--open {
  overflow: hidden;
  width: 160px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f3f3f3;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.11);
}

.overflow-menu.frost-blur {
  overflow: visible;
  background-color: hsla(0, 0%, 95.3%, 0.3);
}

.overflow-menu.frost-blur.w--open {
  left: -95px;
  z-index: 999;
  overflow: hidden;
  width: 135px;
  padding-top: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  background-color: #f5f5f5;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.11);
  font-weight: 400;
}

.overflow-menu.frost-blur {
  background-color: hsla(0, 0%, 95.3%, 0.3);
}

.overflow-menu.frost-blur.w--open {
  left: -95px;
  z-index: 999;
  overflow: hidden;
  width: 135px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  background-color: #f5f5f5;
  box-shadow: none;
}

.form {
  width: auto;
  height: auto;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.filter-select-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  min-width: auto;
  margin-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 0px;
  background-color: transparent;
  font-weight: 400;
  cursor: pointer;
}

.filter-select-field.w--open {
  min-width: auto;
  border-radius: 5px;
  background-color: #fff;
}

.filter-select-field.radius {
  width: 50px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.filter-select-field.radius.filter {
  display: none;
}

.filter-select-field.secondary {
  width: auto;
  height: 44px;
  min-width: 181px;
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 0px;
  border-radius: 10px;
  background-color: #f0f1f3;
  font-size: 12px;
}

.form-block {
  display: none;
  margin-bottom: 0px;
}

.div-block-21 {
  position: static;
  left: auto;
  top: auto;
  right: -250px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  border-radius: 10px;
  background-color: #85c737;
}

.container-notification {
  position: absolute;
  top: 21px;
  right: -240px;
  border-radius: 10px;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.15);
}

.arrow-notification {
  position: absolute;
  left: -5px;
  top: 18px;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 13px;
  height: 14px;
  border-radius: 3px;
  background-color: #85c737;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.link {
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
}

.close-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  opacity: 0.25;
  cursor: pointer;
}

.text-field {
  height: 50px;
  margin-bottom: 0px;
  border: 1px none #000;
  border-radius: 5px;
  background-color: #ddd;
  font-weight: 400;
}

.text-field::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.75);
}

.text-field:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.75);
}

.text-field::-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.75);
}

.text-field::placeholder {
  color: rgba(51, 51, 51, 0.75);
}

.text-field.input {
  padding-left: 12px;
  font-size: 14px;
}

.text-field.input.centered {
  font-weight: 600;
  text-align: center;
}

.div-block-33 {
  display: none;
}

.navbar-2 {
  display: none;
}

.grid-search-bar {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.top-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.top-button.on-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-40 {
  width: 100%;
}

.text-button {
  display: none;
  padding-left: 10px;
}

.loader {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999999;
  display: none;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.lottie-animation-2 {
  width: 75%;
}

.div-block-42 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-list {
  background-color: #b67d7d;
}

.dropdown-list.w--open {
  min-width: 155px;
  margin-top: 10px;
  padding: 15px 0px 0px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
}

.dropdown-list.paper-type.w--open {
  min-width: 220px;
}

.dropdown-list.sort.w--open {
  min-width: 167px;
}

.dropdown-list.date.w--open {
  overflow: hidden;
  min-width: 250px;
  padding-top: 0px;
}

.dropdown-list.filter.w--open {
  overflow: hidden;
  min-width: 250px;
  padding-top: 0px;
}

.dropdown-list.service.w--open {
  min-width: 170px;
}

.dropdown-list.production.w--open {
  left: 50px;
  top: -124px;
  right: auto;
  bottom: auto;
  min-width: 167px;
  font-size: 12px;
}

.dropdown-list.address.w--open {
  min-width: 320px;
}

.dropdown-list.album-options.w--open {
  left: 50px;
  top: -124px;
  right: auto;
  bottom: auto;
  min-width: 167px;
  font-size: 12px;
}

.checkbox-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
  font-weight: 300;
}

.checkbox-field:hover {
  color: #333;
}

.checkbox {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  border-color: #a7a7a7;
}

.checkbox.w--redirected-checked {
  background-color: #333;
  background-size: 11px;
}

.checkbox.checked-simulation {
  border-color: #3898ec;
  background-color: #333;
  background-image: url('../images/check-bold.svg');
  background-position: 50% 50%;
  background-size: 10px;
}

.form-3 {
  display: -ms-grid;
  display: grid;
  height: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.form-block-filter {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
}

.container-info-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  border-top: 1px solid #f7f7f7;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  font-weight: 400;
  cursor: pointer;
}

.container-info-filter:hover {
  background-color: #f7f7f7;
}

.container-info-filter.specific-dates {
  display: none;
  margin-bottom: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f7f7f7;
  border-top-style: none;
  font-weight: 400;
}

.container-info-filter.bg {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
  color: #686868;
}

.container-info-filter.bg:hover {
  color: #202020;
}

.container-info-filter.bg.secondary {
  font-size: 12px;
}

.container-info-filter._1 {
  color: #333;
}

.container-info-filter._1:hover {
  background-color: #333;
  color: #fff;
}

.grid-filters {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.grid-filters.single {
  -ms-grid-columns: auto;
  grid-template-columns: auto;
}

.grid-vertical-line {
  width: 1px;
  height: 100%;
  background-color: #f7f7f7;
}

.icon-2 {
  position: relative;
  margin-top: -2px;
  margin-right: 0px;
  margin-left: 15px;
}

.radio-button {
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  padding-right: 0px;
  border-color: #a7a7a7;
}

.radio-button.w--redirected-checked {
  border-color: #333;
}

.radio-button-field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown {
  margin-right: 0px;
  margin-left: 0px;
  font-weight: 600;
}

.div-block-45 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-45.urgency {
  color: #f14749;
}

.div-block-45.secondary {
  font-size: 12px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #e4e4e4;
}

.dot.framing {
  background-color: #ffec3d;
}

.dot.matting {
  background-color: #f3a622;
}

.dot.mounting {
  background-color: #941dfd;
}

.dot.canvas {
  background-color: #78c71b;
}

.dot.test-prints {
  background-color: #c6119f;
}

.dot.boutique {
  background-color: #e736d6;
}

.dot.bigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}

.dot.bigger.framing {
  background-color: #4cd0b1;
  color: #fff;
}

.dot.bigger.mounting {
  color: #fff;
}

.dot.bigger.mounting.tippy:hover {
  background-color: #941dfd;
  opacity: 1;
}

.dot.bigger.paper {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #858585;
  color: #fff;
  font-size: 10px;
}

.dot.bigger.matting {
  color: #fff;
}

.dot.bigger.api {
  background-color: #d0011c;
}

.dot.bigger.paper {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #858585;
  color: #fff;
  font-size: 10px;
}

.dot.calibration {
  background-color: #37d7ff;
}

.grid-filter-info {
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-filter-info.margin-top {
  margin-top: 20px;
  margin-bottom: 20px;
}

.filter-spec-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6.5px 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #686868;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.filter-spec-div:hover {
  -webkit-filter: contrast(88%);
  filter: contrast(88%);
}

.div-block-47 {
  display: -ms-grid;
  display: grid;
  padding-bottom: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.top-menu-controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-right: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.white-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  color: #333;
  cursor: pointer;
}

.white-button:hover {
  background-color: #fff;
}

.white-button.link-block-3.green.shadow.tippy {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}

.grid-15 {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-title-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  margin-bottom: 3px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}

.div-block-53 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: none;
  padding: 10px;
  border-radius: 10px;
  background-color: hsla(0, 0%, 75.3%, 0.54);
}

.checkbox-label {
  display: none;
}

.checkbox-2 {
  width: 24px;
  height: 24px;
  margin-left: 0px;
  border-color: #a7a7a7;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
}

.checkbox-2.w--redirected-checked {
  border-color: #333;
  background-color: #333;
  background-size: 16px;
}

.checkbox-field-2 {
  margin-bottom: 0px;
  padding-left: 0px;
}

.div-block-54 {
  position: absolute;
  left: auto;
  top: 5px;
  right: 5px;
  bottom: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #fff;
}

.urgent {
  position: absolute;
  left: 10px;
  top: 10px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  height: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  color: #333;
}

.urgent.tippy {
  position: static;
  width: 36px;
  height: 36px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #f14848;
  box-shadow: 0 1px 3px -3px #000;
  opacity: 1;
  color: #fff;
}

.urgent.tippy:hover {
  background-color: #f14848;
}

.div-block-56 {
  position: absolute;
  left: auto;
  top: 0px;
  right: -5px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 16px;
  height: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fefffd;
  box-shadow: 0 1px 3px -2px #000;
  color: #84c737;
  font-size: 10px;
  font-weight: 600;
}

.div-block-58 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-sub-info-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.div-block-60 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: silver;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  cursor: auto;
}

.div-block-60:hover {
  -webkit-filter: brightness(94%);
  filter: brightness(94%);
}

.div-block-60.paid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #84c737;
}

.div-block-60.open {
  background-color: #73d13d;
}

.div-block-60.printing {
  background-color: #3da4ff;
}

.div-block-60.framing {
  background-color: #34da95;
}

.div-block-60.canceled {
  background-color: #ff4d4f;
}

.div-block-60.shipped {
  background-color: #391085;
}

.div-block-60.framing {
  background-color: #4cd0b1;
  cursor: auto;
}

.div-block-60.reprint {
  background-color: #ac1cb1;
}

.toggle-status {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  padding: 0px 7.5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #74d13e;
  color: #fff;
  font-size: 11px;
  line-height: 100%;
  font-weight: 600;
  cursor: pointer;
}

.toggle-status.w--open {
  background-color: #74d13e;
  color: #fff;
}

.toggle-status.canceled {
  background-color: #ff4d4f;
}

.toggle-status.open {
  background-color: #ff4d4f;
}

.toggle-status.paid {
  background-color: #84c737;
}

.dropdown-list-status {
  background-color: #b67d7d;
}

.dropdown-list-status.w--open {
  left: auto;
  right: auto;
  z-index: 1;
  min-width: auto;
  margin-top: 5px;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 0px 0px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.1);
  font-size: 11px;
}

.form-block-2-copy {
  height: 100%;
  margin-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

.form-3-copy {
  display: -ms-grid;
  display: grid;
  height: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.note {
  position: absolute;
  left: 10px;
  top: 10px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  height: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  color: #333;
}

.note.tippy {
  position: relative;
  left: 0px;
  top: 0px;
  width: 36px;
  height: 36px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #333;
  box-shadow: 0 1px 3px -3px #000;
  opacity: 1;
  color: #fff;
}

.note.tippy:hover {
  background-color: #84c737;
}

.note.tippy.admin {
  background-color: #89bb3e;
}

.grid-16 {
  position: absolute;
  left: 10px;
  top: 10px;
  right: auto;
  bottom: auto;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.datepicker {
  height: 38px;
  margin-top: 2.5px;
  margin-bottom: 0px;
  border: 1px none #000;
  border-radius: 5px;
  background-color: #f7f7f7;
  font-size: 12px;
  letter-spacing: 0px;
}

.form-block-5 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.urgency {
  color: #f14749;
  font-weight: 600;
}

.grid-progress-bar {
  width: 100%;
  margin-top: 15px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: auto;
  -ms-grid-columns: 1fr 30px;
  grid-template-columns: 1fr 30px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #686868;
  font-size: 12px;
  font-weight: 600;
}

.grid-progress-bar.tippy {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 30px;
  grid-template-columns: 1fr 30px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-progress-bar._2 {
  min-width: auto;
  margin-top: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: center;
  -ms-grid-columns: 200px 30px;
  grid-template-columns: 200px 30px;
}

.progress-bar {
  overflow: hidden;
  width: 100%;
  height: 10px;
  max-width: none;
  border: 1px none #000;
  border-radius: 10px;
  background-color: #ddd;
}

.bar {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background-color: #858585;
}

.bar._20 {
  width: 20%;
}

.bar._80 {
  width: 80%;
}

.grid-18 {
  margin-top: 15px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: #686868;
  font-size: 12px;
  font-weight: 600;
}

.div-block-64 {
  margin-bottom: 10px;
  font-weight: 400;
}

.div-block-65 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 15px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
}

.form-block-2-copy {
  margin-bottom: 15px;
  padding-right: 0px;
  padding-left: 0px;
}

.form-3-copy {
  display: -ms-grid;
  display: grid;
  height: 100%;
  padding: 20px 15px 5px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-19 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #f7f7f7;
}

.div-block-66 {
  width: 100%;
  height: 1px;
  background-color: #f7f7f7;
}

.div-block-67 {
  position: absolute;
  left: auto;
  top: 5px;
  right: 5px;
  bottom: auto;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: transparent;
  color: #858585;
}

.div-block-67:hover {
  color: #333;
}

.grid-23 {
  height: auto;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-flow: column;
  grid-auto-rows: 18px;
  grid-column-gap: 5px;
  grid-row-gap: 2px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: 18px;
  grid-template-rows: 18px;
  line-height: 100%;
  font-weight: 400;
}

.grid-23.main {
  padding-left: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.grid-23.main._2 {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-flow: column;
  font-size: 14px;
  line-height: 18px;
}

.grid-23.main._2.grey {
  color: #686868;
}

.grid-23.main._2._100 {
  width: 100%;
  grid-auto-rows: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-23.main.align-left {
  padding-left: 0px;
  justify-items: flex-start;
}

.grid-23.main {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.grid-23.main._2 {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-flow: column;
  font-size: 14px;
  line-height: 18px;
}

.grid-23.main._2.centered {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
}

.grid-23.main._2.grey {
  color: #686868;
}

.grid-23.main._2._12px {
  font-size: 12px;
}

.sub-container-orders {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sub-container-orders._2 {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.container-order-block {
  display: block;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
}

.grid-details {
  display: -ms-grid;
  display: grid;
  height: auto;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.grid-details.right {
  display: -ms-grid;
  display: grid;
  justify-items: end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: end;
  -ms-flex-line-pack: end;
  align-content: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.grid-details.right-copy {
  width: 100%;
  height: 100%;
  margin-top: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-details.right {
  justify-items: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-gray {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #858585;
  font-size: 22px;
  line-height: 100%;
  font-weight: 600;
}

.heading-big {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 100%;
  font-weight: 700;
  letter-spacing: 0px;
}

.order-top-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  border-radius: 10px;
  background-color: #fff;
}

.heading-title-2 {
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  border: 1px none #000;
  background-color: transparent;
  font-size: 22px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0px;
}

.heading-title-2::-webkit-input-placeholder {
  color: #202020;
}

.heading-title-2:-ms-input-placeholder {
  color: #202020;
}

.heading-title-2::-ms-input-placeholder {
  color: #202020;
}

.heading-title-2::placeholder {
  color: #202020;
}

.wrapper {
  width: 900px;
  max-width: 900px;
}

.container-topbar-orders {
  padding: 20px 0px;
  border-bottom: 1px none #ddd;
}

.form-5 {
  padding: 0px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.grid-24 {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  justify-items: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-98 {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.field-label-2 {
  margin-bottom: 0px;
  font-weight: 600;
}

.select-field-3-copy {
  width: auto;
  height: auto;
  max-width: 300px;
  min-width: 90px;
  margin-bottom: 0px;
  border: 1px none #000;
  background-color: transparent;
  font-weight: 600;
  text-decoration: none;
}

.select-field-3-copy._2 {
  max-width: none;
}

.div-block-99 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 10px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.div-block-99.bg {
  width: auto;
  min-width: 143px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  background-image: url('../images/chevron-down-15.svg');
  background-position: 90% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}

.div-block-100 {
  width: 1px;
  height: 100%;
  margin-right: 0px;
  margin-left: 0px;
  background-color: rgba(51, 51, 51, 0.18);
  color: rgba(51, 51, 51, 0);
}

._2nd-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #333;
  box-shadow: 0 1px 3px -3px #000;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

._2nd-button.main {
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  border-style: solid;
  border-color: #333;
}

._2nd-button.main.small {
  height: 45px;
  padding-right: 18px;
  padding-left: 18px;
  border-style: solid;
  border-color: #333;
  border-radius: 7.5px;
  box-shadow: none;
}

._2nd-button.main.small.transparent {
  border-style: solid;
  border-color: rgba(51, 51, 51, 0.18);
  background-color: transparent;
  color: #333;
}

._2nd-button.main.smaller {
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 9px 14px;
  border-style: solid;
  border-color: #333;
  border-radius: 7.5px;
  box-shadow: none;
  font-size: 14px;
}

._2nd-button.main.smaller.transparent {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-style: solid;
  border-color: rgba(51, 51, 51, 0.18);
  background-color: transparent;
  color: #333;
  font-size: 12px;
}

._2nd-button.secondary {
  width: auto;
  padding-right: 24px;
  padding-left: 24px;
  border-style: solid;
  border-color: rgba(51, 51, 51, 0.3);
  background-color: transparent;
  color: #333;
}

.status-order {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 65px;
  margin-right: 15px;
  margin-left: 10px;
  padding: 6px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #84c737;
  color: #fff;
  font-weight: 600;
}

.grid-order-icons {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

._3rd-block-container-order {
  padding: 40px 20px;
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
  border-radius: 10px;
  background-color: #fff;
}

.heading-title {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 28px;
  text-decoration: none;
}

.heading-title.right-margin {
  margin-right: 12px;
}

.grid-element-order-summary {
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-flow: column;
  grid-auto-columns: 50px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  -ms-grid-columns: 40px 80px 180px 140px 160px 150px;
  grid-template-columns: 40px 80px 180px 140px 160px 150px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-element-order-summary.head {
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.grid-element-order-summary.head.white {
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.grid-element-order-summary.head.client {
  -ms-grid-columns: 80px 180px 140px 160px 55px;
  grid-template-columns: 80px 180px 140px 160px 55px;
}

.grid-element-order-summary.smaller {
  position: relative;
  padding: 15px 10px;
  -ms-grid-columns: 75px 120px 140px 140px 55px 55px;
  grid-template-columns: 75px 120px 140px 140px 55px 55px;
  border-bottom: 1px solid hsla(0, 0%, 75.3%, 0.36);
}

.grid-element-order-summary.smaller.email {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-grid-columns: 40px 75px 170px 170px 55px 55px;
  grid-template-columns: 40px 75px 170px 170px 55px 55px;
}

.grid-element-order-summary.smaller.number {
  padding-left: 0px;
  -ms-grid-columns: 33px 65px 140px 140px 140px 55px 60px;
  grid-template-columns: 33px 65px 140px 140px 140px 55px 60px;
}

.grid-element-order-summary.head {
  margin-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.grid-element-order-summary.head.white {
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.grid-element-order-summary.head.white.smaller {
  margin-bottom: 5px;
  padding: 0px 10px 0px 0px;
  border-bottom-style: none;
}

.grid-element-order-summary.client {
  -ms-grid-columns: 80px 180px 140px 160px 150px;
  grid-template-columns: 80px 180px 140px 160px 150px;
}

.grid-element-order-summary.client.less {
  -ms-grid-columns: 80px 180px 140px 160px 55px;
  grid-template-columns: 80px 180px 140px 160px 55px;
}

.grid-element-order-summary.unbundled-list {
  -ms-grid-columns: 35px 60px 100px 140px 160px 230px 35px 65px 100px;
  grid-template-columns: 35px 60px 100px 140px 160px 230px 35px 65px 100px;
}

.grid-element-order-summary.admin-order.hide-mobile {
  display: none;
}

.heady {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 65px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.heady.print-details {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

.heady.center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.container-rightside-order-summary {
  margin-top: 24px;
}

.container-rightside-order-summary.no-margin {
  margin-top: 0px;
}

.content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-size: 12px;
  font-weight: 600;
}

.content.print-details {
  overflow: hidden;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content.print-details.unbundled-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.content.center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.img-order-summary {
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  border-radius: 5px;
}

.img-order-summary.smaller {
  width: 65px;
  height: 6px;
  min-height: 65px;
  min-width: 65px;
}

.text-table {
  font-size: 14px;
  font-weight: 400;
}

.text-table.red {
  color: #e05550;
  font-weight: 600;
}

.text-table.price {
  font-weight: 600;
}

.text-table.name {
  font-weight: 600;
}

.text-table.smaller {
  font-size: 12px;
}

.text-table.red {
  color: #e05550;
  font-weight: 600;
}

.text-table._12px {
  font-size: 12px;
  line-height: 16px;
}

.text-table._12px.semi-bold {
  font-weight: 600;
}

.heading-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-container.margin-bottom {
  margin-bottom: 24px;
  padding-left: 0px;
}

.heading-container.aligned-left.no-padding {
  padding-left: 0px;
}

.grid-order-summary-container {
  overflow: scroll;
  height: auto;
  max-height: 59vh;
  padding-bottom: 20px;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.grid-order-summary-container.no-scroll {
  overflow: scroll;
  height: 76vh;
  max-height: none;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-order-summary-container.single {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-order-summary-container.unbundled-list {
  padding-top: 12px;
  grid-row-gap: 36px;
}

.container-order-title {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-details {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 5px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-details._2 {
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: flex-start;
}

.container-details.full {
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 400;
}

.container-details.full.red {
  color: #e05550;
  font-weight: 600;
}

.text-details-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  font-weight: 600;
}

.text-details-container.vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.grid-28 {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-topbar-orders {
  display: -ms-grid;
  display: grid;
  width: 900px;
  max-width: 1000px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.sub-grid-topbar-orders {
  display: -ms-grid;
  display: grid;
  height: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-113 {
  font-weight: 600;
}

.form-block-6 {
  margin-bottom: 0px;
}

.grid-button-topbar-orders {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-29 {
  height: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.checkbox-label-2 {
  display: none;
}

.checkbox-field-3 {
  margin-bottom: 0px;
  padding-left: 0px;
}

.form-block-7 {
  margin-bottom: 0px;
}

.checkbox-4 {
  width: 18px;
  height: 18px;
  margin-top: 0px;
  margin-left: 0px;
  border-color: silver;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.checkbox-4:hover {
  border-width: 2px;
  border-color: #858585;
}

.checkbox-4.w--redirected-checked {
  background-color: #333;
  background-size: 12px;
}

.checkbox-4._2 {
  border-color: #858585;
}

.order-2nd-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px none #ddd;
  border-radius: 10px;
  background-color: #fff;
}

.main-grid-info-order {
  margin-top: 24px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 40px;
  grid-row-gap: 24px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-108-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 5px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-bold {
  margin-bottom: 5px;
}

.grid-info-order {
  max-width: none;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.grid-info-order.centered {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
}

.grid-info-order.centered.margin-top {
  margin-top: 24px;
}

.main-info-block-container {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-orders {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-block-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: 100%;
  max-height: none;
  max-width: 450px;
  min-height: auto;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-style: none;
  border-width: 1px;
  border-color: #f7f7f7;
  border-radius: 10px;
  background-color: transparent;
  font-weight: 400;
}

.grid-button-notes {
  margin-top: 24px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px dashed #ddd;
}

.qr {
  border: 1px solid transparent;
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.qr:hover {
  border-style: solid;
  border-width: 1px;
  border-color: silver;
}

.upgrade-container-2 {
  position: relative;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 450px;
  min-height: 200px;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  padding: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  font-weight: 300;
  text-align: center;
}

.hide-me-overlay-2 {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 8888;
  display: none;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.75);
}

.upgrade-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 50px;
  padding: 0px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  color: #333;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
}

.upgrade-button:hover {
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.text-block-28 {
  margin-top: 0px;
  font-size: 24px;
  line-height: 100%;
  font-weight: 600;
}

.modal-scroll-2 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 50px 50px 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-122 {
  max-width: none;
  min-width: 300px;
  margin-top: 24px;
  margin-bottom: 0px;
}

.div-block-123 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.link-block-5-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  color: #333;
  font-weight: 400;
  text-decoration: none;
}

.grid-24-copy-copy {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  justify-items: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tabs-menu.client {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

.tab-link-tab-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  padding-right: 14px;
  padding-left: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  opacity: 0.85;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #858585;
  font-weight: 400;
}

.tab-link-tab-1:hover {
  opacity: 1;
  color: #333;
}

.tab-link-tab-1.w--current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  padding: 16px 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: none;
  opacity: 1;
  color: #202020;
  font-weight: 600;
}

.tab-link-tab-1.admin {
  color: #e05550;
}

.tab-link-tab-1.boutique {
  color: #e736d6;
}

.link-text {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  font-weight: 600;
  text-decoration: none;
}

.link-text:hover {
  padding-left: 3px;
  color: #858585;
}

.rush {
  position: absolute;
  left: 10px;
  top: 10px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 35px;
  height: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  color: #333;
}

.rush.tippy {
  position: relative;
  left: 0px;
  top: 0px;
  width: 36px;
  height: 36px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #e64a2c;
  box-shadow: 0 1px 3px -3px #000;
  opacity: 1;
  color: #fff;
}

.rush.tippy:hover {
  background-color: #e64a2c;
  opacity: 1;
}

.grid-table-heady {
  padding: 0px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-flow: column;
  grid-auto-columns: 100px;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  -ms-grid-columns: 240px 180px 180px 100px 120px 120px 120px 40px;
  grid-template-columns: 240px 180px 180px 100px 120px 120px 120px 40px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 5px;
}

.grid-table-heady.top {
  margin-bottom: 12px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ddd;
  border-radius: 0px;
}

.block-admin-photographer-table {
  width: 100%;
  padding: 5px 24px 0px;
  border-radius: 0px 0px 10px 10px;
  background-color: #fff;
}

.link-block-7 {
  padding: 5px;
  border-radius: 5px;
  color: #686868;
}

.empty-div {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.overflow-menu-copy {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
}

.overflow-menu-copy.w--open {
  overflow: hidden;
  width: 160px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #f3f3f3;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.11);
}

.overflow-menu-copy.frost-blur-copy {
  overflow: visible;
  background-color: hsla(0, 0%, 95.3%, 0.3);
}

.overflow-menu-copy.frost-blur-copy.w--open {
  left: -135px;
  z-index: 999;
  overflow: hidden;
  width: 180px;
  padding-top: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  background-color: #f5f5f5;
  box-shadow: -7px 9px 11px -3px rgba(0, 0, 0, 0.13);
  font-weight: 400;
}

.overflow-menu__wrap-copy-copy {
  position: relative;
  left: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 4;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 10px;
  background-color: transparent;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #565656;
  cursor: pointer;
}

.overflow-menu__wrap-copy-copy:hover {
  border-radius: 15px;
  background-color: hsla(0, 0%, 100%, 0.75);
}

.status-admin-photographer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4px 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #389d0f;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.status-admin-photographer.unapproved {
  background-color: #e05550;
}

.status-admin-photographer.pending {
  background-color: #d5be3e;
}

.overflow-menu__toggle-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 10px;
  background-color: transparent;
  background-image: url('../images/more-vertical-6.svg');
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}

.overflow-menu__toggle-copy:hover {
  background-color: transparent;
}

.overflow-menu__toggle-copy:active {
  background-color: transparent;
}

.overflow-menu__toggle-copy:focus {
  background-color: transparent;
}

.overflow-menu__toggle-copy.w--open {
  position: static;
  z-index: 10;
  border-radius: 15px 15px 0px 0px;
  background-color: #f5f5f5;
  background-image: url('../images/more-vertical-6.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  -webkit-filter: none;
  filter: none;
}

.overflow-menu__toggle-copy.w--open:hover {
  border-radius: 15px 15px 0px 0px;
  background-color: #f5f5f5;
  -webkit-filter: none;
  filter: none;
}

.overflow-menu__toggle-copy.frost-blur {
  width: 45px;
  height: 45px;
  background-color: transparent;
}

.overflow-menu__toggle-copy.frost-blur:hover {
  background-color: #f7f7f7;
  box-shadow: 0 0 3px -3px #000;
}

.overflow-menu__toggle-copy.frost-blur.w--open {
  background-color: #f7f7f7;
}

.overflow-menu__toggle-copy.frost-blur._2 {
  width: 38px;
  height: 38px;
}

.sub-block-admin-photographer {
  margin-top: 0px;
}

.text-span-2 {
  font-weight: 600;
}

.tab-photographers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: hsla(0, 0%, 86.7%, 0.3);
  color: #686868;
  font-weight: 600;
}

.tab-photographers.w--current {
  background-color: #fff;
  color: #333;
}

.tabs-menu-2 {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tabs-content {
  overflow: visible;
}

.form-7 {
  height: auto;
  padding: 0px 5px;
  border-top: 1px none #ddd;
  border-radius: 5px;
  background-color: #f7f7f7;
  font-family: 'Mark simonson proxima nova', sans-serif;
}

.select-field-2 {
  width: 100%;
  min-width: 100px;
  margin-bottom: 0px;
  border: 1px none #000;
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: 600;
}

.tabs {
  width: 100%;
}

.admin-photographer-subcontainer {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 100%;
  height: auto;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.admin-photographer-subcontainer.big-screen {
  margin-top: 0px;
}

.div-block-130 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #d5be3e;
  color: #fff;
  font-size: 11px;
}

.form-block-container-admin {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-2 {
  width: 100%;
  max-width: 400px;
}

.div-notes {
  height: 100%;
}

.info-block-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-bottom: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.paragraph-3 {
  margin-bottom: 0px;
  color: #686868;
  font-weight: 400;
}

.grid-36 {
  grid-auto-columns: auto;
  grid-row-gap: 5px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.heading-mini-gray {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #858585;
  font-size: 16px;
  line-height: 100%;
  font-weight: 600;
}

.grid-tracking {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 5px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tracking-text {
  height: auto;
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #ddd;
  font-size: 12px;
  font-weight: 600;
}

.div-block-135 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 10px;
  font-weight: 600;
}

.div-block-136 {
  position: relative;
}

.sub-grid-icons-order {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tab-pane-tab-2 {
  width: auto;
  height: 100%;
  max-width: none;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.tab-pane-tab-2.boutique {
  border-top-right-radius: 0px;
}

.tabs-content-2 {
  overflow: scroll;
  height: 289px;
}

.paragraph-container {
  margin-top: 0px;
  padding: 0px;
  border-radius: 0px 10px 10px;
  background-color: #f7f7f7;
}

.paragraph-container.admin {
  color: #e05550;
}

.paragraph-container.boutique {
  color: #e736d6;
}

.grid-bottom-details {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.gray-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-radius: 0px;
  font-size: 12px;
  font-weight: 400;
}

.tab-pane-tab-2-copy {
  width: auto;
  height: 100%;
  max-width: none;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 0px 10px 10px;
  background-color: #f7f7f7;
}

.grid-38 {
  display: none;
}

.div-block-108-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 5px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.tab-link-tab-1-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  padding-right: 14px;
  padding-left: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  opacity: 0.85;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #858585;
  font-weight: 400;
}

.tab-link-tab-1-copy:hover {
  opacity: 1;
  color: #333;
}

.tab-link-tab-1-copy.w--current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 45px;
  padding: 16px 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #f7f7f7;
  box-shadow: none;
  opacity: 1;
  color: #202020;
  font-weight: 600;
}

.tab-link-tab-1-copy.admin {
  color: #e05550;
}

.tab-link-tab-1-copy.boutique {
  color: #e736d6;
}

.print-order-sidebar {
  padding: 15px 15px 0px;
  border-bottom: 1px none #f0f1f3;
  background-color: transparent;
}

.print-order-sidebar.grid {
  position: relative;
  z-index: 2;
  overflow: scroll;
  height: 77.5vh;
  padding: 12px 24px 0px;
}

.print-order-sidebar.grid.absolute {
  position: relative;
  overflow: visible;
  height: auto;
  max-width: 250px;
  min-width: 250px;
  padding-top: 24px;
  padding-bottom: 36px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.tippy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-left: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0%;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;
}

.tippy:hover {
  background-color: transparent;
  opacity: 1;
}

.accordion-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2e323c;
}

.accordion-header.js-accordion-header {
  position: relative;
  height: auto;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px none #f3f3f3;
  border-radius: 0px;
  opacity: 1;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  color: rgba(46, 50, 60, 0.75);
  cursor: pointer;
}

.accordion-header.js-accordion-header:hover {
  border-radius: 0px;
  background-color: transparent;
  color: #2e323c;
}

.accordion-header.js-accordion-header:active {
  background-color: transparent;
}

.accordion {
  display: block;
  width: 66.66%;
  margin-right: auto;
  margin-bottom: -24px;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.accordion.js-accordion {
  width: 100%;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.accordion.js-accordion.margin-top {
  margin-top: 16px;
  margin-bottom: 16px;
}

.accordion__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-bottom: 24px;
  padding: 20px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(46, 50, 60, 0.09);
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
  text-align: left;
  text-decoration: none;
}

.accordion__item:hover {
  box-shadow: 0 3px 9px 0 rgba(46, 50, 60, 0.09);
}

.accordion__item.js-accordion-item {
  overflow: visible;
  margin-bottom: 10px;
  padding: 0px;
  border-style: none;
  border-width: 1px;
  border-color: #000 #000 #f3f3f3;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
  -webkit-transition-property: none;
  transition-property: none;
}

.div-block-150 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.label-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  margin-bottom: 3px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 43px;
  margin-top: 0px;
  padding: 18px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 10px;
  background-color: #202020;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  text-decoration: none;
}

.button-2:hover {
  background-color: #333;
  box-shadow: none;
}

.button-2:active {
  background-color: #202020;
}

.button-2.w--current {
  background-color: #191a24;
  color: #fff;
}

.button-2.secondary {
  border-color: #ddd;
  background-color: transparent;
  color: #686868;
  cursor: pointer;
}

.button-2.secondary:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.button-2.secondary:active {
  background-color: #202020;
}

.button-2.secondary.no-width {
  width: auto;
}

.button-2.secondary.single {
  width: 44px;
  padding: 0px;
}

.button-2.secondary.disabled {
  color: rgba(51, 51, 51, 0.7);
}

.button-2.secondary.disabled:hover {
  border-color: #ddd;
  background-color: transparent;
}

.button-2.disabled {
  opacity: 0.3;
  color: rgba(255, 255, 255, 0.7);
  cursor: not-allowed;
}

.button-2.single {
  width: auto;
}

.accordion-body {
  display: block;
  padding-right: 8px;
  padding-left: 64px;
  color: #9096a4;
}

.accordion-body.js-accordion-body {
  display: block;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  border-radius: 0px;
  color: #9096a4;
}

.accordion-item-content {
  display: block;
  overflow: hidden;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.accordion-item-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 16px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  cursor: pointer;
}

.accordion-item-2:hover {
  color: #333;
}

.bottom-controls-product-selection-sidebar {
  display: -ms-grid;
  display: grid;
  height: 183px;
  padding: 24px;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #f0f1f3;
  background-color: transparent;
}

.line-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 5px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f3f3f3;
}

.line-horizontal.margins {
  margin-top: 16px;
  margin-bottom: 16px;
}

.sidebar-product-selection {
  position: relative;
  width: 350px;
  height: auto;
  border-right: 1px solid #f3f4f5;
  background-color: #fff;
}

.sidebar-product-selection.white {
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 335px;
  height: 100vh;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-left: 1px none hsla(0, 0%, 40.7%, 0.12);
  border-right-style: solid;
  border-right-color: hsla(0, 0%, 40.7%, 0.12);
  background-color: #fff;
  box-shadow: 2px 0 25px -1px rgba(0, 0, 0, 0.04);
  font-weight: 400;
}

.form-select-field {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 90px;
  margin-bottom: 0px;
  padding: 6px 13px 6px 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px none #000;
  border-radius: 10px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #191a24;
  font-size: 12px;
  cursor: pointer;
}

.form-select-field.font-size-14 {
  font-size: 14px;
}

.sub-label-form {
  height: auto;
  margin-bottom: 0px;
  opacity: 1;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
}

.sub-label-form.all-photos {
  margin-right: 5px;
  margin-bottom: 0px;
  margin-left: 5px;
  color: #4bac55;
  font-weight: 900;
}

.sub-label-form.unselected {
  margin-right: 5px;
  margin-bottom: 0px;
  margin-left: 5px;
  color: #e05550;
  font-weight: 900;
}

.sub-label-form.grey {
  color: #686868;
}

.sub-label-form.grey.no-margin {
  margin-top: 0px;
  margin-bottom: 16px;
  line-height: 100%;
}

.sub-label-form.primary {
  padding-right: 6px;
  padding-left: 6px;
  border-radius: 2px;
  background-color: #f0f1f3;
}

.sub-label-form.one-line {
  white-space: pre;
}

.div-block-63 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-block-input {
  width: 100%;
  margin-bottom: 0px;
}

.form-block-input.margin-top {
  margin-top: 16px;
}

.form-block-input.qty {
  max-width: 70px;
}

.form-block-input.disabled {
  opacity: 1;
  cursor: not-allowed;
}

.input-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.input-container.quantity {
  width: 100%;
  max-width: 80px;
}

.input-container.quantity._2 {
  max-width: none;
}

.input-container.paper-type {
  width: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.input-container.add-notes {
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.input-container.paper-type {
  width: 100%;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.input-container.disabled {
  position: relative;
}

.input-container.large {
  width: 160px;
}

.select-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
}

.select-field-wrapper:hover {
  border-color: #3da9fc;
}

.select-field-wrapper.credit-card {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 40px 1fr 60px 50px;
  grid-template-columns: 40px 1fr 60px 50px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  letter-spacing: 0px;
}

.select-field-wrapper.area {
  min-height: 100px;
}

.select-field-wrapper.states {
  padding-right: 0px;
  padding-left: 0px;
}

.select-field-wrapper.select {
  padding-left: 15px;
}

.select-field-wrapper.secondary.filter {
  padding-left: 10px;
  color: #333;
  font-weight: 400;
}

.form-input {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
  padding-left: 5px;
  border-radius: 10px;
  background-color: #f0f1f3;
}

.form-input.select-field {
  overflow: visible;
  height: 44px;
  padding-right: 0px;
  padding-left: 0px;
  border: 2px solid transparent;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}

.form-input.select-field:hover {
  border-width: 2px;
  border-color: #e4ddfe;
}

.form-input.select-field.area {
  height: auto;
  min-height: 100px;
}

.form-input.select-field._2nd-color {
  background-color: #f7f7f7;
}

.form-input.select-field.max-width-100px {
  max-width: 100px;
}

.form-input.select-field.width-300px {
  width: 300px;
}

.img-icon {
  width: 16px;
  padding-top: 2px;
  opacity: 1;
}

.img-icon.margin-left {
  margin-left: 10px;
  padding-top: 0px;
}

.accordion-item-block {
  display: -ms-grid;
  display: grid;
  overflow: visible;
  width: 100%;
  margin-top: 12px;
  margin-right: 0px;
  padding: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.accordion-item-block.no-margin {
  margin-top: 0px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-field-2 {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  padding-right: 0px;
  border: 1px none #000;
  border-radius: 10px;
  background-color: #f0f1f3;
  font-size: 12px;
}

.text-field-2.data-jscolor {
  height: 44px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.text-field-2.area {
  height: auto;
  min-height: 100px;
}

.text-field-2.font-size-14 {
  font-size: 14px;
}

.text-field-2.font-size-14.spaced {
  font-family: Inconsolata, monospace;
  letter-spacing: 1px;
}

.text-field-2.font-size-14.spaced.simulation::-webkit-input-placeholder {
  color: #333;
}

.text-field-2.font-size-14.spaced.simulation:-ms-input-placeholder {
  color: #333;
}

.text-field-2.font-size-14.spaced.simulation::-ms-input-placeholder {
  color: #333;
}

.text-field-2.font-size-14.spaced.simulation::placeholder {
  color: #333;
}

.text-field-2.font-size-14.active::-webkit-input-placeholder {
  color: #202020;
}

.text-field-2.font-size-14.active:-ms-input-placeholder {
  color: #202020;
}

.text-field-2.font-size-14.active::-ms-input-placeholder {
  color: #202020;
}

.text-field-2.font-size-14.active::placeholder {
  color: #202020;
}

.text-field-2.qty {
  text-align: center;
}

.text-field-2.qty::-webkit-input-placeholder {
  color: #333;
}

.text-field-2.qty:-ms-input-placeholder {
  color: #333;
}

.text-field-2.qty::-ms-input-placeholder {
  color: #333;
}

.text-field-2.qty::placeholder {
  color: #333;
}

.grid-form {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-form._1fr {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.grid-form._1fr {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.grid-form.auto {
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
}

.grid-form.auto._1fr-right {
  -ms-grid-columns: 1fr 115px;
  grid-template-columns: 1fr 115px;
}

.grid-form.horizontal {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
}

.grid-form.icons {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: center;
  grid-auto-flow: row;
  grid-auto-columns: auto;
  -ms-grid-columns: auto auto auto auto auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
}

.input-wrapper {
  position: relative;
  overflow: hidden;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 10px;
  background-color: #f0f1f3;
}

.controls-block-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 12px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-align-content: end;
  -ms-flex-line-pack: end;
  align-content: end;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  line-height: 100%;
}

.controls-block-grid.top {
  margin-bottom: 0px;
  padding: 20px 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #f0f1f3;
}

.controls-block-grid.no-margin {
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-block-32 {
  font-size: 24px;
}

.controls-block-container {
  height: auto;
  padding: 0px;
}

.product-selection-main-picture-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 24px 24px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-picture-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100%;
  min-height: auto;
  min-width: 550px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.bottom-controls-product-selection {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 200px;
  max-width: 100%;
  min-height: 200px;
  padding: 24px 0px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px none hsla(0, 0%, 40.7%, 0.12);
  background-color: transparent;
}

.bottom-controls-product-selection.no-margin {
  height: 176px;
  min-height: auto;
  padding-top: 0px;
}

.bottom-controls-product-selection.no-margin.white {
  background-color: #fff;
}

.bottom-controls-product-selection-grid {
  display: -ms-grid;
  display: grid;
  overflow: scroll;
  width: 100%;
  max-width: 70vw;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 124px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.bottom-controls-product-selection-grid.padding-top-5px {
  padding-top: 6px;
}

.picture-element-product-selection {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: auto;
  height: auto;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 5px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.35);
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  cursor: pointer;
}

.picture-element-product-selection:hover {
  border-color: #eee;
  background-color: #fff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #333;
}

.picture-element-product-selection.selected {
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 3px 8px -4px rgba(0, 0, 0, 0.32);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.lightbox-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  width: 90px;
  height: 90px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px none transparent;
  border-radius: 0px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.lightbox-link:hover {
  border-style: none;
  border-color: rgba(61, 169, 252, 0.4);
}

.product-selection-picture-container {
  position: relative;
  display: -ms-grid;
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr auto;
  grid-template-rows: 1fr auto;
}

.top-buttons-image-element {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  justify-items: end;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 8px;
  grid-row-gap: 10px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.top-buttons-image-element.z-index {
  top: 0px;
  right: 0px;
  z-index: 5;
}

.grid-print-order {
  position: relative;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.dropdown-toggle-3 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.icon-3 {
  position: static;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  font-size: 10px;
}

.dropdown-list-3 {
  background-color: #7c5757;
}

.dropdown-list-3.w--open {
  border-top: 1px solid #f7f7f7;
  background-color: #fff;
}

.div-block-169-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.div-block-169-copy.ghost {
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  background-color: transparent;
  box-shadow: none;
  color: #686868;
}

.image {
  display: none;
  width: 120px;
  margin-bottom: 20px;
}

.div-block-5 {
  display: -ms-grid;
  display: grid;
  margin-bottom: 45px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.text-input__field-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-input {
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  padding: 0px 1rem;
  border: 1px none #000;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: inset 0 0 0 2px transparent;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  font-size: 14px;
  line-height: 100%;
}

.text-input:focus {
  border-bottom-color: transparent;
  box-shadow: none;
}

.text-input::-webkit-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input:-ms-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input::-ms-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input::placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.checkbox-6 {
  position: relative;
  left: 0px;
  z-index: auto;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-left: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-color: #282828;
  border-radius: 100%;
  opacity: 1;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.checkbox-6:hover {
  box-shadow: inset 0 0 0 1px #242424;
}

.checkbox-6.w--redirected-checked {
  border-color: #282828;
  background-color: #282828;
  background-image: url('../images/checkmark-icon.svg');
  background-size: 10px;
}

.checkbox-6.w--redirected-focus {
  box-shadow: none;
}

.checkbox-6.small {
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-color: #3f3f3f;
}

.form-item-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}

.form-item-3.centered {
  margin-top: 10px;
  margin-bottom: 25px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.image-89 {
  display: none;
  width: 45px;
  height: 45px;
  padding-right: 10px;
  padding-left: 10px;
}

.form-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.image-66 {
  width: 45px;
}

.eye-2 {
  position: absolute;
  left: auto;
  top: 0px;
  right: 0px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.eye-2:hover {
  opacity: 1;
}

.eye-2:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.checkbox-label-5 {
  margin-bottom: 0px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 150%;
  font-weight: 300;
}

.checkbox-label-5.small {
  padding-left: 7.5px;
  font-size: 12px;
}

.div-block-181 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  min-height: 400px;
  min-width: 300px;
  padding: 60px 60px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.09);
}

.div-full-screen {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100vh;
}

.div-full-screen.white-smoke {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
}

.eye {
  position: absolute;
  left: auto;
  top: 0px;
  right: 0px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0.5;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.eye:hover {
  opacity: 1;
}

.eye:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

.form-block-3 {
  width: 100%;
  margin-bottom: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-block-3.max-width-320px {
  max-width: 320px;
}

.form-item-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
}

.form-item-2.horizontal {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.checkbox-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0rem;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.heading-6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #242424;
  font-size: 32px;
  line-height: 150%;
  font-weight: 300;
  letter-spacing: -0.5px;
}

.main-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  padding: 18px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #181818;
  border-radius: 10px;
  background-color: #181818;
  -webkit-transition: all 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: all 200ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
  color: #fff;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-transform: none;
}

.main-button:hover {
  border-color: #4c4c4c;
  background-color: #4c4c4c;
  -webkit-filter: none;
  filter: none;
  color: #e7e7e7;
}

.main-button:active {
  -webkit-transform: scale(0.96);
  -ms-transform: scale(0.96);
  transform: scale(0.96);
}

.main-button.more-margin {
  margin-top: 24px;
}

.text-span-3 {
  font-weight: 600;
}

.text-input-2 {
  width: 100%;
  height: 50px;
  margin-bottom: 0px;
  padding: 0px 1rem;
  border: 1px none #000;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: inset 0 0 0 2px transparent;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
}

.text-input-2:focus {
  border-bottom-color: transparent;
  box-shadow: none;
}

.text-input-2::-webkit-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input-2:-ms-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input-2::-ms-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input-2::placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-block-34 {
  opacity: 1;
  color: rgba(36, 36, 36, 0.5);
  font-size: 12px;
  font-weight: 400;
}

.text-block-34.margin-top {
  margin-top: 12px;
}

.text-block-34.centered {
  text-align: center;
}

.link-3 {
  color: rgba(36, 36, 36, 0.75);
  text-decoration: underline;
}

.link-3:hover {
  opacity: 1;
  color: #242424;
}

.div-block-182 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  padding: 30px 60px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.23);
  background-color: transparent;
}

.div-block-182.black-upper-line {
  border-top-color: hsla(0, 0%, 64%, 0.23);
}

.div-block-183 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  min-height: 400px;
  min-width: 300px;
  padding: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 15px 45px 0 rgba(0, 0, 0, 0.09);
}

.div-block-183.add-block {
  position: relative;
  min-width: 390px;
  padding-bottom: 140px;
}

.div-block-184 {
  display: -ms-grid;
  display: grid;
  margin-top: 0px;
  margin-bottom: 0px;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-184.more-margin {
  margin-top: 24px;
  grid-auto-flow: row;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-cart-button {
  display: -ms-grid;
  display: grid;
  overflow: hidden;
  height: 44px;
  grid-auto-columns: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #ddd;
  text-decoration: none;
  text-transform: none;
}

.div-block-186 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #686868;
}

.div-block-187 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 54px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.div-block-188 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 22px;
  height: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #858585;
  color: #fff;
  font-size: 10px;
  line-height: 100%;
  font-weight: 600;
}

.grid-dynamic {
  display: -ms-grid;
  display: grid;
  padding-top: 24px;
  padding-bottom: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px none #f3f3f3;
}

.grid-dynamic._1fr {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.wrapper-dynamic-matting {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  opacity: 1;
}

.wrapper-dynamic-mounting {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  opacity: 1;
}

.wrapper-dynamic-framing {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  opacity: 1;
}

.standard-frame {
  display: none;
}

.premium-frame {
  display: none;
}

.img-controls-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 32px;
  margin-bottom: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-align-content: end;
  -ms-flex-line-pack: end;
  align-content: end;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  line-height: 100%;
}

.div-block-169-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.rotate-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.div-block-169-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.div-block-169-copy.tippy:hover {
  background-color: #fff;
}

.frame {
  display: block;
}

.image-thumbnail {
  border: 2px none transparent;
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  width: 90px;
  height: 71px;
  object-fit: cover;
}

.image-thumbnail:hover {
  border-style: none;
  border-color: rgba(61, 169, 252, 0.4);
}

.info-container-mini {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 10px;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-symbol {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #fdcc0d;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #fff;
}

.picture-product-selection {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.prem-frame {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: block;
  background-image: url('../images/Frame_for_your_work.png'), url('../images/71vtBVOn2L.jpg');
  background-position: 50% 50%, 50% 50%;
  background-size: cover, 75%;
}

.prem-frame-wrap {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
}

.side-controls-product-selection {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 120px;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(20%, #f7f7f7),
    to(transparent)
  );
  background-image: linear-gradient(90deg, #f7f7f7 20%, transparent);
}

.grid-44 {
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.divider-16px {
  height: 16px;
}

.upload-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 1px;
  border-color: #f0f1f3;
  border-radius: 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.upload-div:hover {
  border-style: solid;
  background-color: #f7f7f7;
}

.info-print-order-product-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.sub-grid-container-print-order {
  display: -ms-grid;
  display: grid;
  padding: 0px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-print-bottom-button {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.print-order-sidebar-container {
  padding: 24px;
}

.grid-images-print-order {
  padding-bottom: 24px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.img-container-print-order {
  overflow: scroll;
  height: 100vh;
  padding: 24px 24px 0px 0px;
}

.picture-element-print-order {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding: 24px 24px 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  opacity: 1;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.picture-element-print-order:hover {
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
}

.info-container-print-picture {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.img-print-order {
  width: 100%;
  height: 100px;
  border-radius: 0px;
  box-shadow: 0 1px 3px -3px #000;
  -o-object-fit: contain;
  object-fit: contain;
}

.grid-button-picture-print-order {
  position: absolute;
  left: auto;
  top: 12px;
  right: 12px;
  bottom: auto;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.print-delete-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 5px 9px -4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.client-account-sub-container {
  display: block;
  overflow: visible;
  height: auto;
  padding: 48px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.client-account-grid {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: 100%;
  padding: 0px;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: transparent;
}

.client-account-block2 {
  display: -ms-grid;
  display: grid;
  padding: 48px;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.client-account-block2.horizontal {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-auto-flow: column;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.button-form-container {
  padding-top: 20px;
}

.div-block-210 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.sub-heading-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  color: #202020;
}

.sub-heading-container.both-edges {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.client-account-block {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.mini-button-gray {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-left: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: silver;
  color: #fff;
}

.client-account-container {
  overflow: scroll;
  height: 100vh;
  padding-bottom: 0px;
}

.review-cart-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-review-cart {
  width: 100%;
  height: auto;
  max-width: 1048px;
  min-height: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 500px 500px;
  grid-template-columns: 500px 500px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.label-subcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.label-subcontainer.declined {
  padding: 3px 12px;
  border-radius: 4px;
  background-color: #e05550;
  color: #f7f7f7;
  font-weight: 600;
}

.button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 44px;
  margin-top: 0px;
  padding: 0px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 10px;
  background-color: #202020;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  text-decoration: none;
}

.button:hover {
  background-color: #333;
  box-shadow: none;
}

.button:active {
  background-color: #202020;
}

.button.w--current {
  background-color: #191a24;
  color: #fff;
}

.button.secondary {
  border-color: #ddd;
  background-color: transparent;
  color: #686868;
}

.button.secondary:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.button.secondary:active {
  background-color: #202020;
}

.button.secondary.margin-top {
  margin-top: 16px;
}

.button._50px {
  height: 50px;
}

.checkbox-field-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.checkbox-field-4:hover {
  color: #333;
}

.form-block-11 {
  margin-bottom: 0px;
}

.order-summary-container {
  min-width: 500px;
  padding: 48px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.form-cart-container {
  padding: 0px 24px 24px;
  color: #686868;
  font-weight: 400;
}

.sub-heading-summary {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0px;
}

.div-big-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.grid-container-div-block {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.client-credit-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.client-credit-grid.horizontal {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-flow: column;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.client-credit-grid.horizontal.auto-1fr {
  grid-column-gap: 48px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
}

.client-credit-grid.email {
  font-size: 12px;
}

.grid-55 {
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.main-summary-order-elements {
  grid-auto-columns: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.main-summary-order-elements.vertical {
  grid-column-gap: 16px;
  grid-row-gap: 5px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
}

.summary-grid-elements {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.summary-grid-elements.margin-16 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.heading-title-summary {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

.grid-56 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-223 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.div-block-224 {
  margin-top: 24px;
}

.accordion-item {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 16px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  cursor: pointer;
}

.accordion-item:hover {
  background-color: transparent;
  color: #333;
}

.accordion-item.no-padding {
  padding-bottom: 0px;
}

.grid-bottom-buttons {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-bottom-buttons.auto {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
}

.grid-bottom-buttons.auto.margin-24px {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid-bottom-buttons.auto.margin-24px.margin-top {
  margin-top: 16px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
}

.sub-container-top-cart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 36px;
}

.brand-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-2-copy {
  width: 52px;
  margin-right: 10px;
  padding-bottom: 0px;
}

.top-div-container-cart {
  width: 100%;
  max-width: 1048px;
  padding-left: 24px;
}

.text-block-copy {
  font-size: 26px;
  letter-spacing: -0.5px;
}

.text-block-copy.big {
  font-size: 36px;
  line-height: 36px;
}

.address-info-wrap {
  display: none;
}

.card-info-wrapper {
  display: none;
}

.check {
  width: 16px;
  height: 16px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  border-color: #a7a7a7;
}

.check.w--redirected-checked {
  background-color: #333;
  background-size: 11px;
}

.check.secondary {
  border-color: rgba(0, 0, 0, 0.06);
  background-color: #f0f1f3;
}

.check.secondary.w--redirected-checked {
  background-color: #333;
  white-space: normal;
}

.grid-58 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.client-credit-table-element {
  display: -ms-grid;
  display: grid;
  height: auto;
  padding-right: 15px;
  padding-left: 15px;
  grid-auto-columns: 100px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 170px 200px 170px 100px;
  grid-template-columns: 170px 200px 170px 100px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-bottom: 1px solid #e2e2e2;
}

.client-credit-table-element.header {
  margin-bottom: 24px;
  border: 1px none #000;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  opacity: 1;
  font-weight: 600;
}

.client-credit-table-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.client-credit-table-content.credit-earned {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-60 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-59-copy {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  grid-auto-flow: row;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.client-credit-block {
  width: 100%;
  min-width: auto;
  padding: 48px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.client-credit-table {
  padding: 48px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
}

.client-credit-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  min-height: auto;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.client-credit-block-table {
  font-weight: 400;
}

.sub-text-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0px;
}

.div-block-white {
  padding: 47px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.div-block-white.max-width-500px.margin-top-60 {
  margin-top: 60px;
}

.main-sub-text-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

.text-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-size: 12px;
}

.link-block-10 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 9px 5px 9px 15px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 3px solid #fff;
  border-radius: 15px 0px 0px 15px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  text-decoration: none;
}

.link-block-10:hover {
  padding-left: 25px;
  border-right-color: silver;
  color: #333;
}

.link-block-10.w--current {
  border-style: none solid none none;
  border-width: 1px 3px 1px 1px;
  border-color: #000 #fff #000 #000;
  background-color: #ddd;
  color: #333;
}

.link-block-10.logout:hover {
  border-right-color: #f5222d;
  color: #f5222d;
}

.container-order-review {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  height: 100vh;
  padding: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-67 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.edit-mini-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  padding: 2px 7px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.edit-mini-button:hover {
  color: #202020;
}

.text-table-container {
  display: -ms-grid;
  display: grid;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 2.5px;
  grid-row-gap: 2.5px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.qty-input-mini {
  width: 45px;
  height: 35px;
  margin-bottom: 0px;
  padding: 4px 0px 4px 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: transparent;
  line-height: 12px;
  font-weight: 600;
}

.qty-input-mini::-webkit-input-placeholder {
  color: #333;
}

.qty-input-mini:-ms-input-placeholder {
  color: #333;
}

.qty-input-mini::-ms-input-placeholder {
  color: #333;
}

.qty-input-mini::placeholder {
  color: #333;
}

.form-qty-mini {
  height: auto;
  margin-bottom: 0px;
}

.mini-button-delete {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 4px 9px -2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.div-block-246 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
}

.thanks-wrapper {
  display: none;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lottie-animation-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 200px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.heading-9 {
  color: #202020;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.5px;
}

.div-block-248 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  font-weight: 600;
}

.bottom-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 44px;
  margin-top: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid none none;
  border-width: 1px;
  border-color: #dfdfdf;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  cursor: pointer;
}

.bottom-sidebar:hover {
  padding-left: 15px;
  border-top-color: #dfdfdf;
  color: #333;
}

.screen-blocker {
  display: none;
}

.body-email {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 48px;
  padding-bottom: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background-color: #f7f7f7;
  font-family: 'Mark simonson proxima nova alt', sans-serif;
  font-size: 14px;
  font-weight: 300;
}

.email-wrapper {
  width: 100%;
  max-width: 700px;
}

.div-block-251 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-68 {
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.block-email-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  text-align: center;
}

.div-block-254 {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 500px;
  margin-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}

.div-block-255 {
  margin-top: 0px;
}

.paragraph-4 {
  margin-bottom: 0px;
  color: #686868;
}

.div-block-257 {
  margin-top: 0px;
  padding-bottom: 36px;
  border: 1px none #000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fff;
}

.div-block-258 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 26px 36px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: #fff #fff #e3e3e3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #fff;
  font-weight: 400;
}

.grid-69 {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-size: 24px;
}

.text-block-35 {
  font-weight: 600;
}

.grid-70 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-259 {
  margin-bottom: 48px;
  padding-top: 12px;
  padding-right: 26px;
  padding-left: 26px;
}

.grid-71 {
  padding-right: 36px;
  padding-left: 36px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr auto 1fr;
  grid-template-columns: 1fr auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-30-copy {
  margin-top: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: row;
  grid-auto-columns: auto;
  grid-column-gap: 40px;
  grid-row-gap: 24px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-261 {
  width: 1px;
  height: 100%;
  background-color: #e3e3e3;
}

.div-block-262 {
  margin-top: 48px;
  border: 1px none #000;
  border-radius: 15px;
  box-shadow: 0 1px 3px -3px #000;
}

.image-86-copy {
  width: 80px;
  height: 80px;
  min-height: 80px;
  min-width: 80px;
  border-radius: 5px;
}

.image-86-copy.smaller {
  width: auto;
  height: auto;
  max-height: 65px;
  min-height: auto;
  min-width: auto;
  border-radius: 0px;
}

.div-block-263 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  font-weight: 400;
  text-align: center;
}

.grid-72 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-264 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ddd;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #a7a7a7;
}

.div-block-264:hover {
  color: #333;
}

.div-block-265 {
  margin-top: 24px;
}

.div-block-266 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #f7f7f7;
  font-size: 11px;
  font-weight: 600;
}

.text-span-4 {
  color: #5cc572;
}

.overflow-menu__toggle-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 10px;
  background-color: transparent;
  background-image: url('../images/more-vertical-6.svg');
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}

.overflow-menu__toggle-copy:hover {
  background-color: transparent;
}

.overflow-menu__toggle-copy:active {
  background-color: transparent;
}

.overflow-menu__toggle-copy:focus {
  background-color: transparent;
}

.overflow-menu__toggle-copy.w--open {
  position: static;
  z-index: 10;
  border-radius: 15px 15px 0px 0px;
  background-color: #f5f5f5;
  background-image: url('../images/more-vertical-6.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  -webkit-filter: none;
  filter: none;
}

.overflow-menu__toggle-copy.w--open:hover {
  border-radius: 15px 15px 0px 0px;
  background-color: #f5f5f5;
  -webkit-filter: none;
  filter: none;
}

.overflow-menu__toggle-copy.frost-blur {
  background-color: hsla(0, 0%, 100%, 0.3);
}

.overflow-menu__toggle-copy.frost-blur.w--open {
  background-color: #f7f7f7;
}

.grid-button-form {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-269 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999999;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(32, 32, 32, 0.56);
}

.div-block-270 {
  min-height: 150px;
  min-width: 250px;
  padding-bottom: 36px;
  border: 1px none #000;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.19);
}

.div-block-271 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 36px 36px 12px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-272 {
  font-weight: 400;
  text-align: center;
}

.grid-74 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-273 {
  padding-top: 20px;
  padding-right: 36px;
  padding-left: 36px;
}

.success-message {
  margin-top: 24px;
  padding: 0px;
  background-color: transparent;
}

.div-block-274 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-span-5 {
  font-size: 24px;
  line-height: 150%;
  font-weight: 700;
}

.text-block-36 {
  font-weight: 400;
}

.lottie-animation-4 {
  width: 100px;
  margin-bottom: 24px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.error-message {
  max-width: 300px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 5px;
  background-color: #e05550;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.error-message.full-width {
  max-width: none;
}

.empty-state-print-order-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.center-div-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  font-weight: 400;
  text-align: center;
}

.lottie-animation-5 {
  position: absolute;
  left: auto;
  top: 80px;
  right: -75px;
  bottom: auto;
  width: 100px;
  margin-bottom: 140px;
}

.lottie-animation-5._99 {
  left: 21.5vw;
  top: 5.9vw;
  right: 0vw;
  bottom: auto;
  z-index: 9999;
}

.search-bar-div {
  position: absolute;
  left: auto;
  top: 20px;
  right: 0%;
  bottom: auto;
  min-height: auto;
  min-width: auto;
  padding-right: 20px;
  background-color: transparent;
}

.admin-photographers-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-block-278 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.form-block-15 {
  margin-bottom: 0px;
}

.photographer-profile-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  height: 100vh;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid-sales {
  grid-auto-flow: row;
  grid-column-gap: 48px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-element-photographer-profile {
  grid-auto-flow: column;
  grid-auto-columns: 100px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 150px 85px 80px 80px 45px;
  grid-template-columns: 1fr 150px 85px 80px 80px 45px;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-weight: 400;
}

.grid-element-photographer-profile.header {
  font-weight: 600;
}

.topbar-table-gray-mini {
  padding: 10px 0px 10px 20px;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.summary-table-photographer-profile {
  margin-top: 16px;
  padding-left: 20px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.status-table {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: silver;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
}

.status-table.canceled {
  background-color: #e05550;
}

.status-table.paid {
  background-color: #84c737;
}

.div-block-285 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  cursor: pointer;
}

.div-block-285:hover {
  background-color: #f7f7f7;
  color: #333;
}

.grid-78 {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-13-copy {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: end;
  -ms-flex-align: end;
  align-items: end;
  grid-column-gap: 48px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-31-copy-copy {
  max-width: none;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.progress-subgrid {
  height: 100%;
  grid-column-gap: 3px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-286 {
  overflow: hidden;
  height: 20px;
  border-radius: 50px;
  background-color: #fff;
}

.progress-div {
  width: 100%;
  height: 100%;
  background-color: #84c737;
}

.progress-div.empty {
  background-color: #f7f7f7;
}

.number-indicator-grid {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.progress-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
}

.progress-grid {
  grid-column-gap: 12px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.number-indicator {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #f7f7f7;
  font-size: 11px;
}

.photographer-profile-subgrid {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.grid-84 {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-288 {
  display: none;
}

.div-block-289 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.div-block-290 {
  border-radius: 10px;
  background-color: #f7f7f7;
}

.form-block-16 {
  width: 100%;
  margin-bottom: 0px;
}

.form-block-17 {
  max-width: 120px;
  margin-bottom: 0px;
}

.form-block-17:hover {
  opacity: 0.85;
}

.form-10 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 7.5px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.container-full-screen {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.white-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 48px;
  padding: 48px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
  text-align: center;
}

.div-block-293 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.link-4 {
  color: #202020;
  font-weight: 600;
}

.div-block-296 {
  padding: 16px 24px;
  background-color: #e2e2e2;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  line-height: 100%;
  font-weight: 400;
  cursor: pointer;
}

.div-block-296:hover {
  background-color: #f0f1f3;
}

.div-store {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-85 {
  width: auto;
  height: 18px;
  margin-right: 0px;
  margin-left: 0px;
}

.image-85.right-margin {
  width: auto;
  height: 18px;
  margin-right: 10px;
  margin-left: 0px;
}

.photographer-profile-grid {
  width: 100%;
  max-width: 650px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
}

.messages-input-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-298 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.indentify-person-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #f0f1f3;
  color: #333;
  font-weight: 700;
}

.grid-85 {
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 0px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-300 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.message-grid {
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-row-gap: 8px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.message-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
}

.message-bubble {
  position: relative;
  padding: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: #f0f1f3;
  border-radius: 10px;
}

.message-bubble.third {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
  background-color: #f0f1f3;
}

.container-message {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-size: 14px;
  line-height: 165%;
  font-weight: 400;
}

.container-chat.third {
  position: relative;
  padding-right: 0px;
}

.container-sales {
  position: relative;
}

.container-sales.ranking {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.message-details-grid {
  margin-top: 0px;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-306 {
  max-width: 400px;
}

.raking-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ranking-circle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fdcc0d;
  font-weight: 600;
}

.dropdown-list-5 {
  display: none;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 40px 0 rgba(152, 162, 172, 0.21);
}

.dropdown-list-5.w--open {
  left: 86%;
  top: -52px;
  overflow: visible;
  border-radius: 10px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.21);
}

.dropdown-3 {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}

.div-block-310 {
  overflow: hidden;
  border-radius: 10px;
}

.div-block-311 {
  width: 100%;
  height: 1px;
  background-color: hsla(0, 0%, 75.3%, 0.66);
}

.div-block-312 {
  position: relative;
  border-radius: 10px;
}

.div-block-313 {
  position: static;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #f7f7f7;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.div-block-314 {
  position: absolute;
  left: -11px;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.text-span-16 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  margin-top: 5px;
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f7f7f7;
  color: #333;
  line-height: 200%;
  font-weight: 600;
}

.link-5 {
  color: #333;
}

.text-span-black {
  color: #333;
  font-weight: 700;
}

.text-span-18 {
  display: inline;
  color: #2dc056;
  font-weight: 600;
  text-decoration: underline;
}

.paragraph-5 {
  margin-bottom: 0px;
  color: #686868;
  font-size: 11px;
}

.message-image {
  max-height: 100px;
}

.message-image-grid {
  grid-auto-flow: column;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.lightbox-link-2 {
  cursor: pointer;
}

.wrapper-dynamic-prints {
  display: none;
}

.container-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container-center.margin-bottom {
  margin-bottom: 24px;
}

.text-span-19 {
  font-weight: 600;
}

.link-block-8-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 43px;
  margin-top: 0px;
  padding: 18px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 10px;
  background-color: #202020;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  text-decoration: none;
}

.link-block-8-2:hover {
  background-color: #333;
  box-shadow: none;
}

.link-block-8-2:active {
  background-color: #202020;
}

.link-block-8-2.w--current {
  background-color: #191a24;
  color: #fff;
}

.link-block-8-2.secondary {
  border-color: #ddd;
  background-color: transparent;
  color: #686868;
}

.link-block-8-2.secondary:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.link-block-8-2.secondary:active {
  background-color: #202020;
}

.text-span-20 {
  font-weight: 600;
}

.success-message-2 {
  padding: 0px;
  background-color: transparent;
}

.div-block-315 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-316 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #686868;
  font-weight: 400;
  text-align: center;
}

.heading-4-copy-2 {
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  border: 1px none #000;
  background-color: transparent;
  font-size: 22px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0px;
}

.heading-4-copy-2::-webkit-input-placeholder {
  color: #202020;
}

.heading-4-copy-2:-ms-input-placeholder {
  color: #202020;
}

.heading-4-copy-2::-ms-input-placeholder {
  color: #202020;
}

.heading-4-copy-2::placeholder {
  color: #202020;
}

.lottie-animation-6 {
  width: 100px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.wrapper-dynamic-canvas {
  display: none;
}

.text-span-21 {
  color: #333;
  font-weight: 600;
}

.link-6 {
  color: #333;
}

.album-sequence-container {
  overflow: scroll;
  width: 100%;
  height: 100vh;
  padding: 24px;
}

.dragula-container {
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto auto auto auto auto;
  grid-template-columns: auto auto auto auto auto auto;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.c-card__one {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 110px;
  height: 110px;
  margin-top: 0rem;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 75.3%, 0.28);
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
}

.c-card__one:hover {
  background-color: #eee;
}

.c-card__one:active {
  border-color: #e2e2e2;
  background-color: #e2e2e2;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.12);
}

.img-dragable {
  width: 100px;
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}

.div-block-320 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: transparent;
  box-shadow: 0 1px 3px -3px #000;
}

.grid-87 {
  margin-top: 48px;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.product-selection-picture-wrapper {
  position: relative;
  display: -ms-grid;
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.photo-album-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999;
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
}

.photo-album-container.grid {
  display: block;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 176px;
  grid-template-rows: 1fr 176px;
}

.photo-album-container.grid.show {
  display: block;
}

.wrapper-dynamic-album {
  display: none;
}

.div-element {
  color: #333;
  text-decoration: none;
}

.div-element:hover {
  color: #686868;
}

.link-div-element {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333;
  text-decoration: none;
}

.link-div-element:hover {
  color: #686868;
}

.loading-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 1;
}

.loading-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-4 {
  margin-right: 0px;
  margin-left: 0px;
}

.divider-horizontal {
  width: 10px;
  height: 10px;
}

.checked-simulation {
  font-weight: 700;
}

.dropdown-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  min-width: 136px;
}

.dropdown-wrapper.new {
  display: none;
}

.credit-card-simulation-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 100%;
}

.button-2-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 43px;
  margin-top: 0px;
  padding: 18px 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #333;
  border-radius: 10px;
  background-color: #202020;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 14px;
  line-height: 100%;
  font-weight: 400;
  text-decoration: none;
}

.button-2-2:hover {
  background-color: #333;
  box-shadow: none;
}

.button-2-2:active {
  background-color: #202020;
}

.button-2-2.w--current {
  background-color: #191a24;
  color: #fff;
}

.button-2-2.secondary {
  border-color: #ddd;
  background-color: transparent;
  color: #686868;
}

.button-2-2.secondary:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.button-2-2.secondary:active {
  background-color: #202020;
}

.button-2-2.secondary {
  border-color: #ddd;
  background-color: transparent;
  color: #686868;
  cursor: pointer;
}

.button-2-2.secondary:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.button-2-2.secondary:active {
  background-color: #202020;
}

.title-info-card-2 {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;
}

.title-info-card-2:hover {
  color: #333;
}

.title-info-card-2.center {
  text-align: center;
}

.div-block-322 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.grid-88 {
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-323 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  background-color: #fff;
  opacity: 0.6;
  cursor: not-allowed;
}

.div-center-element {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-center-element._100 {
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.check-state {
  color: #89bb3e;
}

.wrapper-dynamic-artisan {
  display: none;
}

.wrapper-dynamic-matted {
  display: none;
}

.wrapper-dynamic-museum {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  opacity: 1;
}

.wrapper-dynamic-folios {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  opacity: 1;
}

.wrapper-dynamic-slipcases {
  display: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: transparent;
  opacity: 1;
}

.div-block-169-copy-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
}

.sub-heading-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  color: #202020;
}

.sub-heading-container-2.both-edges {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sub-heading-container-2.both-edges.no-margin {
  margin-bottom: 0px;
}

.rotate-button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.rotate-button-2.margin-top {
  min-height: 44px;
  min-width: 44px;
  margin-top: 48px;
}

.rotate-button-2.margin-top.tippy {
  margin-top: 0px;
}

.cart-symbol-2 {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #fdcc0d;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #fff;
}

.radio-button-2 {
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  padding-right: 0px;
  border-color: #a7a7a7;
}

.radio-button-2.w--redirected-checked {
  border-color: #333;
}

.heading-title-2-2 {
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  border: 1px none #000;
  background-color: transparent;
  font-size: 22px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0px;
}

.heading-title-2-2::-webkit-input-placeholder {
  color: #202020;
}

.heading-title-2-2:-ms-input-placeholder {
  color: #202020;
}

.heading-title-2-2::-ms-input-placeholder {
  color: #202020;
}

.heading-title-2-2::placeholder {
  color: #202020;
}

.picture-element-product-selection-2 {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: auto;
  height: auto;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 5px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 86.7%, 0.35);
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  cursor: pointer;
}

.picture-element-product-selection-2:hover {
  border-color: #eee;
  background-color: #fff;
  -webkit-transform: translate(0px, -3px);
  -ms-transform: translate(0px, -3px);
  transform: translate(0px, -3px);
  color: #333;
}

.picture-element-product-selection-2.selected {
  border-color: #fff;
  background-color: #fff;
  box-shadow: 0 3px 8px -4px rgba(0, 0, 0, 0.32);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.picture-element-product-selection-2.selected.drag-drop {
  cursor: -webkit-grab;
  cursor: grab;
}

.picture-element-product-selection-2.drag-drop {
  cursor: -webkit-grab;
  cursor: grab;
}

.div-block-324 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-325 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 85px;
  padding: 0px 24px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
}

.div-block-326 {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-327 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  height: 410px;
  padding-top: 24px;
  padding-bottom: 24px;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  border-bottom: 1px none #a7a7a7;
}

.green {
  color: #89bb3e;
}

.heading-title-2-2-copy {
  height: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
  border: 1px none #000;
  background-color: transparent;
  font-size: 18px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0px;
}

.heading-title-2-2-copy::-webkit-input-placeholder {
  color: #202020;
}

.heading-title-2-2-copy:-ms-input-placeholder {
  color: #202020;
}

.heading-title-2-2-copy::-ms-input-placeholder {
  color: #202020;
}

.heading-title-2-2-copy::placeholder {
  color: #202020;
}

.ghost-div-container {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  opacity: 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.ghost-div-container:hover {
  opacity: 1;
}

.delete-symbol {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #686868;
}

.grey {
  color: #a7a7a7;
}

.grey.darker {
  color: #858585;
}

.select-paper-options {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #686868;
}

.wrapper-dynamic-multi {
  display: block;
}

.dropdown-toggle-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px 0px 0px 13px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}

.dropdown-5 {
  width: 100%;
  height: 100%;
}

.dropdown-list-6 {
  background-color: #5c4444;
}

.dropdown-list-6.w--open {
  z-index: 99;
  overflow: scroll;
  height: auto;
  max-height: 220px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 3px 17px 0 rgba(0, 0, 0, 0.09);
}

.div-block-328 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.div-block-329 {
  display: -ms-grid;
  display: grid;
  padding: 13px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 5px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 12px;
  cursor: pointer;
}

.div-block-329:hover {
  background-color: #f0f1f3;
}

.radio-button-label {
  font-weight: 600;
}

.radio-button-field-2 {
  margin-bottom: 0px;
  padding-left: 0px;
}

.paragraph-6 {
  margin-bottom: 0px;
  font-size: 11px;
  line-height: 150%;
  font-weight: 400;
}

.div-block-331 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  height: 90%;
  padding: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.div-block-332 {
  width: 60%;
  border-style: solid;
  border-width: 2px;
  border-color: #0bf;
}

.div-block-333 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70%;
  height: 85%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #0bf;
  background-color: rgba(0, 0, 0, 0.07);
  background-image: url('../images/101-1014683_wallpaper-background-and-grid-image-100-squares.png');
  background-position: 50% 50%;
  background-size: 42%;
  opacity: 0.4;
}

.check-symbol {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #89bb3e;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #fff;
}

.div-block-334 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 85px;
  padding-right: 48px;
  padding-left: 48px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-334.griddy {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 2fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.radio-button-copy {
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  padding-right: 0px;
  border-color: #a7a7a7;
}

.radio-button-copy.w--redirected-checked {
  border-color: #333;
}

.thumbnail-multiopening-card {
  display: block;
  padding: 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.thumbnail-multiopening-card:hover {
  background-color: #f0f1f3;
}

.radio-button-field-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 80px;
  margin-bottom: 0px;
  padding: 13px 13px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
}

.radio-button-4 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-left: 0px;
  border-style: none;
  border-radius: 0%;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.radio-button-4:hover {
  border-style: solid;
  border-color: rgba(108, 158, 223, 0.22);
  border-radius: 10px;
}

.radio-button-4.w--redirected-checked {
  border-style: none;
  border-width: 1px;
  border-radius: 10px;
  background-color: rgba(51, 51, 51, 0.1);
  box-shadow: inset 0 0 0 2px #07bdff;
}

.radio-button-label-2 {
  display: none;
}

.div-block-335 {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 13px;
  grid-row-gap: 13px;
  -ms-grid-columns: minmax(auto, 0.45fr) 1fr;
  grid-template-columns: minmax(auto, 0.45fr) 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-336 {
  width: auto;
  height: auto;
  max-width: none;
  min-height: auto;
  min-width: auto;
  background-color: transparent;
}

.product-selection-main-picture-container-copy {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding: 24px 24px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-selection-main-picture-container-copy.no-padding {
  overflow: hidden;
  max-width: 100%;
  padding: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-selection-main-picture-container-copy.prints {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-top: 85px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.slider-2 {
  overflow: visible;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.slider-mask {
  display: block;
  overflow: visible;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.slider-arrow {
  left: 0px;
  top: 0px;
  width: 72px;
  height: 72px;
  background-color: #fff;
}

.slider-arrow.left {
  left: 24px;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 40.7%, 0.24);
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
}

.slider-arrow.right {
  left: auto;
  top: 0px;
  right: 24px;
  width: 50px;
  height: 50px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 40.7%, 0.24);
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2);
}

.slide-2 {
  position: relative;
}

.slide-2.slide-one {
  overflow: visible;
  height: 100%;
}

.slider-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider-wrap.prints {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.slider-arrow-icon {
  color: #000;
  font-size: 16px;
}

.slide-nav-4 {
  position: static;
  margin-top: 0px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 400;
}

.multi-opening-label {
  font-weight: 600;
}

.image-thumb-multi-opening {
  height: auto;
  background-color: #ddd;
}

.div-block-343 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 999999;
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(32, 32, 32, 0.56);
}

.check-symbol-2 {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #89bb3e;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #fff;
}

.dropdown-6 {
  z-index: 1;
}

.print-section-only-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
}

.print-section-only-wrapper.slideshow-show {
  display: none;
}

.green-2 {
  color: #89bb3e;
}

.div-block-169-copy-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.div-block-169-copy-3.ghost {
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
  background-color: transparent;
  box-shadow: none;
  color: #686868;
}

.div-block-345 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.image-91 {
  width: 35%;
}

.div-block-346 {
  position: relative;
  width: 55%;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
}

.div-block-347 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  display: -ms-grid;
  display: grid;
  padding: 7%;
  grid-auto-columns: 1fr;
  grid-column-gap: 0vw;
  grid-row-gap: 5vw;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.image-92 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.image-93 {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.product-selection-picture-container-flex {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr auto;
  grid-template-rows: 1fr auto;
}

.grid-89 {
  margin-top: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  font-weight: 400;
}

.div-block-348 {
  position: absolute;
  left: auto;
  top: -15px;
  right: -15px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #ddd;
  box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #686868;
  cursor: pointer;
}

.div-block-348:hover {
  color: #202020;
}

.dragula-container-copy {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  display: -ms-grid;
  display: grid;
  width: 100%;
  height: auto;
  padding-top: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
  grid-auto-columns: 110px;
  grid-auto-rows: 110px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 110px 110px 110px 110px 110px 110px;
  grid-template-columns: 110px 110px 110px 110px 110px 110px;
  -ms-grid-rows: 110px;
  grid-template-rows: 110px;
}

.stroke {
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 2px;
  border-color: #000;
  border-radius: 7.5px;
}

.stroke.first-group {
  border-style: dashed;
  border-width: 1px;
  border-color: #202020;
}

.stroke.first-group:hover {
  border-color: #74baff;
}

.div-block-349 {
  position: absolute;
  left: 4px;
  top: -12px;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #f7f7f7;
  font-size: 12px;
  font-weight: 600;
}

._3rd-block-container-order-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-top: 1px none #ddd;
  border-bottom: 1px none #ddd;
  border-radius: 10px;
  background-color: #fff;
}

.container-rightside-order-summary-copy {
  margin-top: 0px;
}

.wrapper-dynamic-stroke {
  display: none;
}

.link-block-11 {
  padding: 5px;
  border-radius: 5px;
  color: #686868;
}

.link-block-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 3px;
  padding: 2.5px 4px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #dbdbdb;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
}

.div-block-350 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-weight: 600;
}

._2nd-button-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #333;
  box-shadow: 0 1px 3px -3px #000;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
}

._2nd-button-2.main {
  width: auto;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  border-style: solid;
  border-color: #333;
}

.grid-90 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 5px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
}

.form-11 {
  display: -ms-grid;
  display: grid;
  height: 100%;
  margin-top: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-92 {
  grid-auto-columns: auto;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.form-block-18 {
  margin-bottom: 0px;
}

.grid-93 {
  margin-top: 10px;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.note-tooltip {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.note-tooltip.admin {
  background-color: #89bb3e;
  color: #fff;
}

.note-tooltip.boutique {
  background-color: #e637d5;
  color: #fff;
}

.div-block-354 {
  position: absolute;
  left: auto;
  top: -5px;
  right: -5px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  color: #333;
  font-size: 11px;
}

.div-block-354.admin {
  color: #89bb3e;
}

.div-block-354.boutique {
  color: #e637d5;
}

.note-tooltip-wrapper {
  position: absolute;
  left: -10px;
  bottom: -112px;
  z-index: 10;
  overflow: visible;
  width: 220px;
  height: auto;
  min-height: 90px;
  padding: 0px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
}

.line-bottom-note-container {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 5px;
  background-color: #333;
}

.line-bottom-note-container.admin {
  background-color: #87c73b;
}

.line-bottom-note-container.boutique {
  background-color: #e736d6;
}

.div-block-356 {
  position: absolute;
  left: 17px;
  top: -5px;
  right: -14px;
  bottom: auto;
  z-index: 5;
  width: 14px;
  height: 14px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 3px 0 #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.div-block-357 {
  position: relative;
  z-index: 6;
  overflow: hidden;
  padding: 12px 12px 17px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}

.client-note {
  display: block;
  opacity: 1;
}

.div-block-357-copy {
  position: relative;
  z-index: 6;
  overflow: visible;
  padding: 0px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}

.div-block-358 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  padding-right: 10px;
  padding-left: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.form-11-copy {
  display: -ms-grid;
  display: grid;
  height: 100%;
  margin-top: 0px;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-98-copy {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.field-label-2-copy {
  margin-bottom: 0px;
  font-weight: 400;
}

.div-block-359 {
  position: absolute;
  left: 17px;
  top: -5px;
  right: -14px;
  bottom: auto;
  z-index: 5;
  width: 14px;
  height: 14px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 10px 3px 0 #000;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.delete-symbol-2 {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.13);
  color: #686868;
}

.div-block-360 {
  position: relative;
  z-index: 6;
  overflow: hidden;
  padding: 12px 12px 17px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}

.line-bottom-note-container-2 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 5px;
  background-color: #333;
}

.line-bottom-note-container-2.boutique {
  background-color: #e736d6;
}

.line-bottom-note-container-2.admin {
  background-color: #87c73b;
}

.link-block-14 {
  padding: 5px;
  border-radius: 5px;
  color: #686868;
}

.div-block-357-copy-2 {
  position: relative;
  z-index: 6;
  overflow: visible;
  padding: 0px;
  border-style: none;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
}

.div-block-361 {
  position: absolute;
  left: auto;
  top: -5px;
  right: -5px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
  color: #333;
  font-size: 11px;
}

.div-block-361.admin {
  color: #89bb3e;
}

.div-block-361.boutique {
  color: #e637d5;
}

.note-tooltip-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 22px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  cursor: pointer;
}

.note-tooltip-2.boutique {
  background-color: #e637d5;
  color: #fff;
}

.note-tooltip-2.admin {
  background-color: #89bb3e;
  color: #fff;
}

.checkbox-7 {
  width: 18px;
  height: 18px;
  margin-top: 0px;
  margin-left: 0px;
  border-color: silver;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.checkbox-7:hover {
  border-width: 2px;
  border-color: #858585;
}

.checkbox-7.w--redirected-checked {
  background-color: #333;
  background-size: 12px;
}

.checkbox-7._2 {
  border-color: #858585;
}

.div-block-350-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  font-weight: 600;
}

@media screen and (min-width: 1280px) {
  .sidebar-button.notification {
    display: none;
  }

  .icon.smaller.right-margin {
    display: block;
    margin-right: 7.5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .icon.mini {
    width: 15px;
    height: 15px;
    margin-top: -3px;
  }

  .icon.mini.margin-left {
    margin-left: 5px;
  }

  .icon.mini.margin-left.opacity70 {
    opacity: 0.7;
  }

  .icon.logout {
    padding: 1.5px;
  }

  .grid-container-controls {
    padding-right: 20px;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  .grid-container-controls.no-padding {
    padding-right: 8px;
    padding-left: 8px;
    grid-column-gap: 8px;
    grid-row-gap: 0px;
  }

  .search {
    border-radius: 15px;
  }

  .search.tab {
    border-bottom: 1px solid #f7f7f7;
    box-shadow: none;
  }

  .input {
    min-width: 235px;
  }

  .input.smaller {
    min-width: 155px;
  }

  .link-block-3 {
    width: auto;
    padding-right: 24px;
    padding-left: 24px;
  }

  .link-block-3.green.shadow {
    width: auto;
    padding-right: 24px;
    padding-left: 24px;
  }

  .sub-container-cards {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .order-card {
    background-color: #fff;
  }

  .order-card.yellow {
    background-color: #fdcc0d;
  }

  .order-card.yellow {
    background-color: #fdcc0d;
  }

  .product-detail-div {
    border-radius: 0px 0px 10px 10px;
  }

  .grid-6 {
    grid-column-gap: 3px;
  }

  .grid-6.date-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .filter-select-field.radius {
    border-radius: 15px;
  }

  .filter-select-field.radius.filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .div-block-40 {
    height: auto;
  }

  .text-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .dropdown-list.filter.w--open {
    min-width: 170px;
  }

  .dropdown-list.album-options.w--open {
    left: -130px;
    top: 17px;
    z-index: 20;
    min-width: 160px;
    padding: 7.5px 10px 10px;
    font-weight: 300;
  }

  .container-info-filter.specific-dates {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .grid-filters {
    border-radius: 15px;
  }

  .dropdown {
    display: block;
  }

  .grid-filter-info {
    grid-auto-flow: column;
  }

  .grid-filter-info.margin-top {
    margin-top: 20px;
  }

  .div-block-47 {
    display: block;
    grid-auto-flow: row;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .top-menu-controls {
    position: static;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .div-block-60.reprint {
    background-color: #ac1cb1;
  }

  .grid-progress-bar._2 {
    width: auto;
    max-width: none;
    min-width: auto;
    -ms-grid-columns: auto 30px;
    grid-template-columns: auto 30px;
  }

  .progress-bar {
    width: 100%;
    min-width: 100px;
  }

  .div-block-66 {
    background-color: #f7f7f7;
  }

  .grid-23.main {
    line-height: 13px;
    font-weight: 400;
  }

  .grid-23.main {
    line-height: 13px;
    font-weight: 400;
  }

  .sub-container-orders {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .grid-details {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .wrapper {
    width: 100%;
    max-width: 1400px;
  }

  .container-topbar-orders {
    width: 100%;
    max-width: 1400px;
  }

  .container-topbar-orders.gridd {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .grid-24 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-grid-columns: 1fr auto;
    grid-template-columns: 1fr auto;
  }

  .grid-element-order-summary {
    -ms-grid-columns: 80px 180px 140px 160px 150px;
    grid-template-columns: 80px 180px 140px 160px 150px;
  }

  .grid-element-order-summary.head.white {
    background-color: #fff;
    box-shadow: 0 1px 3px -3px #000;
  }

  .grid-element-order-summary.head.white.smaller {
    -ms-grid-columns: 80px 180px 140px 100px 150px;
    grid-template-columns: 80px 180px 140px 100px 150px;
  }

  .grid-element-order-summary.head.unbundled-list {
    width: 100%;
  }

  .grid-element-order-summary.smaller {
    -ms-grid-columns: 75px 140px 130px 140px 65px;
    grid-template-columns: 75px 140px 130px 140px 65px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .grid-element-order-summary.smaller:hover {
    background-color: hsla(0, 0%, 100%, 0.6);
  }

  .grid-element-order-summary.smaller.number {
    padding-left: 0px;
    -ms-grid-columns: 33px 85px 140px 140px 140px 55px 60px;
    grid-template-columns: 33px 85px 140px 140px 140px 55px 60px;
  }

  .grid-element-order-summary.head.white {
    background-color: #fff;
    box-shadow: 0 1px 3px -3px #000;
  }

  .grid-element-order-summary.head.white.smaller {
    padding-left: 10px;
    -ms-grid-columns: 75px 140px 130px 140px 65px;
    grid-template-columns: 75px 140px 130px 140px 65px;
  }

  .grid-element-order-summary.head.white.smaller.number {
    padding-left: 0px;
    -ms-grid-columns: 33px 75px 140px 130px 140px 65px 75px;
    grid-template-columns: 33px 75px 140px 130px 140px 65px 75px;
  }

  .grid-element-order-summary.head.unbundled-list {
    width: 100%;
  }

  .grid-element-order-summary.unbundled-list {
    padding-right: 0px;
    -ms-grid-columns: 40px 40px 100px 160px 160px 230px 35px 70px 100px 40px;
    grid-template-columns: 40px 40px 100px 160px 160px 230px 35px 70px 100px 40px;
  }

  .grid-element-order-summary.admin-order {
    grid-auto-columns: 230px;
    -ms-grid-columns: 80px 80px 180px 200px 200px;
    grid-template-columns: 80px 80px 180px 200px 200px;
  }

  .heady.print-details {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  .heady.center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .container-rightside-order-summary.no-margin {
    padding: 0px;
    border-style: none;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 10px;
  }

  .content.print-details {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .img-order-summary.bigger {
    width: 90px;
    height: 90px;
    min-height: 90px;
    min-width: 90px;
  }

  .heading-container.aligned-left.no-padding {
    display: -ms-grid;
    display: grid;
    padding-left: 0px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .grid-order-summary-container {
    overflow: scroll;
    height: auto;
    max-height: none;
  }

  .grid-order-summary-container.no-scroll {
    height: 78vh;
  }

  .grid-order-summary-container.unbundled-list {
    grid-row-gap: 37px;
  }

  .grid-28 {
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    justify-items: end;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .grid-topbar-orders {
    width: auto;
    max-width: none;
  }

  .grid-button-topbar-orders {
    -webkit-box-pack: stretch;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
  }

  .grid-29 {
    width: 100%;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .checkbox-label-2 {
    display: none;
  }

  .checkbox-field-3 {
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .form-block-7 {
    margin-bottom: 0px;
  }

  .order-2nd-div {
    border-bottom-style: none;
  }

  .container-orders {
    overflow: hidden;
  }

  .div-block-details {
    overflow: visible;
    border-radius: 0px 10px 10px;
  }

  .qr {
    border-radius: 5px;
  }

  .text-table-top-container {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .upgrade-button {
    width: auto;
    padding-right: 24px;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
  }

  .upgrade-button:hover {
    width: auto;
    padding-right: 24px;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
  }

  .grid-24-copy-copy {
    -webkit-box-pack: stretch;
    -webkit-justify-content: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
  }

  .tab-link-tab-1.w--current {
    box-shadow: none;
  }

  .grid-table-heady {
    -ms-grid-columns: 200px 180px 180px 100px 120px 120px 100px 40px;
    grid-template-columns: 200px 180px 180px 100px 120px 120px 100px 40px;
  }

  .tabs-menu-2 {
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    justify-items: end;
  }

  .admin-photographer-subcontainer {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tabs-2 {
    width: 100%;
    max-width: 400px;
  }

  .tab-link-tab-1-copy.w--current {
    box-shadow: none;
  }

  .print-order-sidebar.grid.absolute {
    max-width: 265px;
    min-width: 265px;
  }

  .div-block-150 {
    color: #686868;
  }

  .button-2.secondary.single {
    width: 44px;
    padding: 0px;
  }

  .button-2.single {
    width: auto;
  }

  .sub-label-form {
    opacity: 1;
  }

  .accordion-item-block.no-margin {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .grid-form {
    justify-items: stretch;
  }

  .controls-block-grid.no-margin {
    margin-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .product-selection-main-picture-container {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .main-picture-grid {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .bottom-controls-product-selection {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-controls-product-selection.no-margin.white {
    height: 183px;
    border-top-style: solid;
    background-color: #fff;
  }

  .bottom-controls-product-selection-grid {
    max-width: 73.5vw;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .bottom-controls-product-selection-grid.padding-top-5px {
    padding-bottom: 20px;
  }

  .div-block-181 {
    padding-bottom: 40px;
  }

  .main-button {
    position: static;
    margin-right: 0px;
    margin-left: 0px;
  }

  .div-block-183.reset {
    height: auto;
  }

  .wrapper-dynamic-matting {
    display: none;
  }

  .wrapper-dynamic-mounting {
    display: none;
  }

  .wrapper-dynamic-framing {
    display: none;
  }

  .standard-frame {
    display: none;
  }

  .side-controls-product-selection.white-gradient {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(20%, #fff),
      to(transparent)
    );
    background-image: linear-gradient(90deg, #fff 20%, transparent);
  }

  .upload-div:hover {
    color: #333;
  }

  .sub-grid-container-print-order {
    grid-column-gap: 0px;
  }

  .grid-images-print-order {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .print-delete-button {
    opacity: 1;
  }

  .client-account-sub-container {
    padding-bottom: 48px;
    grid-column-gap: 0px;
  }

  .client-account-grid {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .sub-heading-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 12px;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .label-subcontainer {
    color: #686868;
  }

  .div-block-white.max-width-500px {
    max-width: 500px;
  }

  .container-order-review {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-266 {
    background-color: #fff;
    box-shadow: 0 1px 3px -3px #000;
  }

  .grid-button-form {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
  }

  .search-bar-div {
    left: 0%;
    top: 20px;
    right: auto;
    bottom: auto;
    padding-right: 0px;
    padding-left: 20px;
  }

  .admin-photographers-container {
    overflow: hidden;
  }

  .grid-element-photographer-profile {
    -ms-grid-columns: 1fr 250px 85px 80px 80px 45px;
    grid-template-columns: 1fr 250px 85px 80px 80px 45px;
  }

  .photographer-profile-grid {
    max-width: 800px;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    grid-auto-flow: row;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .wrapper-dynamic-canvas {
    display: none;
  }

  .album-sequence-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .dragula-container {
    overflow: visible;
    -ms-grid-columns: auto auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto auto auto;
  }

  .c-card__one {
    position: relative;
    width: 115px;
    height: 115px;
    background-color: #fff;
  }

  .img-dragable {
    width: 90px;
    height: 90px;
  }

  .div-block-320 {
    height: 100%;
    max-width: none;
    padding: 36px;
    box-shadow: none;
  }

  .photo-album-container.grid {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 183px;
    grid-template-rows: auto 183px;
  }

  .wrapper-dynamic-album {
    display: none;
  }

  .div-element {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #333;
    text-decoration: none;
  }

  .div-element:hover {
    color: #686868;
  }

  .wrapper-dynamic-artisan {
    display: none;
  }

  .wrapper-dynamic-matted {
    display: none;
  }

  .wrapper-dynamic-museum {
    display: none;
  }

  .wrapper-dynamic-folios {
    display: none;
  }

  .wrapper-dynamic-slipcases {
    display: none;
  }

  .sub-heading-container-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 12px;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-324 {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: flex-start;
    align-content: flex-start;
    -ms-grid-rows: 85px 83.4%;
    grid-template-rows: 85px 83.4%;
  }

  .div-block-327 {
    overflow: scroll;
    height: 36vw;
    max-height: none;
    padding: 24px 0px;
  }

  .heading-title-2-2-copy {
    font-size: 18px;
  }

  .ghost-div-container {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 3;
    opacity: 0;
    -webkit-transition: opacity 100ms ease;
    transition: opacity 100ms ease;
  }

  .ghost-div-container:hover {
    opacity: 1;
  }

  .delete-symbol {
    padding: 0px;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #686868;
    cursor: pointer;
  }

  .delete-symbol:hover {
    color: #333;
  }

  .select-paper-options {
    padding: 0px;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #686868;
    cursor: pointer;
  }

  .select-paper-options:hover {
    color: #333;
  }

  .wrapper-dynamic-multi {
    display: block;
  }

  .div-block-329 {
    font-size: 11px;
    line-height: 165%;
  }

  .radio-button-field-2 {
    font-size: 12px;
  }

  .div-block-334 {
    min-height: 85px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .div-block-334.griddy {
    position: absolute;
  }

  .thumbnail-multiopening-card {
    font-size: 11px;
    line-height: 165%;
  }

  .product-selection-main-picture-container-copy {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .product-selection-main-picture-container-copy.no-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .slide-nav-4 {
    margin-top: 0px;
  }

  .div-block-345 {
    height: 100%;
  }

  .image-91 {
    width: 45%;
  }

  .dragula-container-copy {
    z-index: 0;
    overflow: visible;
    margin-top: 24px;
    padding-top: 0px;
    grid-auto-columns: 115px;
    grid-auto-rows: 115px;
    -ms-grid-columns: 115px 115px 115px 115px 115px 115px 115px;
    grid-template-columns: 115px 115px 115px 115px 115px 115px 115px;
    -ms-grid-rows: 115px;
    grid-template-rows: 115px;
  }

  .container-rightside-order-summary-copy {
    overflow: scroll;
  }

  .link-block-12 {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }

  .link-block-12:hover {
    border-color: #333;
    background-color: #333;
    color: #fff;
  }

  .div-block-351 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .form-11 {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .form-11-copy {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .delete-symbol-2 {
    padding: 0px;
    background-color: #fff;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #686868;
    cursor: pointer;
  }

  .delete-symbol-2:hover {
    color: #333;
  }
}

@media screen and (min-width: 1440px) {
  .grid {
    overflow: hidden;
    max-height: 100vh;
  }

  .icon.small.left-margin {
    margin-left: 15px;
  }

  .icon.smaller.left-margin {
    margin-left: 15px;
  }

  .grid-container-controls.no-padding {
    padding-right: 0px;
    padding-left: 0px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .input {
    min-width: 250px;
  }

  .input.smaller {
    min-width: 160px;
  }

  .sub-container-cards {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .grid-6 {
    grid-column-gap: 5px;
    line-height: 100%;
  }

  .filter-select-field.radius {
    box-shadow: 0 1px 3px -3px #000;
  }

  .div-block-33 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .top-button.on-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .dropdown-list.w--open {
    font-weight: 400;
  }

  .icon-2 {
    margin-top: -2px;
  }

  .div-block-47 {
    padding-bottom: 0px;
  }

  .white-button {
    color: #333;
  }

  .white-button.link-block-3.green.shadow {
    width: 50px;
    padding: 0px;
  }

  .white-button.link-block-3.green.shadow.tippy {
    border-radius: 15px;
  }

  .div-block-56 {
    box-shadow: 0 1px 3px -2px #000;
  }

  .div-block-60.open {
    background-color: #fdcc0d;
  }

  .toggle-status.open {
    background-color: #fdcc0d;
  }

  .toggle-status.reprint {
    background-color: #ac1db1;
  }

  .toggle-status.reprint.w--open {
    background-color: #a81dab;
  }

  .dropdown-list-status.w--open {
    font-weight: 400;
  }

  .note {
    left: 0px;
    top: 0px;
  }

  .note.tippy {
    position: relative;
    left: 0px;
    top: 0px;
  }

  .progress-bar {
    width: 150px;
  }

  .grid-23.main._2._100 {
    font-weight: 600;
  }

  .grid-23.main.no-padding {
    padding-left: 0px;
  }

  .sub-container-orders {
    position: absolute;
    overflow: scroll;
    width: 100%;
    max-height: 100vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .heading-title-2 {
    padding: 0px;
    border: 1px none #000;
    background-color: transparent;
  }

  .wrapper {
    width: 100%;
    max-width: none;
    min-width: auto;
  }

  .container-topbar-orders {
    width: 100%;
  }

  .container-topbar-orders.gridd {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .grid-24 {
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    -ms-grid-columns: 1fr auto;
    grid-template-columns: 1fr auto;
  }

  .select-field-3-copy {
    background-color: transparent;
  }

  .div-block-99.bg {
    min-width: 120px;
  }

  .status-order {
    margin-right: 0px;
    margin-left: 0px;
  }

  .grid-order-icons {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .heading-title.right-margin.x2 {
    margin-right: 32px;
  }

  .grid-element-order-summary.head.white.smaller {
    -ms-grid-columns: 80px 150px 120px 100px 120px;
    grid-template-columns: 80px 150px 120px 100px 120px;
  }

  .grid-element-order-summary.head.unbundled-list {
    position: relative;
    top: 0px;
  }

  .grid-element-order-summary.smaller {
    -ms-grid-columns: 75px 150px 120px 140px 65px;
    grid-template-columns: 75px 150px 120px 140px 65px;
    font-size: 12px;
  }

  .grid-element-order-summary.head.white.smaller {
    padding-left: 10px;
    -ms-grid-columns: 75px 150px 120px 140px 65px;
    grid-template-columns: 75px 150px 120px 140px 65px;
  }

  .grid-element-order-summary.head.unbundled-list {
    position: relative;
    top: 0px;
  }

  .grid-element-order-summary.unbundled-list {
    grid-auto-columns: 45px;
    grid-column-gap: 10px;
    -ms-grid-columns: 35px 40px 100px 160px 190px 230px 55px 90px 100px 50px;
    grid-template-columns: 35px 40px 100px 160px 190px 230px 55px 90px 100px 50px;
  }

  .grid-element-order-summary.admin-order {
    grid-auto-columns: 100px;
    -ms-grid-columns: 35px 80px 180px 230px 150px 230px;
    grid-template-columns: 35px 80px 180px 230px 150px 230px;
  }

  .grid-element-order-summary.admin-order.hide-mobile {
    display: none;
  }

  .container-rightside-order-summary.no-margin {
    margin-top: 0px;
  }

  .content {
    font-weight: 600;
  }

  .img-order-summary.bigger {
    width: 90px;
    height: 90px;
    min-height: 90px;
    min-width: 90px;
  }

  .text-table.smaller {
    font-size: 12px;
    line-height: 150%;
  }

  .heading-container.margin-bottom {
    height: auto;
    margin-bottom: 24px;
    padding-left: 0px;
  }

  .heading-container.aligned-left {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  .heading-container.aligned-left.no-padding {
    width: 100%;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid-order-summary-container {
    position: relative;
    overflow: scroll;
    height: auto;
    max-height: none;
  }

  .grid-28 {
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    justify-items: end;
  }

  .grid-topbar-orders {
    display: -ms-grid;
    display: grid;
    width: 100%;
    max-width: none;
    min-width: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .sub-grid-topbar-orders {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid-29 {
    width: 100%;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .grid-info-order {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .container-orders {
    position: relative;
  }

  .grid-button-notes {
    grid-auto-flow: column;
    grid-auto-columns: auto;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .div-block-124 {
    position: relative;
  }

  .rush {
    left: 0px;
    top: 0px;
    background-color: #e64a2c;
  }

  .rush.tippy {
    position: relative;
    left: 0px;
    top: 0px;
    width: 36px;
    background-color: #e64a2c;
  }

  .grid-table-heady {
    -ms-grid-columns: 200px 180px 180px 100px 120px 120px 100px 40px;
    grid-template-columns: 200px 180px 180px 100px 120px 120px 100px 40px;
  }

  .overflow-menu__toggle-copy.frost-blur._2 {
    z-index: 3;
  }

  .admin-photographer-subcontainer {
    position: absolute;
    overflow: scroll;
    width: 100%;
    max-height: 100vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .admin-photographer-subcontainer.big-screen {
    max-height: none;
    margin-top: 0px;
    padding-right: 36px;
    padding-left: 36px;
  }

  .div-notes.hide {
    display: none;
  }

  .gray-block {
    border-radius: 0px;
  }

  .tab-pane-tab-2-copy {
    border-top-right-radius: 0px;
  }

  .grid-38 {
    display: none;
    padding-left: 100px;
    grid-auto-columns: 1fr;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .tab-link-tab-1-copy.active {
    padding-top: 14px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f7f7f7;
  }

  .print-order-sidebar.grid.absolute {
    max-width: 300px;
    min-width: 300px;
  }

  .button-2:hover {
    background-color: #3b3b3b;
  }

  .button-2.secondary {
    cursor: pointer;
  }

  .bottom-controls-product-selection-sidebar {
    height: auto;
  }

  .sidebar-product-selection.white {
    height: 100vh;
  }

  .input-container.paper-type {
    position: relative;
  }

  .input-container.paper-type {
    position: relative;
  }

  .select-field-wrapper {
    position: relative;
  }

  .select-field-wrapper.secondary {
    width: auto;
  }

  .form-input.select-field._2nd-color {
    background-color: #f7f7f7;
  }

  .grid-form {
    position: relative;
    grid-auto-flow: row;
  }

  .grid-form.auto._1fr-right {
    -ms-grid-columns: 1fr 115px;
    grid-template-columns: 1fr 115px;
  }

  .controls-block-grid.no-margin {
    margin-bottom: 0px;
  }

  .product-selection-main-picture-container {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-controls-product-selection {
    height: 195px;
    min-height: 195px;
    padding-top: 24px;
  }

  .bottom-controls-product-selection.no-margin.white {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    height: 13.472vw;
    min-height: 13.472vw;
  }

  .bottom-controls-product-selection-grid {
    overflow: scroll;
    max-width: 76vw;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  .grid-print-order {
    position: static;
  }

  .div-block-169-copy.ghost {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }

  .div-block-169-copy.ghost:hover {
    background-color: #ddd;
    color: #333;
  }

  .text-input {
    font-weight: 400;
  }

  .text-input::-webkit-input-placeholder {
    font-weight: 400;
  }

  .text-input:-ms-input-placeholder {
    font-weight: 400;
  }

  .text-input::-ms-input-placeholder {
    font-weight: 400;
  }

  .text-input::placeholder {
    font-weight: 400;
  }

  .div-block-181 {
    font-weight: 400;
  }

  .heading-6 {
    letter-spacing: -0.5px;
  }

  .text-span-3 {
    font-weight: 600;
  }

  .wrapper-dynamic-matting {
    display: none;
  }

  .wrapper-dynamic-mounting {
    display: none;
  }

  .wrapper-dynamic-framing {
    display: none;
  }

  .standard-frame {
    display: none;
  }

  .premium-frame {
    display: none;
  }

  .picture-product-selection {
    width: 45%;
  }

  .prem-frame-wrap {
    display: none;
  }

  .client-account-sub-container {
    height: auto;
  }

  .sub-heading-container.both-edges {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .client-account-container {
    overflow: scroll;
    height: 100vh;
  }

  .text-block-copy {
    font-size: 28px;
  }

  .grid-58 {
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
  }

  .client-credit-table-element {
    border-bottom: 1px solid #e6e6e6;
  }

  .client-credit-table-element.header {
    border-bottom-style: none;
  }

  .container-order-review {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 48px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .grid-67 {
    display: -ms-grid;
    display: grid;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-auto-columns: 1fr;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr 430px;
    grid-template-columns: 1fr 430px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .mini-button-delete {
    cursor: pointer;
  }

  .thanks-wrapper {
    display: none;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    padding: 24px 24px 48px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .overflow-menu__toggle-copy.w--open {
    width: auto;
    min-width: 40px;
    padding-right: 15px;
    padding-left: 15px;
    background-image: none;
  }

  .grid-button-form {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .search-bar-div {
    padding-left: 36px;
  }

  .admin-photographers-container {
    position: relative;
  }

  .div-block-278 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .form-block-15 {
    margin-bottom: 0px;
  }

  .photographer-profile-container {
    padding-right: 24px;
    padding-left: 24px;
  }

  .grid-sales {
    grid-auto-flow: row;
  }

  .grid-element-photographer-profile {
    -ms-grid-columns: 1fr 180px 85px 80px 80px 45px;
    grid-template-columns: 1fr 180px 85px 80px 80px 45px;
  }

  .grid-13-copy {
    position: relative;
    grid-auto-flow: row;
  }

  .grid-31-copy-copy {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .progress-block {
    max-width: 100px;
  }

  .photographer-profile-subgrid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .div-block-288 {
    display: none;
  }

  .div-block-289 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .form-block-16 {
    width: 100%;
    margin-bottom: 0px;
  }

  .form-block-17 {
    margin-bottom: 0px;
  }

  .container-full-screen {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .white-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .photographer-profile-grid {
    max-width: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: flex-start;
    align-content: flex-start;
    grid-auto-flow: column;
    grid-column-gap: 24px;
    grid-row-gap: 48px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 850px)) 500px;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .message-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .container-sales {
    height: auto;
  }

  .heading-4-copy-2 {
    padding: 0px;
    border: 1px none #000;
    background-color: transparent;
  }

  .album-sequence-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .dragula-container {
    -ms-grid-columns: auto auto auto auto auto auto auto auto;
    grid-template-columns: auto auto auto auto auto auto auto auto;
  }

  .c-card__one {
    background-color: #fff;
  }

  .div-block-320 {
    max-width: none;
    padding: 36px 48px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .photo-album-container {
    display: block;
  }

  .photo-album-container.grid {
    display: none;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 1fr auto;
    grid-template-rows: 1fr auto;
  }

  .button-2-2.secondary {
    cursor: pointer;
  }

  .wrapper-dynamic-artisan {
    display: none;
  }

  .wrapper-dynamic-museum {
    display: none;
  }

  .wrapper-dynamic-folios {
    display: none;
  }

  .wrapper-dynamic-slipcases {
    display: none;
  }

  .sub-heading-container-2.both-edges {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .rotate-button-2.margin-top {
    position: absolute;
    bottom: 10%;
    margin-top: 24px;
  }

  .rotate-button-2.margin-top.tippy {
    position: static;
  }

  .heading-title-2-2 {
    padding: 0px;
    border: 1px none #000;
    background-color: transparent;
  }

  .div-block-324 {
    -ms-grid-rows: 85px auto;
    grid-template-rows: 85px auto;
  }

  .div-block-325 {
    padding-right: 36px;
    padding-left: 36px;
  }

  .div-block-326 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .div-block-327 {
    overflow: scroll;
    height: 100%;
    max-height: 37.5vw;
    padding-bottom: 9vw;
  }

  .green {
    color: #89bb3e;
  }

  .success-message-3 {
    opacity: 0.01;
  }

  .heading-title-2-2-copy {
    padding: 0px;
    border: 1px none #000;
    background-color: transparent;
    font-size: 18px;
  }

  .dropdown-toggle-4.w--open {
    border: 1px none #000;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #f0f1f3;
  }

  .div-block-334 {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .product-selection-main-picture-container-copy {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .product-selection-main-picture-container-copy.no-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .product-selection-main-picture-container-copy.prints {
    padding-top: 24px;
  }

  .slider-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .slider-mask {
    height: 100%;
  }

  .slide-2.slide-one {
    height: 100%;
  }

  .slider-wrap.prints {
    height: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .slide-nav-4 {
    margin-top: 0px;
  }

  .print-section-only-wrapper {
    z-index: 9999;
    display: none;
  }

  .green-2 {
    color: #89bb3e;
  }

  .div-block-345 {
    height: 100%;
    padding-bottom: 0px;
  }

  .image-91 {
    width: 40%;
  }

  .grid-89 {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 10%;
    z-index: 9;
    margin-top: 0px;
  }

  .dragula-container-copy {
    margin-top: 24px;
    -ms-grid-columns: 115px 115px 115px 115px 115px 115px 115px 115px;
    grid-template-columns: 115px 115px 115px 115px 115px 115px 115px 115px;
  }

  .container-rightside-order-summary-copy {
    position: relative;
  }

  .link-block-11 {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .link-block-11:hover {
    color: #333;
  }

  .link-block-12 {
    margin-top: 5px;
    padding: 2.5px 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-style: solid;
    border-width: 1px;
    border-color: #dbdbdb;
    border-radius: 5px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }

  .link-block-12:hover {
    border-color: #ddd;
    background-color: #333;
    color: #fff;
    font-weight: 600;
  }

  .div-block-350 {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .form-11 {
    display: -ms-grid;
    display: grid;
    margin-top: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    justify-items: end;
    grid-auto-columns: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .grid-92 {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    justify-items: flex-start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .form-block-18 {
    margin-bottom: 0px;
  }

  .div-block-353 {
    padding: 21px;
  }

  .client-note {
    display: block;
    opacity: 1;
  }

  .form-11-copy {
    display: -ms-grid;
    display: grid;
    margin-top: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
    justify-items: end;
    grid-auto-columns: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .div-block-98-copy {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .field-label-2-copy {
    font-weight: 400;
  }

  .link-block-14 {
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
  }

  .link-block-14:hover {
    color: #333;
  }

  .div-block-350-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
}

@media screen and (min-width: 1920px) {
  .grid {
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
  }

  .input {
    min-width: 400px;
  }

  .sub-container-cards {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .text-field.input {
    min-width: auto;
  }

  .wrapper {
    max-width: 1480px;
  }

  .container-topbar-orders {
    max-width: 1480px;
  }

  ._3rd-block-container-order {
    padding-right: 40px;
    padding-left: 40px;
  }

  .grid-element-order-summary {
    padding-right: 20px;
    padding-left: 20px;
    -ms-grid-columns: 40px 80px 250px 250px 160px 150px;
    grid-template-columns: 40px 80px 250px 250px 160px 150px;
  }

  .grid-element-order-summary.head {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid-element-order-summary.smaller {
    padding-right: 20px;
    padding-left: 20px;
    grid-auto-columns: 85px;
    -ms-grid-columns: 75px 210px 200px 210px 65px;
    grid-template-columns: 75px 210px 200px 210px 65px;
  }

  .grid-element-order-summary.smaller.number {
    padding-left: 20px;
  }

  .grid-element-order-summary.head {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid-element-order-summary.head.white.smaller {
    padding-right: 20px;
    padding-left: 20px;
    -ms-grid-columns: 75px 210px 200px 210px 65px;
    grid-template-columns: 75px 210px 200px 210px 65px;
  }

  .grid-element-order-summary.head.white.smaller.number {
    padding-left: 20px;
  }

  .container-rightside-order-summary.no-margin {
    padding: 0px;
    border-style: none;
  }

  .text-table.smaller {
    font-size: 14px;
  }

  .heading-container.aligned-left {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .grid-order-summary-container {
    height: auto;
    padding-bottom: 20px;
  }

  .grid-order-summary-container.no-scroll {
    height: 83.5vh;
  }

  .div-block-details {
    width: 100%;
    max-width: 500px;
  }

  .admin-photographer-subcontainer.big-screen {
    margin-top: 24px;
    padding: 48px;
  }

  .div-notes {
    display: none;
  }

  .tab-pane-tab-2-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .grid-38 {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .div-block-143 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 400px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    font-size: 12px;
  }

  .div-block-108-copy {
    margin-bottom: 0px;
    font-size: 14px;
  }

  .tab-link-tab-1-copy {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f7f7f7;
    opacity: 1;
    color: #333;
    font-size: 14px;
    font-weight: 400;
  }

  .print-order-sidebar.grid.absolute {
    min-width: 300px;
  }

  .product-selection-main-picture-container {
    padding-bottom: 24px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-controls-product-selection-grid {
    max-width: 82vw;
  }

  .print-order-sidebar-container {
    padding-top: 48px;
    padding-right: 48px;
    padding-left: 48px;
  }

  .grid-images-print-order {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .img-container-print-order {
    padding-top: 48px;
    padding-right: 48px;
    padding-left: 24px;
  }

  .div-block-white.max-width-500px.margin-top-60 {
    max-width: none;
  }

  .container-order-review {
    padding-right: 60px;
  }

  .grid-67 {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .search-bar-div {
    top: 46px;
    padding-left: 48px;
  }

  .album-sequence-container {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .dragula-container {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .c-card__one {
    width: 160px;
    height: 160px;
  }

  .img-dragable {
    width: 120px;
    height: 120px;
  }

  .div-block-320 {
    max-width: none;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .rotate-button-2.margin-top {
    margin-top: 48px;
  }

  .div-block-325 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .div-block-331 {
    max-height: 650px;
    max-width: 1100px;
    padding: 96px;
  }

  .div-block-332 {
    width: 40%;
  }

  .div-block-333 {
    width: 50%;
    height: 65%;
  }

  .div-block-334 {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    height: 120px;
    padding-right: 0px;
  }

  .product-selection-main-picture-container-copy {
    padding-bottom: 120px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .product-selection-main-picture-container-copy.no-padding {
    padding-bottom: 72px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .product-selection-main-picture-container-copy.prints {
    padding-bottom: 0px;
  }

  .slider-2 {
    height: 100%;
  }

  .slider-wrap.prints {
    height: 100%;
    padding-bottom: 24px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .slide-nav-4 {
    position: static;
    font-size: 16px;
  }

  .div-block-345 {
    height: 100%;
  }

  .image-91 {
    width: 45%;
  }

  .dragula-container-copy {
    grid-auto-columns: 160px;
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    -ms-grid-columns: 160px 160px 160px 160px 160px 160px 160px 160px;
    grid-template-columns: 160px 160px 160px 160px 160px 160px 160px 160px;
    -ms-grid-rows: 160px;
    grid-template-rows: 160px;
  }

  ._3rd-block-container-order-copy {
    padding-right: 40px;
    padding-left: 40px;
  }

  ._3rd-block-container-order-copy.unbundled-list {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .navbar {
    position: fixed;
    width: 250px;
  }

  .grid-sidebar {
    padding-left: 15px;
  }

  .sidebar-button {
    padding-left: 15px;
  }

  .icon.big {
    width: 52px;
    height: 52px;
  }

  .grid-main-container {
    padding-right: 0px;
    padding-left: 70px;
  }

  .input {
    width: 100%;
    max-width: 400px;
    min-width: 200px;
  }

  .link-block-3 {
    padding-right: 24px;
    padding-left: 24px;
  }

  .sub-container-cards {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .image-5 {
    height: 150px;
  }

  .grid-search-bar {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .top-button {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .modal-scroll-2 {
    padding-top: 50px;
    padding-right: 5%;
    padding-left: 5%;
  }

  .accordion {
    width: 83.33%;
    padding-right: 8px;
    padding-left: 8px;
  }

  .sidebar-product-selection {
    width: 280px;
  }

  .form-select-field {
    font-size: 12px;
  }

  .grid-form {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-dynamic {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .link-block-10 {
    padding-left: 15px;
  }

  .screen-blocker {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 99999999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f7f7f7;
  }

  .div-block-267 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
  }

  .grid-13-copy {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .slider-2 {
    height: 24vh;
  }
}

@media screen and (max-width: 767px) {
  .upgrade-container-2 {
    margin-bottom: 70px;
    padding-top: 50px;
    padding-right: 7%;
    padding-left: 7%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .modal-scroll-2 {
    overflow: auto;
  }

  .accordion {
    width: 100%;
    margin-bottom: -16px;
  }

  .accordion__item {
    margin-bottom: 16px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .accordion-item-content {
    margin-right: 0px;
  }

  .accordion-item-block {
    margin-right: 0px;
  }

  .form-block-3 {
    width: 80%;
  }

  .main-button {
    padding-right: 2px;
  }
}

@media screen and (max-width: 479px) {
  .grid {
    position: relative;
    overflow: visible;
    height: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .brand {
    height: 60px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar {
    display: none;
  }

  .grid-sidebar {
    padding-left: 0px;
    justify-items: center;
  }

  .sidebar-button {
    width: auto;
    min-width: 150px;
    padding-right: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px none #000;
    border-radius: 15px;
  }

  .sidebar-button:hover {
    padding-right: 25px;
  }

  .sidebar-button.w--current {
    border: 1px none #000;
  }

  .body {
    font-size: 12px;
  }

  .logo-img {
    width: 32px;
    margin-right: 7.5px;
    padding-bottom: 0px;
  }

  .text-logo {
    font-size: 20px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  .icon.small {
    width: 14px;
    height: 14px;
  }

  .icon.small.right-margin {
    width: 14px;
    height: 14px;
  }

  .icon.smaller {
    width: 14px;
    height: 14px;
  }

  .icon.right-margin {
    width: 18px;
    height: 18px;
  }

  .div-block-6 {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }

  .grid-main-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    max-height: none;
    padding: 60px 15px 0px;
  }

  .grid-container-controls {
    display: none;
  }

  .sub-container-cards {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 150px 150px;
    grid-template-columns: 150px 150px;
  }

  .main-cards-container {
    overflow: visible;
    width: 100%;
    height: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .image-5 {
    height: 110px;
  }

  .product-detail-div {
    padding: 12px;
    font-size: 11px;
  }

  .title-info-card {
    font-size: 12px;
  }

  .grid-6 {
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: flex-start;
    align-content: flex-start;
    font-size: 10px;
    line-height: 10px;
    text-align: left;
  }

  .overflow-menu__toggle.frost-blur {
    width: 30px;
    height: 30px;
  }

  .container-notification {
    display: none;
  }

  .div-block-33 {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 5;
    display: block;
    border: 1px none #000;
    background-color: transparent;
    background-image: url('../images/605c8a9d09ffb36a2e88ae22_base.svg');
    background-position: 50% 50%;
    background-size: cover;
  }

  .grid-11 {
    padding-top: 5px;
    padding-bottom: 5px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    justify-items: center;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .div-block-34 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 47px;
    height: 47px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-34.main {
    position: relative;
    top: -26px;
    border-radius: 100%;
    background-color: #333;
    box-shadow: 0 1px 3px -2px #000;
    color: #fff;
  }

  .div-block-34.main {
    position: relative;
    top: -26px;
    border-radius: 100%;
    background-color: #333;
    box-shadow: 0 1px 3px -2px #000;
    color: #fff;
  }

  .navbar-2 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 1px 3px -3px #000;
  }

  .div-block-35 {
    display: -ms-grid;
    display: grid;
    width: 100%;
    height: 60px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .nav-menu-2 {
    width: 100%;
    margin-top: 60px;
    border-top: 1px solid #ddd;
    background-color: #fff;
  }

  .menu-button {
    background-color: transparent;
  }

  .menu-button.w--open {
    width: 60px;
    height: 60px;
    background-color: #fff;
    color: #333;
  }

  .div-block-36 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40px;
    height: 60px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-37 {
    display: -ms-grid;
    display: grid;
    justify-items: end;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .dot.bigger.paper.tippy {
    width: 16px;
    height: 16px;
    font-size: 8px;
  }

  .dot.bigger.paper.tippy {
    width: 16px;
    height: 16px;
    font-size: 8px;
  }

  .div-block-47 {
    display: none;
  }

  .urgent.tippy {
    width: 25px;
    height: 25px;
  }

  .div-block-56 {
    font-size: 9px;
  }

  .div-block-60 {
    width: 16px;
    height: 16px;
    font-size: 0px;
  }

  .toggle-status {
    height: 16px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 9px;
  }

  .note.tippy {
    width: 25px;
    height: 25px;
  }

  .grid-23.main._2 {
    font-size: 12px;
  }

  .grid-23.main._2 {
    font-size: 12px;
  }

  .sub-container-orders {
    overflow: visible;
    height: auto;
    margin-top: 60px;
    padding: 10px;
  }

  .container-order-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .grid-details {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    grid-auto-flow: row;
  }

  .grid-details.right {
    margin-top: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
  }

  .heading-gray {
    font-size: 16px;
  }

  .heading-big {
    font-size: 28px;
  }

  .order-top-div {
    width: 100%;
    padding: 30px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .heading-title-2 {
    font-size: 20px;
  }

  .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: none;
    padding: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .container-topbar-orders {
    padding: 0px;
  }

  .grid-24 {
    width: 100%;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: flex-start;
    align-content: flex-start;
    grid-auto-flow: row;
    -ms-grid-columns: auto auto auto;
    grid-template-columns: auto auto auto;
  }

  .div-block-99 {
    height: 47px;
  }

  ._2nd-button.main {
    height: 47px;
    font-size: 14px;
  }

  ._2nd-button.main.smaller.transparent {
    padding: 6px 10px;
    font-size: 10px;
  }

  ._2nd-button.secondary {
    height: 47px;
  }

  .status-order {
    height: 25px;
    min-width: 55px;
    padding-top: 2px;
    padding-bottom: 0px;
  }

  .grid-order-icons {
    justify-items: center;
    grid-auto-flow: row;
    grid-column-gap: 5px;
  }

  .grid-order-icons.mobile {
    overflow: scroll;
    grid-auto-flow: column;
  }

  ._3rd-block-container-order {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 30px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .heading-title {
    font-size: 20px;
    line-height: 140%;
  }

  .grid-element-order-summary {
    overflow: visible;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .grid-element-order-summary.head {
    width: auto;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .grid-element-order-summary.smaller.email {
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 30px auto auto auto;
    grid-template-columns: 30px auto auto auto;
  }

  .grid-element-order-summary.head {
    width: auto;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
  }

  .heady {
    height: 47px;
  }

  .container-rightside-order-summary {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .content {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .img-order-summary {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
  }

  .text-table {
    font-size: 12px;
  }

  .grid-order-summary-container {
    overflow: visible;
    width: 100%;
    max-height: none;
  }

  .container-order-title {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    grid-auto-flow: row;
  }

  .grid-topbar-orders {
    width: auto;
    max-width: none;
  }

  .sub-grid-topbar-orders {
    justify-items: flex-start;
    grid-auto-flow: row;
  }

  .checkbox-4 {
    width: 16px;
    height: 16px;
  }

  .order-2nd-div {
    width: 100%;
    padding: 30px 20px;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .main-grid-info-order {
    margin-top: 15px;
    grid-auto-flow: row;
  }

  .grid-info-order {
    width: 100%;
    grid-row-gap: 5px;
  }

  .grid-info-order.centered.margin-top {
    margin-top: 0px;
  }

  .main-info-block-container {
    width: 100%;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
    grid-row-gap: 30px;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .container-orders {
    display: block;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .grid-button-notes {
    padding-left: 0px;
  }

  .grid-button-notes.margin-responsive-mobile {
    padding-left: 15px;
  }

  .upgrade-container-2 {
    margin-bottom: 70px;
    padding-right: 5%;
    padding-bottom: 0px;
    padding-left: 5%;
  }

  .upgrade-button {
    width: 47px;
    height: 47px;
    padding-right: 0px;
    padding-left: 10px;
  }

  .link-block-5-copy {
    width: 47px;
    height: 47px;
  }

  .rush.tippy {
    width: 25px;
    height: 25px;
  }

  .overflow-menu__toggle-copy.frost-blur {
    width: 30px;
    height: 30px;
  }

  .tabs-2 {
    width: auto;
  }

  .div-notes {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .info-block-container {
    width: 100%;
  }

  .grid-tracking.mobile {
    grid-auto-flow: row;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .sub-grid-icons-order {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
  }

  .tabs-content-2 {
    overflow: visible;
    height: auto;
  }

  .grid-bottom-details {
    grid-auto-flow: row;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .text-block-30 {
    font-size: 0px;
  }

  .print-order-sidebar.grid {
    display: -ms-grid;
    display: grid;
    overflow: scroll;
    height: auto;
    padding-top: 15px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border: 1px none #000;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    box-shadow: none;
  }

  .accordion-header.js-accordion-header:hover {
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .button-2.secondary {
    font-size: 12px;
  }

  .accordion-body {
    padding-left: 56px;
  }

  .accordion-body.js-accordion-body {
    margin-top: 20px;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .accordion-item-2 {
    width: 100%;
  }

  .sidebar-product-selection {
    overflow: scroll;
    width: auto;
    height: auto;
    padding-top: 2px;
    border-right-style: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .sidebar-product-selection.white {
    height: 50vh;
  }

  .form-select-field {
    font-size: 12px;
  }

  .sub-label-form {
    font-size: 8px;
  }

  .input-container.paper-type {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .input-container.paper-type {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .text-field-2.font-size-14 {
    font-size: 12px;
  }

  .grid-form {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-form._1fr {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-form._1fr {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-print-order {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .div-block-5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 35px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .checkbox-6.small {
    margin-top: 1px;
    border-color: #616161;
  }

  .form-item-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .image-66 {
    width: 45px;
    margin-bottom: 10px;
  }

  .div-block-181 {
    padding: 40px 30px;
  }

  .div-full-screen.white-smoke {
    padding: 10px;
  }

  .eye {
    width: 45px;
    height: 45px;
  }

  .form-block-3 {
    width: 100%;
  }

  .heading-6 {
    font-size: 26px;
    line-height: 120%;
    text-align: center;
    white-space: normal;
  }

  .main-button {
    padding-right: 32px;
  }

  .div-block-182 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .div-block-183 {
    padding: 40px 30px;
  }

  .div-block-183.add-block {
    width: 100%;
    min-width: auto;
  }

  .grid-dynamic {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .client-account-sub-container {
    padding: 10px;
  }

  .client-account-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .client-account-block2 {
    padding: 48px 20px;
  }

  .client-account-block2.horizontal {
    padding-right: 24px;
    padding-left: 24px;
    grid-auto-flow: row;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .review-cart-container {
    min-height: auto;
    padding: 30px 10px;
  }

  .grid-review-cart {
    max-width: none;
    grid-auto-flow: row;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .checkbox-field-4 {
    font-size: 14px;
  }

  .order-summary-container {
    width: 100%;
    min-width: auto;
    padding: 20px;
  }

  .form-cart-container {
    padding: 10px;
  }

  .sub-heading-summary {
    font-size: 14px;
  }

  .div-big-text {
    font-size: 14px;
  }

  .grid-55 {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .heading-title-summary {
    font-size: 16px;
  }

  .div-block-224 {
    margin-top: 0px;
  }

  .accordion-item {
    width: 100%;
  }

  .grid-bottom-buttons.auto.margin-24px.margin-top {
    grid-auto-flow: row;
  }

  .sub-container-top-cart {
    margin-bottom: 24px;
  }

  .brand-2 {
    padding-left: 0px;
  }

  .image-2-copy {
    width: 32px;
    margin-right: 7.5px;
    padding-bottom: 0px;
  }

  .top-div-container-cart {
    max-width: none;
    padding-left: 10px;
  }

  .text-block-copy {
    font-size: 24px;
    line-height: 24px;
  }

  .text-block-copy.big {
    font-size: 28px;
    line-height: 28px;
  }

  .address-info-wrap {
    display: none;
  }

  .grid-58 {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .client-credit-block {
    width: 100%;
    min-width: auto;
    padding: 20px;
  }

  .client-credit-container {
    padding: 20px 10px;
  }

  .sub-text-heading {
    font-size: 20px;
  }

  .link-block-10 {
    width: auto;
    min-width: 150px;
    padding-right: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px none #000;
    border-radius: 15px;
  }

  .link-block-10:hover {
    padding-right: 25px;
  }

  .link-block-10.w--current {
    border: 1px none #000;
  }

  .nav-menu-3 {
    width: 100%;
    margin-top: 60px;
    border-top: 1px solid #ddd;
    background-color: #fff;
  }

  .menu-button-2 {
    background-color: transparent;
  }

  .menu-button-2.w--open {
    width: 60px;
    height: 60px;
    background-color: #fff;
    color: #333;
  }

  .screen-blocker {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 999999;
    width: 100%;
    height: 100vh;
  }

  .body-email {
    padding-top: 24px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
  }

  .block-email-container {
    padding: 48px 24px;
  }

  .paragraph-4 {
    font-size: 14px;
  }

  .div-block-258 {
    display: -ms-grid;
    display: grid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .grid-71 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-30-copy {
    margin-top: 15px;
    grid-auto-flow: row;
  }

  .image-86-copy {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
  }

  .div-block-267 {
    width: 100%;
    max-width: 250px;
  }

  .overflow-menu__toggle-copy.frost-blur {
    width: 30px;
    height: 30px;
  }

  .lottie-animation-5 {
    left: 70px;
    top: 81px;
    width: 75px;
  }

  .admin-photographers-container {
    display: block;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .grid-13-copy {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-31-copy-copy {
    width: 100%;
    grid-row-gap: 5px;
  }

  .container-full-screen {
    padding-right: 24px;
    padding-left: 24px;
  }

  .link-block-8-2.secondary {
    font-size: 12px;
  }

  .heading-4-copy-2 {
    font-size: 20px;
  }

  .button-2-2.secondary {
    font-size: 12px;
  }

  .button-2-2.secondary {
    font-size: 12px;
  }

  .title-info-card-2 {
    font-size: 12px;
  }

  .heading-title-2-2 {
    font-size: 20px;
  }

  .heading-title-2-2-copy {
    font-size: 20px;
  }

  ._3rd-block-container-order-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 30px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  .container-rightside-order-summary-copy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }

  ._2nd-button-2.main {
    height: 47px;
    font-size: 14px;
  }

  .div-block-358 {
    height: 47px;
  }

  .checkbox-7 {
    width: 16px;
    height: 16px;
  }
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde536-a9e809c7 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde53b-a9e809c7 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde540-a9e809c7 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde545-a9e809c7 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde566-a9e809c7 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_344acf15-8f2b-e745-5dfe-960f4d50f02a-a9e809c7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_8afe4b18-289a-31ff-3e3f-c4f5718c11c8-a9e809c7 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_15384a58-73dd-f21d-2855-6e36925ebb65-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7e2c5523-c834-6180-9eb6-d0d5b99e291b-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ae6aa088-e345-05ee-5042-447e93666784-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1a8a7a44-9ddc-f136-225b-920337a90bb1-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8ce50f10-360e-c1be-71bc-6e5049bcd4cc-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_92beaeb7-58ee-cc48-de46-e4d5645f911a-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b988176b-e7da-cb9b-11bb-ad3070bad0d3-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b36d847d-e8af-8263-bcf5-0b874d6ab198-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5716247c-f6fd-705a-79a1-ed0ea7c62629-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f643152f-042b-3957-cf9a-f08f9dc8a55d-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d0221767-c516-2aa0-dca0-da9c4ed18479-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_62934a7b-01cb-803b-cf8b-7cb4e7ba68bb-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7d53d554-1f8f-96db-4ced-47d38946367f-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_35d40a81-a306-b7a1-4c64-7260a3d49c18-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b83bb0a7-742e-cb0a-6b17-db8fd65ee337-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a9846c79-b27c-9450-c876-426f49097950-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a94be3dc-d30c-c649-2427-5946b049ec7b-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2ed7d511-8b16-3b16-890b-c5acaf7afb5e-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-adf80ed4-ddac-0993-d9f7-3dceb55c5af0-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_777d5532-4068-ef89-3993-138f3a3c9962-a9e809c7 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba06-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba0b-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba10-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba15-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba1a-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba37-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba3c-d124b9fc {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-97e80a0e {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde536-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde53b-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde540-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde545-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde566-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ff333d0c-e8bf-7c67-544f-ead3be75efac-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_44b07485-3d4e-1b08-3415-2cdb0066d9be-6be80a0f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c42-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c44-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c53-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c56-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c59-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f031-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f03d-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f08f-6be80a0f {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f0a3-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5bd3ad64-6310-33aa-344e-10bfa38d8265-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5bd3ad64-6310-33aa-344e-10bfa38d8271-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82c3-6be80a0f {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82d7-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_567bc931-e071-9e48-babc-7515dde8f66f-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_567bc931-e071-9e48-babc-7515dde8f67b-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_567bc931-e071-9e48-babc-7515dde8f6c6-6be80a0f {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_567bc931-e071-9e48-babc-7515dde8f6da-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_920fd572-641e-ffa9-5aa0-7d7654db84df-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_920fd572-641e-ffa9-5aa0-7d7654db84eb-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_920fd572-641e-ffa9-5aa0-7d7654db8536-6be80a0f {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_920fd572-641e-ffa9-5aa0-7d7654db854a-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d9289f50-4ff7-3f06-0560-ece5833bd289-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d9289f50-4ff7-3f06-0560-ece5833bd295-6be80a0f {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d9289f50-4ff7-3f06-0560-ece5833bd2e0-6be80a0f {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d9289f50-4ff7-3f06-0560-ece5833bd2f4-6be80a0f {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-aeb2fb91-2b12-b2d2-b3f8-4129190addde-6be80a0f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_652204d4-e5ff-36d4-25fb-e3b74f1e5df7-6be80a0f {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_652204d4-e5ff-36d4-25fb-e3b74f1e5e13-6be80a0f {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde536-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde53b-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde540-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde545-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde566-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f5c-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f77-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f7c-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29f7f-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29fb1-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29fb6-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb29fb9-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_10c08f2c-bf24-d011-70b8-9278d2460286-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_10c08f2c-bf24-d011-70b8-9278d246028b-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_10c08f2c-bf24-d011-70b8-9278d2460295-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1957a58b-34a3-aa4c-f786-5238103498df-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_1957a58b-34a3-aa4c-f786-5238103498e4-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_1957a58b-34a3-aa4c-f786-5238103498ee-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-be23bd0c-08ab-dc3b-1dfa-92a4409c3c36-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-be23bd0c-08ab-dc3b-1dfa-92a4409c3c3b-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-be23bd0c-08ab-dc3b-1dfa-92a4409c3c45-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_842f72dc-7580-17fe-ca83-afb307d35798-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_842f72dc-7580-17fe-ca83-afb307d3579d-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_842f72dc-7580-17fe-ca83-afb307d357a7-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7665cb2d-1dea-ac1b-99a8-ba70009e1c27-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_7665cb2d-1dea-ac1b-99a8-ba70009e1c2c-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_7665cb2d-1dea-ac1b-99a8-ba70009e1c36-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2666b5ba-c15e-ee8a-bd8a-ca04af6b4c97-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_2666b5ba-c15e-ee8a-bd8a-ca04af6b4c9c-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_2666b5ba-c15e-ee8a-bd8a-ca04af6b4ca6-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-aea9534c-6f79-bc7f-d75d-f2d68974569a-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-aea9534c-6f79-bc7f-d75d-f2d68974569f-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-aea9534c-6f79-bc7f-d75d-f2d6897456a9-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb2a17e-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb2a199-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb2a19e-67e80a11 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_79f08531-6afd-7b9d-2f32-6719dcb2a1a1-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_4b1c7c0c-bd43-5e33-cfcf-e7c3d6643ea4-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_4b1c7c0c-bd43-5e33-cfcf-e7c3d6643ea9-67e80a11 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4b1c7c0c-bd43-5e33-cfcf-e7c3d6643eb3-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_2a43b6b7-8fe5-facb-a7f1-234cdb92eb58-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_2a43b6b7-8fe5-facb-a7f1-234cdb92eb5d-67e80a11 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2a43b6b7-8fe5-facb-a7f1-234cdb92eb67-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_76dafe11-66b2-06f7-d98c-9ad441e9a0ce-67e80a11 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_76dafe11-66b2-06f7-d98c-9ad441e9a0d3-67e80a11 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_76dafe11-66b2-06f7-d98c-9ad441e9a0dd-67e80a11 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-22e80a12 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_13845923-1f53-7434-fb6b-fbd05663125e-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_42f471fb-7499-65b3-66ef-53ebaedabf69-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_338e3b1a-0d1f-f4ee-082b-f2a36b408d22-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-ca87f8e6-7922-3ce0-a26a-f9eb9db0c079-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_55a59b19-4df9-afb5-e615-ac103138be92-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_13845923-1f53-7434-fb6b-fbd0566312ac-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd0566312d2-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd0566312e7-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_13845923-1f53-7434-fb6b-fbd0566312fb-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd05663130d-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd05663131f-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_13845923-1f53-7434-fb6b-fbd056631333-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd056631345-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd056631357-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_13845923-1f53-7434-fb6b-fbd056631369-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ba4e0a7a-1ac6-08ce-fbeb-191abbeea77c-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-ba4e0a7a-1ac6-08ce-fbeb-191abbeea78b-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-ba4e0a7a-1ac6-08ce-fbeb-191abbeea79b-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-ba4e0a7a-1ac6-08ce-fbeb-191abbeea7aa-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_6aafd1cc-6ae2-1a92-7714-bff1de310b57-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_6aafd1cc-6ae2-1a92-7714-bff1de310c81-0ce80a13 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#data-jscolor.w-node-_920c75da-5e95-8642-fe08-e9bae125f0fc-0ce80a13 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#data-jscolor.w-node-_5fa2a662-2c52-5b3e-f7ab-d79480392f8c-0ce80a13 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_186b4661-3441-ebed-799a-637652a70b1f-0ce80a13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_0bddf85c-9418-5271-b783-6289be9e436b-0ce80a13 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_73193249-9351-5600-a63c-74cbe1db70cc-0ce80a13 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_66260a0b-5f09-08cc-fedf-d8ea3690ffe9-0ce80a13 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_815a993c-1e54-8066-26c2-cac5c0e63fbc-0ce80a13 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_722a9e77-02a0-5936-ec3e-d36e27be32d4-27be32ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_722a9e77-02a0-5936-ec3e-d36e27be32e3-27be32ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_722a9e77-02a0-5936-ec3e-d36e27be32e8-27be32ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_722a9e77-02a0-5936-ec3e-d36e27be32ef-27be32ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_722a9e77-02a0-5936-ec3e-d36e27be3305-27be32ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_722a9e77-02a0-5936-ec3e-d36e27be330a-27be32ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_1a855403-83e1-ab3f-f4c1-a8aaaf65ce05-04e80a14 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_1a855403-83e1-ab3f-f4c1-a8aaaf65ce14-04e80a14 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_3eb8618e-5b71-88ed-5312-95c092571618-04e80a14 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_3eb8618e-5b71-88ed-5312-95c092571627-04e80a14 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a9aac738-f91f-229c-42ce-b1c9ecd0c698-04e80a14 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-a9aac738-f91f-229c-42ce-b1c9ecd0c6a7-04e80a14 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_19b91d99-b2bb-08f8-bd04-595c46589c59-04e80a14 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde536-fde80a15 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde53b-fde80a15 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde540-fde80a15 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde545-fde80a15 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde566-fde80a15 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_44b07485-3d4e-1b08-3415-2cdb0066d9be-fde80a15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4ff616b2-037f-87d4-e48c-76a9c4e5c1fc-fde80a15 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ff43ca08-2906-f71c-d8e7-5ad72bc9fe03-fde80a15 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_7d3ac1dd-3d07-762b-9d90-02a38c7a2fc4-fde80a15 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_32a48fbf-c764-bc84-5c0d-03a231ccaaaf-fde80a15 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5cc3a6bb-bec2-04c4-323a-08b79b9bd88a-fde80a15 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-cc216a9f-0516-f316-d257-e781bc306570-fde80a15 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_652204d4-e5ff-36d4-25fb-e3b74f1e5df7-fde80a15 {
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
}

#w-node-_652204d4-e5ff-36d4-25fb-e3b74f1e5e13-fde80a15 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde536-abe80a19 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde53b-abe80a19 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde540-abe80a19 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde545-abe80a19 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde566-abe80a19 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_344acf15-8f2b-e745-5dfe-960f4d50f02a-abe80a19 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_8afe4b18-289a-31ff-3e3f-c4f5718c11c8-abe80a19 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-b85c4af7-f736-7b47-03da-55b12a957c10-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_14dc6af3-13f3-a5a2-1905-e9465a70f453-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-bf0754de-561a-1cae-c4df-c75483c5f3d1-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_595fa15e-81ac-9e6e-274a-05f1f914c73f-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c001d012-1810-036c-8292-d11fc4bb0cfa-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_564ca4ee-57e9-785d-1fa7-c9a53d8828d9-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3cfbc676-67d5-221e-6439-3d868bd9964b-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_29590915-01bf-c7b8-9a8b-c47f32e8d9de-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_904328cd-1edd-6411-3451-3f63b9216b98-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5cef3216-7026-1f67-7b72-25e0ad3c0aa4-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2298dc80-6a10-8c89-3915-5e0e4eb29a24-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3bd8c8fb-7d88-d7d6-eb28-10d925a0cd61-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e3d5f496-c451-0c8e-f800-b1fcd9f2057a-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-e6bd2215-24fa-54be-ae10-7e51090e497f-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_69547107-2f8f-86b3-c47b-b294a955eb83-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6828e7ec-3825-a46e-bb92-b6420123654c-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1b850d64-60ad-b778-f19f-aa3aaab799df-abe80a19 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-d7e80a1a {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-9ae80a1b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-16e80a1c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-7de80a1d {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599e61-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599e63-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0050eec6-e21d-310f-8a29-aae3e0904df1-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbee4-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbeee-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbef2-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599e85-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599eab-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599ec0-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599ed4-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599ee6-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599ef8-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599f0c-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599f1e-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599f30-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c599f42-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-e418690d-dbc5-cf5f-c30d-91fe8bceff96-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a076-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a09c-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a0b1-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a0c5-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a0d7-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a0e9-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a0fd-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a10f-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a121-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a133-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-edb36a92-3594-4788-3cce-c3be0c59a148-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db240b-41e80a1e {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db2425-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db244b-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db2460-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db2474-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db2486-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db2498-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db24ac-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db24be-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db24d0-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db24e2-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1b64aab0-de3b-c12f-f198-608da5db250f-41e80a1e {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-e7628b6d-eccf-94d0-3bc3-daad3e4764c1-41e80a1e {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_19d2efc2-08d5-fc70-7d3d-5c561b54e339-41e80a1e {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea2128d-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea212b3-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea212c8-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea212dc-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea212ee-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea21300-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea21314-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea21326-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea21338-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2fc185df-c97d-649c-92cb-4a52bea2134a-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d69b5-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d69db-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d69f0-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a04-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a16-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a28-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a3c-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a4e-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a60-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_10fff537-b160-ec7e-6eeb-8d023d3d6a72-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f6db6d51-cc6f-2564-c876-ff83d387b32e-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-a184e61d-5a93-35ac-408d-8379ec332cac-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-a184e61d-5a93-35ac-408d-8379ec332cbb-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4e3b-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4e61-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4e76-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4e8a-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4e9c-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4eae-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4ec2-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4ed4-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4ee6-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-aab9bdcd-494e-1cc2-5273-8bf7703e4ef8-41e80a1e {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-b92431e6-1250-9465-1635-0b8636fbfd6d-41e80a1e {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52466f5-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524671f-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246745-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524675a-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524676e-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246780-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246792-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467a6-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467b8-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467ca-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467dc-ece80a36 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52466f5-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524671f-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246745-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524675a-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524676e-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246780-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246792-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467a6-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467b8-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467ca-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467dc-a5e80a47 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52466f5-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524671f-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246745-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524675a-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_32811dee-b088-9225-f64f-fc05d524676e-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246780-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d5246792-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467a6-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467b8-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467ca-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_32811dee-b088-9225-f64f-fc05d52467dc-81e80a48 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a2441164c-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a2441165c-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a24411682-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_79897ac1-1d3e-128c-1fa7-d5c7764520c4-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-eaf3fe18-d18d-b6ac-8d17-cfe23919bc4d-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_8b379125-90a6-7d04-edd2-bcdf9dde0fcf-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdd4-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefde6-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdf8-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a16-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a28-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a3a-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c1c7bde4-8fac-4ab8-4dc4-37f5d4782818-f6e80a49 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-f6e80a49 {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_2dfa92fa-719e-7852-4c62-0cb41c2db9b9-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-b7640de5-7a20-fbc4-52d4-86e40130e18c-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-b7640de5-7a20-fbc4-52d4-86e40130e190-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_5e867f46-6da5-b723-f68a-2e4c9f796ec3-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_5e867f46-6da5-b723-f68a-2e4c9f796ec7-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_24ed214b-b9c6-ce65-d57a-53adcde2e291-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_24ed214b-b9c6-ce65-d57a-53adcde2e295-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_54d01a59-d65b-7981-cc18-40c03cb265e6-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_54d01a59-d65b-7981-cc18-40c03cb265ea-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_87caf8a1-06aa-5fe5-7ca1-b3d78a0e4c40-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_87caf8a1-06aa-5fe5-7ca1-b3d78a0e4c44-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b897346d-5f82-c2e7-6486-813241fa8c93-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-b897346d-5f82-c2e7-6486-813241fa8c97-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2cfc47b1-1891-d99f-88ff-888d67c9e03e-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_2cfc47b1-1891-d99f-88ff-888d67c9e042-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6c45f54a-fdc1-1ba7-f602-27dbd410bc96-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_6c45f54a-fdc1-1ba7-f602-27dbd410bc9a-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_44c8b052-504a-36bd-271a-a51c5f36b6e6-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_44c8b052-504a-36bd-271a-a51c5f36b6ea-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_2f23d97a-7efb-489d-3589-090bb60208c1-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_2f23d97a-7efb-489d-3589-090bb60208c5-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_76d6a7ea-f40c-cc34-fa82-12ea4cc70a5f-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_76d6a7ea-f40c-cc34-fa82-12ea4cc70a63-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_27baf6b5-1d1f-ec8a-e2e6-329b7f56dbe6-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_27baf6b5-1d1f-ec8a-e2e6-329b7f56dbea-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4a6e14e8-ce71-2423-4961-c3218181bf81-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_4a6e14e8-ce71-2423-4961-c3218181bf85-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1d4d3a48-d549-b937-c0aa-614d35ed0f48-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_1d4d3a48-d549-b937-c0aa-614d35ed0f4c-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ac3ae30f-4a5c-a5c2-0007-188b5848b170-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-ac3ae30f-4a5c-a5c2-0007-188b5848b174-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ef42c296-4a00-f197-8cbc-638b634f06dc-f6e80a49 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-ef42c296-4a00-f197-8cbc-638b634f06e0-f6e80a49 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a2441165c-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_14965116-ff45-6c9d-417b-5548495e2e27-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_14965116-ff45-6c9d-417b-5548495e2e4c-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_3b9d4bd1-cf74-f802-e0a6-f9c94a27e33c-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_3b9d4bd1-cf74-f802-e0a6-f9c94a27e361-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c027399f-7562-e8b6-e026-2e8c3a98bf75-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c027399f-7562-e8b6-e026-2e8c3a98bf9a-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_67d9aab5-6c3b-0000-4a1b-e4c6bbe242cd-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_67d9aab5-6c3b-0000-4a1b-e4c6bbe242f2-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9c4-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9e9-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4a120b44-a597-ab3a-8792-fefbeb26d9d2-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c5b29070-bb85-0593-0462-7bdb85104048-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_79897ac1-1d3e-128c-1fa7-d5c7764520c4-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-eaf3fe18-d18d-b6ac-8d17-cfe23919bc4d-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_8b379125-90a6-7d04-edd2-bcdf9dde0fcf-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdd4-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefde6-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdf8-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a16-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a28-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a3a-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c1c7bde4-8fac-4ab8-4dc4-37f5d4782818-9be80a4a {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-9be80a4a {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_8e27bbd8-5f5a-2f6c-06d6-f4fa24ae86fa-9be80a4a {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3373c-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33752-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33776-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33789-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3379b-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa337bf-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa337e4-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa337f9-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3380b-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33820-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33832-00e80a4b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33833-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33845-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33854-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33867-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_3380b0e3-683e-864d-37e7-548450c35c1f-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33875-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3388d-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa338d7-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3389f-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa338ea-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa338fd-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_85a63cac-e2c7-080c-e07e-357b3d741095-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3390d-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33920-00e80a4b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33924-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33939-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3394b-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3395d-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3396f-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33981-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-cf803ae4-b21a-7592-4d66-d4fc5822967c-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33995-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339a7-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339b9-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339cb-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339dd-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339ee-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a01-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7982e0e8-4a86-3a85-8509-7ef9940ae7f2-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a15-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a29-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a3c-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a4f-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a62-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a71-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a84-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7f47e100-59bc-275d-8f0a-09197042a363-00e80a4b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33aa8-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ace-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ae2-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33af4-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b06-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b1a-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b2c-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b3e-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b50-00e80a4b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b85-00e80a4b {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b9a-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ba6-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33baa-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bb0-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bb4-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bba-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bbe-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bc2-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bc6-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bca-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bce-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bd2-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bd6-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bda-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bde-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33be2-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33be6-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bea-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bee-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bf2-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bf6-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bfa-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33bfe-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c02-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c06-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c0a-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c0e-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c12-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c16-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c1a-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c1e-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c22-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33c26-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca1-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca5-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cae-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cb5-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cb9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cbd-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc1-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc5-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ccd-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd1-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd5-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cdd-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce1-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce5-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ced-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf1-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf5-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cfd-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d01-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d05-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d09-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d0d-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d11-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d15-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d19-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d1d-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d21-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bc3-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bc7-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bcd-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bd1-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bd7-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bdb-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4be1-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4be5-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4beb-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bef-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bf5-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bf9-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bff-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c03-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c07-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c0b-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c0f-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c13-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c17-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c1b-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c1f-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c23-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c27-00e80a4b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c2b-00e80a4b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a2441165c-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-cb859e8a-d0d5-3cd8-8d53-8d5d48f8fd12-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_9ba91436-3fcf-2a37-38e3-957c4957edb1-7be80a4c {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f83a8489-cac2-c445-8008-b8bdc9fbf92d-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_62e988bb-e2e1-739f-0992-748cd377d365-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9c4-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9d7-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9e9-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4a120b44-a597-ab3a-8792-fefbeb26d9d2-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c5b29070-bb85-0593-0462-7bdb85104048-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2ed638fe-e365-110a-96ec-db31c35edcd4-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b6e31bf8-dee5-52a1-839a-453d75f0e727-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-a7e0266c-b0bf-b61a-c33a-be862e5253a6-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_26db900e-4c0e-aa21-d9c0-56214b83f5b8-7be80a4c {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c959bd32-c7fd-3a5b-cfc5-c1a430af7e03-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4d90e65e-f806-bcca-4734-8498042f9baa-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_42287c33-fa92-b5ea-ddf1-5e4c159d4b2e-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_60bb72c6-0278-4171-52cb-db5668b67033-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-e2fd29aa-fb75-6911-54d7-3a5271293f95-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f3ebb4e4-b805-c56b-bc66-34cc273326e1-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f3ebb4e4-b805-c56b-bc66-34cc27332715-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f3ebb4e4-b805-c56b-bc66-34cc27332728-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-af9fc50f-52a6-55b5-4b55-cab01fc7f08b-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a570c5b4-7952-526b-ce1f-a9f6f3181753-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_17b3f633-a8d8-80fb-7dc0-64b953d18920-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0b7b9a83-10ab-7295-142b-a0828165a30a-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1b62311c-ab97-4336-db5e-0319d7910d70-7be80a4c {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8b379125-90a6-7d04-edd2-bcdf9dde0fcf-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce103c-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce104e-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce1060-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce1095-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce10a8-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_19ce3b49-21b8-4735-a0ff-a830d4a47a5a-7be80a4c {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce624-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce638-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce64b-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce65e-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce671-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce680-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce693-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_053ead28-a5cf-ed41-e683-ee987435afc1-7be80a4c {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_00e9807a-0460-a2c4-bc42-90793162c716-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1fd0f7f7-c3e9-c5ed-ee4e-ad9a54b0cfbb-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_57a6f914-91af-3cf9-32fd-e96a03a2ca3f-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-e6a33486-2795-8eef-1afb-9b91b5756637-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d92e5993-b8ab-037c-86aa-a1ec3065fbe2-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f95db77-8f13-6910-458a-2681060f957e-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_3bab8644-c6dd-c3d8-e3d3-c81843fbfff8-7be80a4c {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_79897ac1-1d3e-128c-1fa7-d5c7764520c4-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-eaf3fe18-d18d-b6ac-8d17-cfe23919bc4d-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdd4-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefde6-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdf8-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a16-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a28-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a3a-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c1c7bde4-8fac-4ab8-4dc4-37f5d4782818-7be80a4c {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-7be80a4c {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636315-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636319-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63631f-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636323-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636329-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63632d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636331-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636335-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636339-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63633d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636341-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636345-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636349-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63634d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636351-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636355-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636359-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63635d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636361-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636365-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636369-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63636d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636371-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636375-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636379-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63637d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636381-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636385-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636389-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec63638d-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636391-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3d0abda9-fe51-f0de-79b2-f1fdec636395-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d950a422-2aa7-0312-ed0a-5562cf071495-7be80a4c {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946854-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946858-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94685c-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946861-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946868-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94686c-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946870-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946874-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946878-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94687c-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946880-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946884-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946888-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94688c-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946890-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946894-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946898-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94689c-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a0-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a4-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a8-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468ac-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b0-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b4-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b8-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468bc-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c0-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c4-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c8-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468cc-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468d0-7be80a4c {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468d4-7be80a4c {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-dfe80a4d {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3f65e1c0-1d27-a12c-7163-306528279207-80e80a52 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_266adb6c-012a-0af0-e9f2-608c53dcb7d1-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_266adb6c-012a-0af0-e9f2-608c53dcb7e0-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_83a8a9d7-764d-a757-9321-a96ba32c3ac9-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f45764df-f887-a87d-6483-5aabf989154f-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-f45764df-f887-a87d-6483-5aabf989155e-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_4e25e9d2-a930-bab8-236c-b9fd44ffcb45-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_4e25e9d2-a930-bab8-236c-b9fd44ffcb57-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_22c1976e-78a7-2d1b-8a12-41db4b10a94a-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#data-jscolor.w-node-_80ce2b53-38cf-7fb5-7af3-2d9aebebda24-80e80a52 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_38c5b4ef-b6b0-9d63-4a55-1fc4bcfb10da-80e80a52 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_0a96a71b-ab9d-d8dd-f08d-1d36e64263d5-80e80a52 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-fa3d1331-eb8c-e82f-30d9-10af54e3dcdf-69e80a54 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa3d1331-eb8c-e82f-30d9-10af54e3dce4-69e80a54 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa3d1331-eb8c-e82f-30d9-10af54e3dce9-69e80a54 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa3d1331-eb8c-e82f-30d9-10af54e3dcee-69e80a54 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fa3d1331-eb8c-e82f-30d9-10af54e3dcf7-69e80a54 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_0ce5f8fc-849e-0435-1e68-45eae83274fd-69e80a54 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0ce5f8fc-849e-0435-1e68-45eae8327500-69e80a54 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_82494e88-7b88-efca-8738-63e9c4f49626-82e80a56 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_82494e88-7b88-efca-8738-63e9c4f49629-82e80a56 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-ec0eb271-b76a-df11-185a-ba1c5d627d51-82e80a56 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb5d5-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb5e4-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_06e8e546-cc2d-83fb-d8fe-77dec9128bb6-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-e8f880f6-f1e1-86ab-d6e2-f3c5797c77fa-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb639-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb65f-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb674-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb688-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb69a-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb6ac-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb6c0-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb6d2-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb6e4-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb6f6-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb71e-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_7d388398-2299-1e4a-1147-3c63cf429a15-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_8723e314-4c71-1232-7076-6fa3474b866f-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_559a202a-c1df-dc62-ddc1-2a7828077770-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb776-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb79c-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb7b1-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb7c5-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb7d7-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb7e9-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb7fd-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb80f-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb821-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_84c88b9e-77b1-1714-6342-35d7ef6fb833-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a0d-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a33-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a48-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a5c-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a6e-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a80-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88a94-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88aa6-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88ab8-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a25644f2-abfa-4572-6931-b7d0faa88aca-2be80a69 {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_510d7e36-35b4-b710-7bf4-12954113d762-39e80a8b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a2441165c-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-cb859e8a-d0d5-3cd8-8d53-8d5d48f8fd12-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_9ba91436-3fcf-2a37-38e3-957c4957edb1-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_19f79e18-2aca-575c-ccae-61d436ec736b-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_2980a710-7174-cb05-5d31-964142ebb732-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_6ac2cf6a-7ca7-de35-e233-edd6a3250f24-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3871876f-6f02-45cd-7946-d8f8909d6ad2-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_9b92be9f-a6c0-58e8-c33c-6a8e7986bbe8-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3e3cb261-ec4e-2eb0-a06d-c7a164e4c74e-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_92673a71-1ed6-09b0-c9e7-d89bf1e20b33-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_5a9db7c4-0fc7-383b-d911-b1457006d7ad-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_52a63724-ea4b-167e-eb11-737d1fb21c39-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_40a8c441-8faa-d5c1-6d27-73085d7beeda-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-dc746769-3643-8f24-ff7f-b62fdb71616a-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-a4d9e2c9-5c33-38a9-d386-6b5a4fd55876-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_52747d80-36b2-7770-59e9-20055429b5ee-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-f83a8489-cac2-c445-8008-b8bdc9fbf92d-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_62e988bb-e2e1-739f-0992-748cd377d365-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9c4-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9d7-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-c6c2207a-d352-622d-1d16-1f430770a9e9-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4a120b44-a597-ab3a-8792-fefbeb26d9d2-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c5b29070-bb85-0593-0462-7bdb85104048-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2ed638fe-e365-110a-96ec-db31c35edcd4-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-b6e31bf8-dee5-52a1-839a-453d75f0e727-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-a7e0266c-b0bf-b61a-c33a-be862e5253a6-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_26db900e-4c0e-aa21-d9c0-56214b83f5b8-e99303ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-c959bd32-c7fd-3a5b-cfc5-c1a430af7e03-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_4d90e65e-f806-bcca-4734-8498042f9baa-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_42287c33-fa92-b5ea-ddf1-5e4c159d4b2e-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_60bb72c6-0278-4171-52cb-db5668b67033-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-e2fd29aa-fb75-6911-54d7-3a5271293f95-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f3ebb4e4-b805-c56b-bc66-34cc273326e1-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-f3ebb4e4-b805-c56b-bc66-34cc27332715-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-f3ebb4e4-b805-c56b-bc66-34cc27332728-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-af9fc50f-52a6-55b5-4b55-cab01fc7f08b-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-a570c5b4-7952-526b-ce1f-a9f6f3181753-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_17b3f633-a8d8-80fb-7dc0-64b953d18920-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_0b7b9a83-10ab-7295-142b-a0828165a30a-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_1b62311c-ab97-4336-db5e-0319d7910d70-e99303ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_8b379125-90a6-7d04-edd2-bcdf9dde0fcf-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce103c-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce104e-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce1060-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce1095-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_839ebcce-a18b-b33e-96e8-bd6ea3ce10a8-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_19ce3b49-21b8-4735-a0ff-a830d4a47a5a-e99303ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce624-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce638-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce64b-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce65e-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce671-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce680-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_2358bf69-568a-a491-9b2b-73afd61ce693-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_053ead28-a5cf-ed41-e683-ee987435afc1-e99303ca {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_00e9807a-0460-a2c4-bc42-90793162c716-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_1fd0f7f7-c3e9-c5ed-ee4e-ad9a54b0cfbb-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_57a6f914-91af-3cf9-32fd-e96a03a2ca3f-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-e6a33486-2795-8eef-1afb-9b91b5756637-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-d92e5993-b8ab-037c-86aa-a1ec3065fbe2-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f95db77-8f13-6910-458a-2681060f957e-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_3bab8644-c6dd-c3d8-e3d3-c81843fbfff8-e99303ca {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_79897ac1-1d3e-128c-1fa7-d5c7764520c4-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-eaf3fe18-d18d-b6ac-8d17-cfe23919bc4d-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdd4-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefde6-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_0f289548-9dd2-1946-e3e5-62552baefdf8-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a16-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a28-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_912a507f-5e0c-80e6-e86c-639726716a3a-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-c1c7bde4-8fac-4ab8-4dc4-37f5d4782818-e99303ca {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-ef53a1b5-32fb-b097-4006-9c6a244116ab-e99303ca {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f37514d-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375151-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375157-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f37515b-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375161-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375165-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f37516b-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f37516f-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375175-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375179-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f37517f-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f375183-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f3751d1-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-c82de2b5-2a1f-6668-772a-5d534f3751d5-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_78ebe911-9cad-f776-73bf-1570c0bc4a49-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_78ebe911-9cad-f776-73bf-1570c0bc4a4d-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_38731e7b-19b0-6e84-7ad9-1f75dddae12f-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_38731e7b-19b0-6e84-7ad9-1f75dddae133-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_78f99f55-6403-ef56-0e9f-c0c38a055465-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_78f99f55-6403-ef56-0e9f-c0c38a055469-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_15892d09-5bf7-4a43-3e66-ef10e4c7cbdc-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_15892d09-5bf7-4a43-3e66-ef10e4c7cbe0-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_8b0e473f-3e0b-79d6-4ce3-12c2ce9b00d1-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_8b0e473f-3e0b-79d6-4ce3-12c2ce9b00d5-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d950a422-2aa7-0312-ed0a-5562cf071495-e99303ca {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946854-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946858-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94685c-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946861-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946868-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94686c-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946870-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946874-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946878-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94687c-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946880-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946884-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946888-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94688c-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946890-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946894-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be946898-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94689c-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a0-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a4-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468a8-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468ac-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b0-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b4-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468b8-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468bc-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c0-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c4-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468c8-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468cc-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468d0-e99303ca {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d1ff4b6c-7e75-0652-d44a-3cf9be9468d4-e99303ca {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3373c-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33752-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33776-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33789-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3379b-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa337bf-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa337e4-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa337f9-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3380b-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33820-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33832-a480b94b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33833-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33845-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33854-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33867-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_3380b0e3-683e-864d-37e7-548450c35c1f-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33875-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3388d-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa338d7-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3389f-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa338ea-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa338fd-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_85a63cac-e2c7-080c-e07e-357b3d741095-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3390d-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33920-a480b94b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33924-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33939-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3394b-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3395d-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa3396f-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33981-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-cf803ae4-b21a-7592-4d66-d4fc5822967c-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33995-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339a7-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339b9-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339cb-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339dd-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa339ee-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a01-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7982e0e8-4a86-3a85-8509-7ef9940ae7f2-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a15-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a29-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a3c-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a4f-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a62-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a71-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33a84-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_7f47e100-59bc-275d-8f0a-09197042a363-a480b94b {
  -ms-grid-column-align: stretch;
  justify-self: stretch;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33aa8-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ace-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ae2-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33af4-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b06-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b1a-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b2c-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b3e-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b50-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33b85-a480b94b {
  -webkit-align-self: end;
  -ms-flex-item-align: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-abb48915-8921-1799-14b7-2817332fdec4-a480b94b {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca1-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca5-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cae-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cb5-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cb9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cbd-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc1-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc5-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cc9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ccd-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd1-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd5-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cd9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cdd-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce1-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce5-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ce9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33ced-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf1-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf5-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cf9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33cfd-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d01-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d05-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d09-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d0d-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d11-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d15-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d19-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d1d-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_95f9ce64-2aff-654a-b841-4969aaa33d21-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6a1f80a1-2a98-e912-ef0a-10398c2bf8d5-a480b94b {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-cce36bd5-f70d-4d7e-d718-3b8bbbacd453-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d5550fcb-e1e3-3b3a-4c71-8673257e3c87-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_28e36939-17af-ed7e-a3a4-5ee8dada5876-a480b94b {
  -ms-grid-row-align: flex-start;
  align-self: flex-start;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bc3-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bc7-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bcd-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bd1-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bd7-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bdb-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4be1-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4be5-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4beb-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bef-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bf5-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bf9-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4bff-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c03-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c07-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c0b-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c0f-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c13-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c17-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c1b-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c1f-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c23-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c27-a480b94b {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_01f939b8-e576-c51b-18ca-532e814c4c2b-a480b94b {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde536-b74eabf4 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde53b-b74eabf4 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde540-b74eabf4 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde545-b74eabf4 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_982d033d-d3a0-8de1-c41a-df2d14cde566-b74eabf4 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-eb08ab27-3ecd-1d2b-4d94-73f415ee8924-b74eabf4 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-eb08ab27-3ecd-1d2b-4d94-73f415ee89b3-b74eabf4 {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_9159f475-e091-8ff7-5b5e-8b9da4b6c340-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-f438072b-5987-7aab-1614-aedccca8dc6e-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_9d421a0a-bed8-5014-abd0-b10e1e80fae3-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_613a7126-eb2e-a6d5-cb97-a0b4b019691b-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641022-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641080-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641094-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f66-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fc4-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fd8-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a19-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a77-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a8b-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3f065201-2cac-761c-3070-1934613cbebd-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3f065201-2cac-761c-3070-1934613cbf1b-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3f065201-2cac-761c-3070-1934613cbf2f-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_073e8027-4c92-1381-c06b-31f08469eea1-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_073e8027-4c92-1381-c06b-31f08469eeff-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_073e8027-4c92-1381-c06b-31f08469ef13-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d84cddff-ee81-b242-c70f-81ca6af41b11-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-d84cddff-ee81-b242-c70f-81ca6af41b6f-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-d84cddff-ee81-b242-c70f-81ca6af41b83-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3227de94-9f9d-7523-c28b-03bb1e5559c5-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3227de94-9f9d-7523-c28b-03bb1e555a23-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_3227de94-9f9d-7523-c28b-03bb1e555a37-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1b414107-556a-2e31-911b-d880ac598561-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_1b414107-556a-2e31-911b-d880ac5985bf-b74eabf4 {
  -ms-grid-column-align: flex-start;
  justify-self: flex-start;
}

#w-node-_1b414107-556a-2e31-911b-d880ac5985d3-b74eabf4 {
  -ms-grid-column-align: center;
  justify-self: center;
}

@media screen and (min-width: 1920px) {
  #w-node-ae7e6802-0fd5-e5d0-26e2-82cedf2fd01a-a9e809c7 {
    -ms-grid-row: span 11;
    grid-row-start: span 11;
    -ms-grid-row-span: 11;
    grid-row-end: span 11;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c44-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-aeb2fb91-2b12-b2d2-b3f8-4129190addde-6be80a0f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-fde80a15 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a45-d7e80a1a {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c806-d7e80a1a {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f4a-d7e80a1a {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a45-9ae80a1b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c806-9ae80a1b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f4a-9ae80a1b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-c769d59e-763a-a11d-1b07-716415342c00-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-c769d59e-763a-a11d-1b07-716415342c48-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_864c7c55-11ed-189b-2939-00640e89a753-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_733a02c5-fa2c-6a90-2b22-c602c0736e79-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7b7a4321-a384-117a-8d97-f90256452654-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_94597a17-546a-046c-1971-dfa5479fbc7f-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_7cf593b6-3e04-36f9-b1c1-a1f2f26c3bd8-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_73bae3e1-5b35-e01a-e6e7-2afa2e34b569-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_91c488c4-2d50-8ff0-8508-5877ebb4eb2b-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f67f03fe-669d-3740-e7a0-2b19b8464fea-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e74b8f70-bbe9-1858-7027-ebbf9803edd7-69e80a54 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media screen and (min-width: 1440px) {
  #w-node-_2563b34c-ae53-7719-a7c5-13ea3ede76a8-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c35-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c42-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c44-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c50-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f024-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f031-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f039-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f092-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f0a3-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d8258-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d8265-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d826d-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82c6-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82d7-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f662-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f66f-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f677-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f6c9-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f6da-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db84d2-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db84df-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db84e7-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db8539-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db854a-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd27c-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd289-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd291-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd2e3-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd2f4-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_0bddf85c-9418-5271-b783-6289be9e436b-0ce80a13 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_01b56de3-15e9-bb3c-3794-0cfe1bc04585-41e80a1e {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-f6db6d51-cc6f-2564-c876-ff83d387b34c-41e80a1e {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_917112c9-ea04-d2b9-17a9-ea814d498553-41e80a1e {
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
  }

  #w-node-b92431e6-1250-9465-1635-0b8636fbfd6d-41e80a1e {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa337f9-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa3380b-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_2ed638fe-e365-110a-96ec-db31c35edcd4-7be80a4c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b6e31bf8-dee5-52a1-839a-453d75f0e727-7be80a4c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_2ed638fe-e365-110a-96ec-db31c35edcd4-e99303ca {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-b6e31bf8-dee5-52a1-839a-453d75f0e727-e99303ca {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa337f9-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa3380b-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_6a1f80a1-2a98-e912-ef0a-10398c2bf8d5-a480b94b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d5550fcb-e1e3-3b3a-4c71-8673257e3c87-a480b94b {
    -ms-grid-row-align: flex-start;
    align-self: flex-start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_28e36939-17af-ed7e-a3a4-5ee8dada5876-a480b94b {
    -ms-grid-row-align: flex-start;
    align-self: flex-start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_35b7b17b-b258-c301-288a-2dcfad452204-b74eabf4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_21a51873-c723-c389-9054-28e2e870526d-b74eabf4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_21a51873-c723-c389-9054-28e2e870526f-b74eabf4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b85fc84a-7639-a916-6709-18ec67fd9a2a-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_9159f475-e091-8ff7-5b5e-8b9da4b6c340-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_4ff616b2-037f-87d4-e48c-76a9c4e5c1fc-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641015-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641022-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f64102a-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641083-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641094-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f59-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f66-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f6e-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fc7-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fd8-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a0c-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a19-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a21-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a7a-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a8b-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbeb0-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbebd-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbec5-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbf1e-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbf2f-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ee94-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469eea1-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469eea9-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ef02-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ef13-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b04-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b11-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b19-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b72-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b83-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e5559b8-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e5559c5-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e5559cd-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e555a26-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e555a37-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac598554-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac598561-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac598569-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac5985c2-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac5985d3-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media screen and (min-width: 1280px) {
  #w-node-_1bdf2da0-0a71-dc0a-f808-6294b35befdc-a9e809c7 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-ae7e6802-0fd5-e5d0-26e2-82cedf2fd01a-a9e809c7 {
    -ms-grid-row: span 11;
    grid-row-start: span 11;
    -ms-grid-row-span: 11;
    grid-row-end: span 11;
  }

  #w-node-_8afe4b18-289a-31ff-3e3f-c4f5718c11c8-a9e809c7 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_4ed3bba0-20f4-ecdd-52a3-fa7ad124ba1a-d124b9fc {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c44-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f08f-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f092-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f095-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f0a3-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82c3-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82c6-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82c9-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d82d7-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f6c6-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f6c9-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f6cc-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f6da-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db8536-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db8539-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db853c-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db854a-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd2e0-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd2e3-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd2e6-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd2f4-6be80a0f {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_5ba76f4f-dd11-8061-cdc8-7b02c1a25001-0ce80a13 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_6aafd1cc-6ae2-1a92-7714-bff1de310b57-0ce80a13 {
    -ms-grid-row-align: flex-start;
    align-self: flex-start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-e07faba9-89b0-1fef-2c4c-2e54de350a45-0ce80a13 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_5afd835a-262f-b38c-f426-50cd9fa2acdc-0ce80a13 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-_186b4661-3441-ebed-799a-637652a70b1f-0ce80a13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_2d27da4f-5783-351f-ea8e-feab8ac7cc4d-0ce80a13 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_0bddf85c-9418-5271-b783-6289be9e436b-0ce80a13 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_8afe4b18-289a-31ff-3e3f-c4f5718c11c8-abe80a19 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-edb36a92-3594-4788-3cce-c3be0c599e61-41e80a1e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_2a8d8542-1e6a-9f18-eb52-0809d78bbee4-41e80a1e {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa3388d-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338d7-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa3389f-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338c4-00e80a4b {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338e9-00e80a4b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338ea-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_85a63cac-e2c7-080c-e07e-357b3d741095-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa33920-00e80a4b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cf803ae4-b21a-7592-4d66-d4fc5822967c-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_7982e0e8-4a86-3a85-8509-7ef9940ae7f2-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa33a71-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_7f47e100-59bc-275d-8f0a-09197042a363-00e80a4b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca9-00e80a4b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f3ebb4e4-b805-c56b-bc66-34cc27332715-7be80a4c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-af9fc50f-52a6-55b5-4b55-cab01fc7f08b-7be80a4c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_51d090be-18a3-4c71-2b9b-9e81fc535741-7be80a4c {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a570c5b4-7952-526b-ce1f-a9f6f3181753-7be80a4c {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_1b62311c-ab97-4336-db5e-0319d7910d70-7be80a4c {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_053ead28-a5cf-ed41-e683-ee987435afc1-7be80a4c {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94685c-7be80a4c {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_0ce5f8fc-849e-0435-1e68-45eae83274ca-69e80a54 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f3ebb4e4-b805-c56b-bc66-34cc27332715-e99303ca {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-af9fc50f-52a6-55b5-4b55-cab01fc7f08b-e99303ca {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_51d090be-18a3-4c71-2b9b-9e81fc535741-e99303ca {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-a570c5b4-7952-526b-ce1f-a9f6f3181753-e99303ca {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_1b62311c-ab97-4336-db5e-0319d7910d70-e99303ca {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_053ead28-a5cf-ed41-e683-ee987435afc1-e99303ca {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-d1ff4b6c-7e75-0652-d44a-3cf9be94685c-e99303ca {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa3388d-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338d7-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa3389f-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338c4-a480b94b {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338e9-a480b94b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa338ea-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_85a63cac-e2c7-080c-e07e-357b3d741095-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa33920-a480b94b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-cf803ae4-b21a-7592-4d66-d4fc5822967c-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_7982e0e8-4a86-3a85-8509-7ef9940ae7f2-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa33a71-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_7f47e100-59bc-275d-8f0a-09197042a363-a480b94b {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
  }

  #w-node-_95f9ce64-2aff-654a-b841-4969aaa33ca9-a480b94b {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-eb08ab27-3ecd-1d2b-4d94-73f415ee89b3-b74eabf4 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_21a51873-c723-c389-9054-28e2e870526f-b74eabf4 {
    -ms-grid-column-align: end;
    justify-self: end;
  }

  #w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641080-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641083-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641086-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641094-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fc4-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fc7-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fca-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674fd8-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a77-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a7a-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a7d-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a8b-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbf1b-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbf1e-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbf21-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbf2f-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469eeff-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ef02-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ef05-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ef13-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b6f-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b72-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b75-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b83-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e555a23-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e555a26-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e555a29-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e555a37-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac5985bf-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac5985c2-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac5985c5-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac5985d3-b74eabf4 {
    -ms-grid-column-align: center;
    justify-self: center;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_97a79c67-2984-f351-bc14-d6d5ebc090a6-a9e809c7 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-ff333d0c-e8bf-7c67-544f-ead3be75efa4-6be80a0f {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-f3b21e65-a50e-06d4-6be3-7f53c5576c44-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_1cb2fc1e-1d0b-0fce-d2f1-3719628fb20c-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_1cb2fc1e-1d0b-0fce-d2f1-3719628fb20d-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_1cb2fc1e-1d0b-0fce-d2f1-3719628fb210-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f023-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f024-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_53dbd4f7-0b25-2698-a0dd-5e49a732f039-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d8257-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d8258-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_5bd3ad64-6310-33aa-344e-10bfa38d826d-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f661-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f662-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_567bc931-e071-9e48-babc-7515dde8f677-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db84d1-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db84d2-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_920fd572-641e-ffa9-5aa0-7d7654db84e7-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd27b-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd27c-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-d9289f50-4ff7-3f06-0560-ece5833bd291-6be80a0f {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6aafd1cc-6ae2-1a92-7714-bff1de310c81-0ce80a13 {
    -ms-grid-row-align: flex-start;
    align-self: flex-start;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-dd0bac13-a82f-af8e-3198-9716954bedea-0ce80a13 {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-ff43ca08-2906-f71c-d8e7-5ad72bc9fdd4-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-ff43ca08-2906-f71c-d8e7-5ad72bc9fdd5-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_7d3ac1dd-3d07-762b-9d90-02a38c7a2fab-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_7d3ac1dd-3d07-762b-9d90-02a38c7a2fac-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_32a48fbf-c764-bc84-5c0d-03a231ccaa96-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_32a48fbf-c764-bc84-5c0d-03a231ccaa97-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_5cc3a6bb-bec2-04c4-323a-08b79b9bd871-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_5cc3a6bb-bec2-04c4-323a-08b79b9bd872-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-cc216a9f-0516-f316-d257-e781bc306557-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-cc216a9f-0516-f316-d257-e781bc306558-fde80a15 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_97a79c67-2984-f351-bc14-d6d5ebc090a6-abe80a19 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a1e-d7e80a1a {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-eaa90489-9160-790e-2558-aba5bc678f86-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a1f-d7e80a1a {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a23-d7e80a1a {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a2f-d7e80a1a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a3b-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a45-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7ee-d7e80a1a {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7ef-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7f3-d7e80a1a {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7f7-d7e80a1a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7fb-d7e80a1a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c803-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c806-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f32-d7e80a1a {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f33-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f37-d7e80a1a {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f3b-d7e80a1a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f3f-d7e80a1a {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f47-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f4a-d7e80a1a {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a1e-9ae80a1b {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-eaa90489-9160-790e-2558-aba5bc678f86-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a1f-9ae80a1b {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a23-9ae80a1b {
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a2f-9ae80a1b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a3b-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-efa90d6d-3495-15a5-f6a2-f4da5f547a45-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7ee-9ae80a1b {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7ef-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7f3-9ae80a1b {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7f7-9ae80a1b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c7fb-9ae80a1b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c803-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-e1a577fd-657a-44d8-a46a-8897f867c806-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f32-9ae80a1b {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f33-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f37-9ae80a1b {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f3b-9ae80a1b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f3f-9ae80a1b {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f47-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2;
  }

  #w-node-_6d0e7ef5-ab4d-c056-535c-fa1f8e9f2f4a-9ae80a1b {
    -ms-grid-column-span: 1;
    grid-column-end: 5;
    -ms-grid-column: 4;
    grid-column-start: 4;
    -ms-grid-row-span: 1;
    grid-row-end: 4;
    -ms-grid-row: 3;
    grid-row-start: 3;
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-c769d59e-763a-a11d-1b07-716415342c00-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-c769d59e-763a-a11d-1b07-716415342c16-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-c769d59e-763a-a11d-1b07-716415342c17-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_864c7c55-11ed-189b-2939-00640e89a728-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_864c7c55-11ed-189b-2939-00640e89a72d-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_733a02c5-fa2c-6a90-2b22-c602c0736e4e-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_733a02c5-fa2c-6a90-2b22-c602c0736e53-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_7b7a4321-a384-117a-8d97-f90256452629-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_7b7a4321-a384-117a-8d97-f9025645262e-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_94597a17-546a-046c-1971-dfa5479fbc54-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_94597a17-546a-046c-1971-dfa5479fbc59-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_7cf593b6-3e04-36f9-b1c1-a1f2f26c3bad-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_7cf593b6-3e04-36f9-b1c1-a1f2f26c3bb2-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_73bae3e1-5b35-e01a-e6e7-2afa2e34b53e-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_73bae3e1-5b35-e01a-e6e7-2afa2e34b543-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_91c488c4-2d50-8ff0-8508-5877ebb4eb00-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_91c488c4-2d50-8ff0-8508-5877ebb4eb05-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-f67f03fe-669d-3740-e7a0-2b19b8464fbf-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-f67f03fe-669d-3740-e7a0-2b19b8464fc4-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-e74b8f70-bbe9-1858-7027-ebbf9803edac-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-e74b8f70-bbe9-1858-7027-ebbf9803edb1-69e80a54 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_6668db51-b4f0-f223-765b-6b7c5d315271-82e80a56 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-b5d27e99-ef88-6e89-7677-2535cf1f25a5-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641014-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f641015-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_3b5dcc35-7a3b-ad11-c48a-5bdf4f64102a-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f58-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f59-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_50d52765-6c57-ce26-b7f8-0fbbf8674f6e-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a0b-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a0c-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-abf4b1f5-4eee-8a36-53fc-f113cb821a21-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbeaf-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbeb0-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_3f065201-2cac-761c-3070-1934613cbec5-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ee93-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469ee94-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_073e8027-4c92-1381-c06b-31f08469eea9-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b03-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b04-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-d84cddff-ee81-b242-c70f-81ca6af41b19-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e5559b7-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e5559b8-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_3227de94-9f9d-7523-c28b-03bb1e5559cd-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac598553-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac598554-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  #w-node-_1b414107-556a-2e31-911b-d880ac598569-b74eabf4 {
    -ms-grid-column-align: flex-start;
    justify-self: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}

@font-face {
  font-family: 'Plusjakartatext';
  src: url('../fonts/PlusJakartaText-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartatext';
  src: url('../fonts/PlusJakartaText-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartatext';
  src: url('../fonts/PlusJakartaText-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartatext';
  src: url('../fonts/PlusJakartaText-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartatext';
  src: url('../fonts/PlusJakartaText-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartatext';
  src: url('../fonts/PlusJakartaText-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Plusjakartadisplay';
  src: url('../fonts/PlusJakartaDisplay-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Black-Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Bold-Italic.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extrabold-Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Black-Italic.otf')
    format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Bold-Italic.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Extrabold-Italic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Light-Italic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Regular-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Semibold-Italic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Condensed-Thin-Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Black-Italic.otf')
    format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Bold-Italic.otf')
      format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Extrabold-Italic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Extrabold.otf')
      format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Light-Italic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Regular-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Semibold-Italic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Semibold.otf')
    format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Thin-Italic.otf')
    format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Extra-Condensed-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Semibold-Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova alt';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Alt-Thin-Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Black-Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Bold-Italic.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Extrabold-Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Black-Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Bold-Italic.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Extrabold-Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Semibold-Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Thin-Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Condensed-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Bold-Italic.otf')
      format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Extrabold-Italic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Black-Italic.otf')
    format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Bold-Italic.otf')
      format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Extrabold-Italic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Regular-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Semibold-Italic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Light-Italic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Thin-Italic.otf')
    format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Extra-Condensed-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Black-Italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Bold-Italic.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extrabold-Italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Bold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extrabold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Black-Italic.otf')
    format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Light-Italic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Extrabold.otf') format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Regular-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Semibold-Italic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Thin-Italic.otf')
    format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Black-Italic.otf')
    format('opentype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Bold-Italic.otf')
      format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Extrabold-Italic.otf')
      format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Extrabold.otf')
      format('opentype'),
    url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Light-Italic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Regular-Italic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Semibold-Italic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Semibold.otf')
    format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Thin-Italic.otf')
    format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Thin.otf')
    format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Semibold-Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Thin-Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Semibold-Italic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Thin-Italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Condensed-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Mark simonson proxima nova scosf extra condensed';
  src: url('../fonts/Mark-Simonson---Proxima-Nova-ScOsf-Extra-Condensed-Black.otf')
    format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
