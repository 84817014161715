.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

.heading-3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
  display: inline-block;
}

.container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  display: flex;
}

.wrapper {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.card-frame {
  width: 100%;
  height: 10vw;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 15px;
  flex: 100 auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-columns: 1fr;
  padding: 0;
  display: grid;
  position: relative;
  overflow: scroll;
}

.overflow-menu__link {
  height: 2.5rem;
  color: #242424;
  align-items: center;
  padding: 0 1rem;
  font-size: 12px;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.overflow-menu__link:hover {
  color: #171717;
  background-color: rgba(255, 255, 255, .27);
}

.overflow-menu__link._3 {
  justify-content: space-between;
}

.overflow-menu__link._3:hover {
  background-color: #dddbdb;
}

.input {
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 30px;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 12px;
  transition: all .2s;
}

.input:hover {
  padding-left: 0;
}

.dropdown-toggle-2 {
  width: 120px;
  height: 45px;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  align-items: center;
  padding: 0 15px;
  font-size: 12px;
  transition: all .35s;
  display: flex;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0);
}

.dropdown-toggle-2:hover {
  background-color: #f0f0f0;
  border-radius: 10px 10px 0 0;
}

.dropdown-toggle-2.w--open {
  background-color: #f0f0f0;
  border-bottom: 1px solid #dfdfdf;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
}

.dropdown-toggle-2.w--open:hover {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-2.small {
  width: 65px;
  height: 40px;
}

.dropdown-toggle-2.low {
  height: 40px;
}

.dropdown-list-2 {
  background-color: #f3f3f3;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

.dropdown-list-2.w--open {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
}

.dropdown-list-2.frost-blur {
  background-color: rgba(243, 243, 243, .3);
}

.dropdown-item {
  height: auto;
  color: #565656;
  background-color: #fff;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 70ms;
  display: block;
}

.dropdown-item:hover {
  color: #171717;
  background-color: #fff;
}

.dropdown-item:active {
  background-color: rgba(255, 255, 255, .3);
}

.dropdown {
  width: 100%;
  height: auto;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: all 70ms;
  display: block;
}

.dropdown-toggle__icon {
  width: 1rem;
  height: 1rem;
  background-image: url('../../images/arrow__down.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 10px;
  position: absolute;
  right: 1rem;
  transform: rotate(0);
}

.dropdown-toggle__icon.dropdown-toggle__icon-inline {
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  background-size: 8px;
  margin-right: 0;
  font-size: 18px;
  line-height: 100%;
  position: static;
  top: .5rem;
  right: .5rem;
}

.main-wrapper {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: static;
}

.main-wrapper.scroll {
  padding-top: 0;
  overflow: scroll;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 0;
  transition: all .2s;
  display: flex;
  position: relative;
  box-shadow: 0 2px 3px rgba(29, 29, 29, .15);
}

.card:hover {
  box-shadow: 0 6px 12px rgba(29, 29, 29, .15);
}

.image-card {
  width: 100%;
  min-height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.product-content-div {
  padding: 15px;
}

.rating-div {
  grid-column-gap: 3px;
  grid-row-gap: 0px;
  grid-template-rows: 18px;
  grid-template-columns: 18px 18px 18px 18px 18px;
  grid-auto-rows: 18px;
  grid-auto-columns: 18px;
  display: grid;
}

.rating-image {
  width: 16px;
}

.dropdown-item-2 {
  color: #565656;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 70ms;
  display: block;
}

.dropdown-item-2:hover {
  color: #171717;
  background-color: #e5e5e5;
}

.dropdown-item-2:active {
  background-color: #dcdcdc;
}

.dropdown-wrap {
  width: 100%;
  max-width: 14rem;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.dropdown-wrap.dropdown-wrap__inline {
  max-width: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.dropdown-toggle-3 {
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #8c8c8c;
  align-items: center;
  padding: 0 0 0 1rem;
  transition: all 70ms;
  display: flex;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0);
}

.dropdown-toggle-3:hover {
  background-color: #e5e5e5;
}

.dropdown-toggle-3.w--open {
  border-bottom: 1px solid #dcdcdc;
}

.dropdown-toggle-3.dropdown-toggle__inline {
  height: auto;
  min-width: 100%;
  border-bottom-color: rgba(0, 0, 0, 0);
  justify-content: flex-start;
  align-self: stretch;
  padding: 4px 0 0;
  line-height: 1.345;
  display: flex;
}

.dropdown-toggle-3.dropdown-toggle__inline:hover {
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-toggle-3.dropdown-toggle__inline.align-left {
  background-color: rgba(0, 0, 0, 0);
  justify-content: flex-start;
}

.dropdown-toggle-3.dropdown-toggle__inline {
  height: 100%;
  border-bottom-color: rgba(0, 0, 0, 0);
  justify-content: center;
  padding: 0;
  line-height: 1.345;
  display: flex;
}

.dropdown-toggle-3.dropdown-toggle__inline:hover {
  background-color: #f7f7f7;
}

.dropdown-2 {
  width: 100%;
  height: auto;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  transition: all 70ms;
  display: block;
}

.dropdown-2.dropdown-inline {
  width: auto;
  height: auto;
  width: 45px;
  height: 45px;
  background-color: #fff;
  border-radius: 15px;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: space-around;
  align-self: stretch;
  margin-left: 0;
  margin-right: 0;
  display: inline-block;
}

.dropdown-list-3 {
  background-color: #f3f3f3;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
}

.dropdown-list-3.w--open {
  width: 200px;
  max-width: 200px;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px;
  top: 30px;
  bottom: auto;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.div-block-37 {
  margin-top: 15px;
}

.text {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 100%;
}

.text-block-9 {
  margin-top: 5px;
  margin-right: 5px;
  font-weight: 600;
}

.text-block-9-copy {
  color: #8b8b8b;
  margin-right: 0;
  font-weight: 600;
  display: inline-block;
}

.text-block-9-copy.line {
  margin-left: 5px;
  margin-right: 5px;
}

.product-details-div {
  max-width: none;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: 1fr;
  margin-top: 15px;
  display: flex;
}

.delivery-details-div {
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-top: 15px;
  display: grid;
}

.image-77 {
  width: 20px;
}

.product-name {
  font-weight: 600;
}

.product-name.designer {
  text-align: left;
  font-size: 20px;
  line-height: 165%;
}

.div-block-39 {
  display: flex;
}

.div-block-42 {
  margin-top: 0;
  margin-bottom: 15px;
}

.grid-6 {
  grid-column-gap: 7.5px;
  grid-template-rows: auto;
  grid-template-columns: 45px 1fr 30px;
  margin-bottom: 10px;
}

.div-block-43 {
  width: 100%;
  height: 10px;
  min-width: 75px;
  background-color: #e9e9e9;
  border-radius: 10px;
  overflow: hidden;
}

.div-block-44 {
  width: 100%;
  height: 100%;
  background-color: #f2c98f;
}

.div-block-44._83 {
  width: 83%;
}

.div-block-44._11 {
  width: 11%;
}

.div-block-44._4 {
  width: 4%;
}

.div-block-44._2 {
  width: 2%;
}

.div-block-44._1 {
  width: 1%;
}

.third-button {
  height: 52px;
  opacity: 1;
  color: #181818;
  letter-spacing: .5px;
  text-transform: none;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 300px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 18px 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  transition: all .2s cubic-bezier(.6, .04, .98, .335);
  display: flex;
}

.third-button:hover {
  opacity: 1;
  filter: none;
  color: #000;
  background-color: #eee;
  border-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

.third-button.small {
  height: auto;
  letter-spacing: 0;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  padding: 16px 20px;
  font-size: 13px;
}

.third-button.small:hover {
  background-color: rgba(209, 209, 209, .15);
}

.third-button.small.fixed {
  opacity: 0;
}

.third-button.small.line {
  border-color: #d1d1d1;
}

.third-button.small.line.delete {
  opacity: .6;
}

.third-button.small.line.delete:hover {
  border-color: #ff0026;
}

.div-block-45 {
  display: flex;
}

.price-details-div {
  margin-top: 5px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 400;
}

.div-block-46 {
  color: #fff;
  letter-spacing: 0;
  text-transform: none;
  background-color: #e57373;
  border-radius: 0 10px 0 5px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.text-block-11 {
  width: auto;
}

.wishlist-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 5px;
  bottom: auto;
  left: 5px;
  right: auto;
  overflow: hidden;
}

.wishlist-button:hover {
  background-color: #fff;
}

.lottie-heart {
  width: 90px;
  height: 90px;
  min-height: 70px;
  min-width: 70px;
}

.accordion-item-trigger-3 {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
}

.accordion-item-trigger {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 0;
  display: flex;
}

.div-block-47 {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
  margin-right: 0;
  padding-right: 0;
}

.div-block-50 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.div-block-50.padding-15 {
  padding: 15px;
}

.div-block-50.padding-15.scroll {
  padding-right: 0;
}

.checkbox-wrap {
  flex: none;
  align-items: center;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  padding-left: 0;
  display: flex;
  position: relative;
}

.checkbox {
  z-index: auto;
  width: 1rem;
  height: 1rem;
  opacity: 1;
  cursor: pointer;
  border-color: #282828;
  border-radius: 100%;
  flex: none;
  margin-top: 0;
  margin-left: 0;
  transition: all .2s;
  position: relative;
  left: 0;
}

.checkbox:hover {
  box-shadow: inset 0 0 0 1px #242424;
}

.checkbox.w--redirected-checked {
  background-color: #282828;
  background-image: url('../../images/checkmark-icon.svg');
  background-size: 10px;
  border-color: #282828;
}

.checkbox.w--redirected-focus {
  box-shadow: none;
}

.checkbox.square {
  background-color: #fff;
  border-color: #cacaca;
  border-radius: 3px;
}

.checkbox.square.w--redirected-checked {
  background-color: #333;
}

.checkbox-label {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: .5rem;
  font-size: 12px;
  display: flex;
}

.form-block {
  margin-top: 10px;
  margin-bottom: 0;
  display: block !important;
}

.breadcrumb {
  width: auto;
  max-width: none;
  color: #0062ff;
  align-items: center;
  font-family: Mark simonson proxima nova alt, sans-serif;
  display: flex;
}

.breadcrumb-link {
  color: #949494;
  cursor: pointer;
  flex: none;
  font-weight: 400;
  line-height: 1.25rem;
  text-decoration: none;
  transition: all .5s;
  display: block;
}

.breadcrumb-link:hover {
  color: #1d1d1d;
  padding-left: 0;
  padding-right: 0;
  font-weight: 400;
  text-decoration: none;
}

.breadcrumb-link.w--current {
  color: #1d1d1d;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 400;
}

.breadcrumb-divider {
  width: 1px;
  height: .875rem;
  background-color: #949494;
  flex: none;
  margin-left: .5rem;
  margin-right: .5rem;
  position: relative;
  transform: skew(-20deg);
}

.modal-closer {
  z-index: 5;
  background-color: rgba(0, 0, 0, .69);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal-closer.musea {
  background-color: rgba(255, 255, 255, 0);
}

.body-full {
  height: 100vh;
  background-color: #f7f7f7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  font-family: Mark simonson proxima nova alt, sans-serif;
  display: flex;
}

.info-div {
  width: 100%;
  max-width: none;
  min-height: 100px;
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  padding: 40px;
  display: flex;
  box-shadow: 0 2px 3px rgba(29, 29, 29, .15);
}

.heading-4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.install-logos-div {
  max-width: 350px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto;
  grid-auto-columns: 1fr;
  margin-top: 40px;
  display: grid;
}

.logo-install {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-80 {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.logo-wrap {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .15);
}

.lottie-wrap {
  width: 80px;
  opacity: .3;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.image-80-copy {
  width: 50px;
  height: 50px;
  object-fit: fill;
}

.lottie-animation {
  width: auto;
  filter: grayscale();
  align-self: flex-start;
  transform: rotate(-90deg);
}

.div-block-57 {
  width: 600px;
}

.div-block-58 {
  margin-top: 15px;
}

.div-block-59 {
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  text-align: center;
  margin-top: 60px;
}

.div-details-install {
  border-bottom: 1px solid #ebebeb;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}

.link-details {
  color: #05b8ff;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
}

.link-details:hover {
  color: #03678f;
}

.divider {
  height: 20px;
}

.divider._60px {
  height: 60px;
}

.divider.horizontal {
  width: 10px;
}

.grid-7 {
  grid-template-rows: auto;
  margin-top: 40px;
}

.grid-design {
  height: 100%;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex: 100 auto;
  grid-template-rows: auto;
  grid-template-columns: 400px 1fr;
  margin-top: 20px;
  overflow: visible;
}

.design-frame {
  width: 100%;
  height: 100% !important;
  min-height: inherit !important;
  background-color: #fff;
  border-radius: 0;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  display: flex;
  position: relative;
  overflow: scroll;
}

.navigator-design {
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 15px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
}

.tabs {
  background-color: rgba(0, 0, 0, 0);
}

.tab {
  width: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  display: flex;
}

.tab.w--current {
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.tabs-menu {
  height: 55px;
  display: flex;
}

.grid-8 {
  grid-column-gap: 7.5px;
  grid-row-gap: 7.5px;
  grid-template-rows: 25px 25px;
  grid-template-columns: 25px 25px;
  grid-auto-rows: 25px;
  grid-auto-columns: 25px;
  grid-auto-flow: column;
  margin-top: 15px;
}

.grid-8.drop {
  grid-template-rows: 25px 25px 25px 25px 25px;
  grid-template-columns: 25px 25px 25px 25px 25px 25px 25px 25px;
  justify-content: space-between;
  margin-top: 0;
  padding: 15px 10px;
}

.checkbox-label-2 {
  display: none;
}

.checkbox-2 {
  width: auto;
  height: auto;
  min-height: 25px;
  min-width: 25px;
  cursor: pointer;
  background-color: #d1d1d1;
  border-style: solid;
  margin-top: 0;
  margin-left: 0;
  transition: all .2s;
}

.checkbox-2.w--redirected-checked {
  background-color: #d1d1d1;
  background-image: url('../../images/checkmark-icon.svg');
  background-size: 12.5px;
  border-style: solid;
  border-color: #333;
}

.checkbox-2.e8e8e8 {
  background-color: #e8e8e8;
}

.checkbox-2.e8e8e8.w--redirected-checked {
  text-align: left;
}

.checkbox-2.bababa {
  background-color: #bababa;
}

.checkbox-2._696969 {
  background-color: #696969;
}

.checkbox-2._7c1223 {
  background-color: #7c1223;
}

.checkbox-2.c70e28 {
  background-color: #c70e28;
}

.checkbox-2.ed1d24 {
  background-color: #ed1d24;
}

.checkbox-2.f37981 {
  background-color: #f37981;
}

.checkbox-2.ffcad0 {
  background-color: #ffcad0;
}

.checkbox-2.e9bdd5 {
  background-color: #e9bdd5;
}

.checkbox-2.d071a6 {
  background-color: #d071a6;
}

.checkbox-2.cd368c {
  background-color: #cd368c;
}

.checkbox-2.c52e89 {
  background-color: #c52e89;
}

.checkbox-2.fff2b3 {
  background-color: #fff2b3;
}

.checkbox-2.ffe94d {
  background-color: #ffe94d;
}

.checkbox-2.ffc929 {
  background-color: #ffc929;
}

.checkbox-2.ffb18a {
  background-color: #ffb18a;
}

.checkbox-2.f5873f {
  background-color: #f5873f;
}

.checkbox-2.f26622 {
  background-color: #f26622;
}

.checkbox-2.bc5500 {
  background-color: #bc5500;
}

.checkbox-2.e5e43b {
  background-color: #e5e43b;
}

.checkbox-2.f8c193 {
  background-color: #f8c193;
}

.checkbox-2.bf854b {
  background-color: #bf854b;
}

.checkbox-2._603913 {
  background-color: #603913;
}

.checkbox-2.e7ffd7 {
  background-color: #e7ffd7;
}

.checkbox-2.a1c75b {
  background-color: #a1c75b;
}

.checkbox-2._74b87e {
  background-color: #74b87e;
}

.checkbox-2._74a952 {
  background-color: #74a952;
}

.checkbox-2._006838 {
  background-color: #006838;
}

.checkbox-2.bfd8c7 {
  background-color: #bfd8c7;
}

.checkbox-2._038b97 {
  background-color: #038b97;
}

.checkbox-2._00505a {
  background-color: #00505a;
}

.checkbox-2.ebffff {
  background-color: #ebffff;
}

.checkbox-2.a2e5f4 {
  background-color: #a2e5f4;
}

.checkbox-2._44b3d4 {
  background-color: #44b3d4;
}

.checkbox-2._3499b5 {
  background-color: #3499b5;
}

.checkbox-2._6ea5d9 {
  background-color: #6ea5d9;
}

.checkbox-2._0f3d84 {
  background-color: #0f3d84;
}

.checkbox-2._0f1e3c {
  background-color: #0f1e3c;
}

.checkbox-2._46276a {
  background-color: #46276a;
}

.checkbox-2._8a6aa7 {
  background-color: #8a6aa7;
}

.checkbox-2.b095c2 {
  background-color: #b095c2;
}

.checkbox-2.d4b106 {
  background-color: #d4b106;
}

.checkbox-2.fffff {
  background-color: #fff;
}

.checkbox-field {
  margin-bottom: 0;
  padding-left: 0;
}

.pane {
  height: auto;
  background-color: #fff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 15px;
  overflow: scroll;
}

.pane.design {
  border-radius: 15px 0 15px 15px;
  overflow: visible;
}

.pane.scroll {
  height: 100vh !important;
  overflow: initial !important;
}

.div-block-60 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 15px;
  display: grid;
}

.div-block-60.no-margin {
  margin-top: 15px;
  padding: 0;
}

.div-block-61 {
  height: 75px;
  cursor: pointer;
  border: 1px solid rgba(209, 209, 209, .45);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.div-block-61:hover {
  background-color: rgba(209, 209, 209, .32);
}

.dropdown-toggle-4 {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
}

.dropdown-3 {
  width: 340px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

.dropdown-3:hover {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-list-4 {
  top: -14.5px;
}

.dropdown-list-4.w--open {
  width: 340px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 0 0 10px 10px;
  top: 38.5px;
  left: -1px;
}

.grid-9 {
  grid-column-gap: 10px;
  grid-row-gap: 3px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-9._2row {
  grid-column-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  justify-items: flex-start;
}

.grid-9._2line {
  grid-template-columns: 1fr;
}

.image-81 {
  width: 18px;
  margin-bottom: 5px;
  margin-right: 0;
}

.div-block-62 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-63 {
  position: relative !important;
  width: 50%;
  height: 65%;
  cursor: pointer;
  background-color: #f7f7f7;
  background-image: none;
  background-position: 0 0;
  border: 1px solid #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .14);
}

.text-block-13 {
  font-size: 16px;
  font-weight: 600;
}

.div-block-65 {
  text-align: right;
  margin-right: 24px;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
}

.tabs-content {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  overflow: visible;
}

.design-wrapper {
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  overflow: hidden;
}

.div-block-68 {
  height: 230px ;
  min-height: 230px;
  background-color: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 15px;
}

.grid-10 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  justify-items: center;
}

.div-block-61-copy {
  height: auto;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  transition: all .2s;
  display: flex;
}

.div-block-61-copy:hover {
  background-color: rgba(209, 209, 209, .32);
  border-color: #d1d1d1;
}

.div-block-69 {
  width: 36px;
  height: 36px;
  background-color: #f8f8f8;
  margin-top: 15px;
  margin-bottom: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .15);
}

.div-block-69._12x16 {
  height: 48px;
}

.div-block-69._16x16 {
  width: 48px;
  height: 48px;
}

.div-block-69._16x20 {
  width: 48px;
  height: 60px;
}

.div-block-69._18x24 {
  width: 54px;
  height: 72px;
}

.div-block-69._24x36 {
  width: 72px;
  height: 108px;
  background-image: none;
}

.image-81-copy {
  width: 80px;
  margin-bottom: 15px;
  margin-right: 0;
}

.text-block-14 {
  font-weight: 500;
}

.div-block-62-copy {
  width: 100%;
  height: 100%;
  opacity: .65;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.div-block-62-copy:hover {
  opacity: 1;
}

.imagen-icon {
  width: 16px;
  margin-right: 10px;
}

.div-block-71 {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.div-block-74 {
  width: 100%;
  height: 140px;
  max-width: 1200px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px dashed rgba(51, 51, 51, .4);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
}

.div-block-75 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-top: 1px solid #d1d1d1;
  grid-template-rows: auto;
  grid-template-columns: 45px 2.5fr 1fr 100px auto;
  grid-auto-columns: 1fr;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all .2s;
  display: grid;
}

.div-block-75:hover {
  background-color: #ececec;
}

.div-block-75.external-store {
  grid-template-columns: 0 1.5fr 2.25fr;
}

.div-block-75.external-store.price-list {
  grid-template-columns: 0 330px 200px 1.5fr;
}

.text-block-standard {
  align-items: center;
  font-weight: 500;
  display: flex;
}

.text-block-standard.light {
  font-weight: 400;
}

.text-block-standard.semi-bold {
  font-size: 14px;
  font-weight: 600;
}

.div-block-79 {
  width: 70px;
  height: 70px;
  background-color: #f1f1f1;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.div-block-80 {
  display: flex;
}

.text-block-18 {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.div-block-81 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
  padding-left: 0;
  display: flex;
}

.text-block-19 {
  opacity: .6;
  font-size: 12px;
}

.pane-2 {
  background-color: #fff;
  border-radius: 15px;
  padding: 15px;
}

.div-block-75-copy {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-top: 1px #d1d1d1;
  border-bottom: 1px solid #d1d1d1;
  grid-template-rows: auto;
  grid-template-columns: 45px 2.5fr 1fr 100px auto;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-top: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid;
}

.div-block-75-copy.external-store {
  grid-template-columns: 0 330px 200px;
}

.tab-2 {
  height: 60px;
  background-color: rgba(0, 0, 0, 0);
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.tab-2.w--current {
  background-color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: none;
}

.tabs-menu-2 {
  display: flex;
}

.image-82 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tabs-content-2 {
  overflow: visible;
}

.div-block-82 {
  height: 456px;
  margin-top: -1px;
  overflow: scroll;
}

.text-input__field-wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.image-83 {
  width: 120px;
  margin-bottom: 20px;
  display: none;
}

.div-block-84 {
  grid-column-gap: 15px;
  grid-row-gap: 16px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
  display: grid;
}

.form-item-3 {
  letter-spacing: .16px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  font-size: .875rem;
  line-height: 1.125rem;
  display: flex;
}

.form-4 {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.div-block-7 {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid rgba(255, 255, 255, .23);
  padding: 30px 60px;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.div-block-7.black-upper-line {
  border-top-color: rgba(163, 163, 163, .23);
}

.image-66 {
  width: 45px;
}

.div-block-85 {
  width: auto;
  min-height: 400px;
  min-width: 300px;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px;
  display: flex;
  box-shadow: 0 15px 45px rgba(0, 0, 0, .09);
}

.div-block-85.frost-blur {
  z-index: 2;
  min-width: 350px;
  background-color: rgba(255, 255, 255, .64);
  padding-bottom: 60px;
  position: relative;
  box-shadow: 0 15px 45px rgba(0, 0, 0, .09);
}

.div-block-85.frost-blur.add-block {
  min-width: 390px;
  border-radius: 10px;
  justify-content: center;
  padding-bottom: 60px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .09);
}

.div-block-85.add-block {
  min-width: 390px;
  padding-bottom: 140px;
  position: relative;
}

.form-block-3 {
  width: 100%;
  flex: 0 auto;
  margin-bottom: 0;
}

.form-item-2 {
  letter-spacing: .16px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  font-size: .875rem;
  line-height: 1.125rem;
  display: flex;
}

.main-button {
  height: auto;
  color: #fff;
  letter-spacing: .5px;
  text-transform: none;
  background-color: #1d1d1d;
  border: 1px solid #181818;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 16px 32px;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  transition: all .2s cubic-bezier(.6, .04, .98, .335);
  display: flex;
}

.main-button:hover {
  filter: none;
  color: #e7e7e7;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}

.main-button:active {
  transform: scale(.96);
}

.block-wrap {
  height: 100%;
  background-color: #fff;
  grid-template-rows: auto;
  grid-template-columns: 240px 1fr;
  margin-top: 0;
  display: flex;
  overflow: visible;
}

.block-wrap.pane-2 {
  height: auto;
  max-width: 1200px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.pricing-head-table {
  height: 50px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
}

.pricing-head-table.grid {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto;
  grid-auto-columns: 1fr;
  align-content: center;
  justify-content: end;
  justify-items: end;
  padding-left: 0;
  display: grid;
}

.card-copy {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-radius: 0;
  flex: 0 auto;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1.25fr;
  grid-auto-columns: 1fr;
  margin-bottom: 10px;
  padding: 0;
  transition: all .2s;
  display: grid;
  position: relative;
}

.pricing-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1.25fr;
  grid-auto-columns: 1fr;
  padding: 10px 0;
  transition: all .2s;
  display: grid;
  position: relative;
}

.pricing-grid:hover {
  background-color: #f7f7f7;
}

.pricing-grid.sub {
  width: 100%;
  background-color: #ebebeb;
  border-radius: 5px;
  flex: 0 auto;
  grid-template-rows: auto;
  padding: 10px;
}

.number-input {
  width: 75px;
  text-align: center;
  background-color: #f5f5f5;
  border: 1px #d1d1d1;
  border-radius: 5px;
  margin-bottom: 0;
}

.number-input.white {
  width: 75px;
  height: 40px;
  background-color: #fff;
  border-style: none;
  border-radius: 10px;
}

.number-input.input {
  height: 40px;
  border-style: solid;
  border-color: #e9e9e9;
  font-weight: 300;
}

.form-block-4 {
  margin-bottom: 0;
}

.pricing-table {
  height: 50px;
  justify-content: center;
  align-items: center;
  padding: 15px;
  display: flex;
}

.div-block-87 {
  display: flex;
}

.sub-table-pricing {
  width: 100%;
  height: 566px;
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex: 100 auto;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  padding-top: 15px;
  display: grid;
  overflow: scroll;
}

.help-icon {
  width: 18px;
  height: 18px;
  opacity: .5;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100%;
  margin-left: 10px;
  margin-right: 0;
  padding-left: 2px;
  padding-right: 2px;
  transition: all .2s;
}

.help-icon:hover {
  opacity: 1;
  background-color: #e9e9e9;
}

.div-block-88 {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.grid-12 {
  grid-template-columns: 1fr;
  margin-top: 0;
}

.div-block-92 {
  width: 100%;
  max-width: 1200px;
  margin-top: 48px;
  position: relative;
}

.modal-wrapper-2 {
  z-index: 99999;
  perspective: 850px;
  cursor: default;
  background-color: rgba(0, 0, 0, .19);
  justify-content: center;
  align-items: center;
  padding: 40px;
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-block-copy {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 100%;
}

.modal-body-2 {
  z-index: 10;
  width: 450px;
  height: 450px;
  color: #181818;
  background-color: #fff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 60px;
  display: flex;
  position: relative;
  box-shadow: 0 20px 45px rgba(0, 0, 0, .13);
}

.modal-body-2.select {
  height: auto;
  flex-direction: column;
}

.div-block-93 {
  width: 100%;
}

.lottie-animation-5 {
  width: 75px;
  filter: invert();
  margin-bottom: 40px;
}

.text-block-6 {
  font-size: 18px;
  line-height: 165%;
}

.text-span {
  font-size: 24px;
  font-weight: 600;
}

.success-message {
  background-color: rgba(0, 0, 0, 0);
}

.inline-notification {
  height: auto;
  min-height: 3rem;
  min-width: 18rem;
  color: #fff;
  background-color: #242424;
  border-radius: 5px;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
}

.inline-notification.danger {
  text-align: left;
  background-color: #dc232b;
  border: 1px #000;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.inline-notification__details {
  align-items: flex-start;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}

.inline-notification__icon {
  flex: none;
  margin-right: 1rem;
  padding-top: 1px;
}

.inline-notification__icon-error {
  width: 20px;
  height: 20px;
  background-image: url('../../images/slash-2.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  margin-top: 0;
}

.inline-notification__text-wrapper {
  color: #fff;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

.inline-notification__title {
  letter-spacing: .16px;
  margin-right: .25rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}

.inline-notification__subtitle {
  letter-spacing: .16px;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
}

.error-message {
  background-color: rgba(0, 0, 0, 0);
  margin-top: 40px;
  padding: 0;
}

.icon-3 {
  width: 22px;
  height: 22px;
}

.icon-3.small {
  width: 18px;
  height: 18px;
}

.icon-3.small.x2 {
  width: 16px;
  height: 16px;
}

.icon-3.small.right-margin {
  margin-right: 10px;
}

.icon-3.small.left-margin {
  margin-left: 15px;
}

.icon-3.right-margin {
  margin-right: 10px;
}

.icon-3.big {
  width: 52px;
  height: 52px;
}

.img-order-summary-container {
  position: relative;
}

.form-block-filter {
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.filter-select-field {
  width: auto;
  height: 50px;
  min-width: auto;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 0;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 400;
  display: flex;
}

.filter-select-field.w--open {
  min-width: auto;
  background-color: #fff;
  border-radius: 5px;
}

.filter-select-field.radius {
  width: 50px;
  background-color: #fff;
  border-radius: 15px;
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 1px 3px -3px #000;
}

.grid-container-controls {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  justify-items: flex-start;
  padding: 0;
  display: grid;
}

.search-button-2 {
  width: 45px;
  height: 45px;
  opacity: .45;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0);
  background-image: url('../../images/magnifying-glass-bold-2.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 10px;
  margin-right: 0;
  transition: all .2s;
  position: static;
  top: auto;
  bottom: 4px;
  left: 3px;
  right: auto;
}

.search-button-2:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 0;
  position: static;
}

.div-block-94 {
  width: 10px;
  height: 10px;
  box-shadow: none;
  background-color: #85c737;
  border-radius: 100%;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.line-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(192, 192, 192, .51);
}

.navbar-2 {
  display: none;
}

.div-block-95 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.icon-4 {
  margin: 0 0 0 15px;
  position: relative;
}

.checkbox-3 {
  width: 16px;
  height: 16px;
  border-color: #a7a7a7;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
}

.checkbox-3.w--redirected-checked {
  background-color: #333;
  background-size: 11px;
}

.grid-sidebar {
  height: auto;
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  justify-items: flex-start;
  padding: 20px 0 0 15px;
  position: relative;
  overflow: visible;
}

.div-block-97 {
  border-bottom: 1px solid #f7f7f7;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 15px;
  display: flex;
}

.text-logo {
  letter-spacing: -.5px;
  font-size: 26px;
}

.div-block-98 {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  display: flex;
}

.checkbox-field-2 {
  color: #333;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  font-weight: 300;
  display: flex;
}

.checkbox-field-2:hover {
  color: #333;
}

.radio-button-field {
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.search-3 {
  background-color: #fff;
  border-radius: 15px;
  align-items: center;
  margin-bottom: 0;
  padding-left: 5px;
  display: flex;
  position: relative;
  box-shadow: 0 1px 3px -3px #000;
}

.grid-20 {
  width: 100%;
  height: 100vh;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  overflow: hidden;
}

.radio-button {
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  border-color: #a7a7a7;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
  padding-right: 0;
}

.radio-button.w--redirected-checked {
  border-color: #333;
}

.div-block-99 {
  padding-left: 10px;
  padding-right: 10px;
  display: block;
}

.input-2 {
  height: 50px;
  min-width: 230px;
  color: #a7a7a7;
  letter-spacing: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 10px;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 400;
}

.input-2::-ms-input-placeholder {
  color: #a7a7a7;
}

.input-2::placeholder {
  color: #a7a7a7;
}

.text-span-2 {
  font-weight: 600;
}

.brand {
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 0;
  font-size: 20px;
  display: flex;
}

.div-block-40 {
  width: 100%;
}

.sidebar-button {
  width: 100%;
  height: 50px;
  color: #686868;
  letter-spacing: 0;
  background-color: rgba(0, 0, 0, 0);
  border-right: 3px solid #fff;
  border-radius: 15px 0 0 15px;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 5px 9px 15px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
  display: flex;
  position: relative;
}

.sidebar-button:hover {
  color: #333;
  border-right-color: silver;
  padding-left: 25px;
}

.sidebar-button.w--current {
  color: #333;
  background-color: #ddd;
  border: 1px #000;
  border-right: 3px solid #fff;
}

.sidebar-button.logout:hover {
  color: #f5222d;
  border-right-color: #f5222d;
}

.sidebar-button.notification:hover {
  border-right-color: rgba(0, 0, 0, 0);
}

.logo-img {
  width: 42px;
  margin-right: 0;
  padding-bottom: 15px;
}

.grid-vertical-line {
  width: 1px;
  height: 100%;
  background-color: #f7f7f7;
}

.dropdown-4 {
  margin-left: 0;
  margin-right: 0;
  font-weight: 600;
}

.navbar-3 {
  width: 215px;
  height: 100vh;
  max-height: 100vh;
  min-height: 100vh;
  background-color: #fff;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  overflow: visible;
  box-shadow: 0 0 7px -1px rgba(0, 0, 0, .13);
}

.dropdown-list {
  background-color: #b67d7d;
}

.dropdown-list.w--open {
  min-width: 155px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px 0 0;
  box-shadow: 0 6px 9px rgba(0, 0, 0, .1);
}

.dropdown-list.service.w--open {
  min-width: 170px;
}

.dropdown-list.service.column.w--open {
  min-width: 265px;
  left: -75px;
}

.dropdown-list.paper-type.w--open {
  width: 100%;
  min-width: 220px;
}

.dropdown-list.sort.w--open {
  min-width: 167px;
}

.grid-filters {
  grid-column-gap: 1px;
  grid-row-gap: 0px;
  background-color: #fff;
  border-radius: 15px;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  box-shadow: 0 1px 3px -3px #000;
}

.container-info-filter {
  color: #686868;
  cursor: pointer;
  background-color: #fff;
  border-top: 1px solid #f7f7f7;
  padding: 15px;
  font-weight: 400;
  transition: all .2s;
  display: flex;
}

.container-info-filter:hover {
  background-color: #f7f7f7;
}

.container-info-filter.bg {
  color: #686868;
  background-color: #f7f7f7;
  align-items: center;
}

.container-info-filter.bg:hover {
  color: #202020;
}

.div-block-102 {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.form-3 {
  height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-23 {
  grid-column-gap: 0px;
  grid-template-rows: auto;
}

.div-block-105 {
  height: 100% !important;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.form-block-input {
  width: 100%;
  margin-bottom: 0;
}

.div-block-150 {
  width: auto;
  color: #686868;
  align-items: center;
  display: flex;
}

.label-container {
  width: auto;
  height: auto;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3px;
  display: flex;
}

.div-block-151 {
  width: 30px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.form-input {
  background-color: #f0f1f3;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 10px;
  position: relative;
  overflow: hidden;
}

.form-input.select-field {
  height: 44px;
  border: 2px solid rgba(0, 0, 0, 0);
  padding-left: 0;
  padding-right: 0;
  transition: all .35s;
  overflow: visible;
}

.form-input.select-field:hover {
  border-width: 2px;
  border-color: #e4ddfe;
}

.select-field-wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  align-items: center;
  margin-bottom: 0;
  padding-left: 5px;
  padding-right: 10px;
  transition: all .35s;
  display: flex;
}

.select-field-wrapper:hover {
  border-color: #3da9fc;
}

.input-container {
  width: auto;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: 1fr;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.input-container.paper-type {
  width: 100%;
  align-items: stretch;
}

.img-icon {
  width: 16px;
  opacity: 1;
  padding-top: 2px;
}

.sub-label-form {
  height: auto;
  opacity: 1;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 9px;
  font-weight: 600;
}

.form-select-field {
  z-index: 2;
  width: 100%;
  height: 100%;
  min-width: 90px;
  color: #191a24;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 0;
  padding: 6px 13px 6px 8px;
  font-size: 12px;
  transition: all .3s;
  display: flex;
  position: relative;
}

._2nd-button {
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: #333;
  border: 1px #ddd;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 3px -3px #000;
}

._2nd-button.main {
  width: auto;
  border-style: solid;
  border-color: #333;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  font-family: Mark simonson proxima nova alt, sans-serif;
}

.upgrade-button {
  width: auto;
  height: 50px;
  color: #333;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 3px -3px #000;
}

.upgrade-button:hover {
  background-color: #fff;
  box-shadow: 0 1px 3px -3px #000;
}

.div-block-152 {
  height: 100%;
  display: block;
  overflow: initial !important;
}

.text-table {
  font-size: 14px;
  font-weight: 600;
}

.top-grid {
  width: 100%;
  height: auto;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  justify-items: flex-start;
}

.top-grid.space-between {
  justify-content: space-between;
}

.navbar-wrapper {
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  padding-top: 24px;
  padding-bottom: 24px;
}

.text-block-31 {
  font-weight: 400;
}

.main-button-3 {
  height: auto;
  color: #fff;
  letter-spacing: .5px;
  text-transform: none;
  background-color: #181818;
  border: 1px solid #181818;
  border-radius: 10px;
  flex: 0 auto;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  padding: 18px 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  transition: all .2s cubic-bezier(.6, .04, .98, .335);
  display: flex;
}

.main-button-3:hover {
  filter: none;
  color: #e7e7e7;
  background-color: #4c4c4c;
  border-color: #4c4c4c;
}

.main-button-3:active {
  transform: scale(.96);
}

.main-button-3.more-margin {
  margin-top: 24px;
}

.main-button-3.transparent {
  color: rgba(51, 51, 51, .75);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.main-button-3.transparent:hover {
  color: #1d1d1d;
  background-color: rgba(209, 209, 209, .15);
}

.text-input-2 {
  width: 100%;
  height: 50px;
  background-color: #f7f7f7;
  border: 1px #000;
  border-radius: 5px;
  margin-bottom: 0;
  padding: 0 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  transition: all 70ms;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0);
}

.text-input-2:focus {
  box-shadow: none;
  border-bottom-color: rgba(0, 0, 0, 0);
}

.text-input-2::-ms-input-placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.text-input-2::placeholder {
  color: #8c8c8c;
  font-weight: 400;
}

.link-3 {
  color: rgba(36, 36, 36, .75);
  text-decoration: underline;
}

.link-3:hover {
  opacity: 1;
  color: #242424;
}

.eye-2 {
  width: 50px;
  height: 50px;
  opacity: .5;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
}

.eye-2:hover {
  opacity: 1;
}

.eye-2:active {
  transform: scale(.9);
}

.div-block-184 {
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-items: center;
  justify-items: center;
  margin-top: 0;
  margin-bottom: 0;
  display: grid;
}

.text-block-34 {
  opacity: 1;
  color: rgba(36, 36, 36, .5);
  font-size: 12px;
  font-weight: 400;
}

.screen-blocker {
  display: none;
}

.divider-2 {
  width: 100%;
  height: 10px;
}

.heading-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s;
}

.div-block-268 {
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.grid-sidebar-2 {
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  justify-items: flex-start;
  padding: 20px 0 0 15px;
  position: relative;
}

.grid-sidebar-2._2 {
  padding-left: 0;
}

.div-block-6 {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.div-block {
  padding-left: 10px;
  display: block;
}

.grid-25-copy {
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  align-content: center;
  align-items: center;
  justify-items: end;
}

.div-block-91-copy {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.div-block-310 {
  border-radius: 10px;
  overflow: hidden;
}

.dropdown-5 {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.image-86 {
  width: auto;
  height: 18px;
  margin-left: 0;
  margin-right: 0;
}

.image-86.right-margin {
  width: auto;
  height: 18px;
  margin-left: 0;
  margin-right: 10px;
}

.div-block-314 {
  z-index: -1;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: -11px;
  right: auto;
}

.div-block-296 {
  cursor: pointer;
  background-color: #e2e2e2;
  padding: 16px 24px;
  font-weight: 400;
  line-height: 100%;
  transition: all .2s;
}

.div-block-296:hover {
  background-color: #f0f1f3;
}

.div-block-313 {
  width: 20px;
  height: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  position: static;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: auto;
  transform: rotate(45deg);
}

.dropdown-list-5 {
  background-color: #fff;
  border-radius: 5px;
  display: none;
  overflow: hidden;
  box-shadow: 0 10px 40px rgba(152, 162, 172, .21);
}

.dropdown-list-5.w--open {
  border-radius: 10px;
  top: -52px;
  left: 86%;
  overflow: visible;
  box-shadow: 0 10px 40px rgba(0, 0, 0, .21);
}

.div-block-312 {
  border-radius: 10px;
  position: relative;
}

.div-block-311 {
  width: 100%;
  height: 1px;
  background-color: rgba(192, 192, 192, .66);
}

.div-store {
  align-items: center;
  display: flex;
}

.grid-24 {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.checkbox-5 {
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: 0;
  margin-left: 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.checkbox-5:hover {
  border-color: #1c8ef6;
}

.checkbox-5.w--redirected-checked {
  background-color: rgba(0, 0, 0, 0);
  background-image: url('../../images/circle-fill.svg');
  background-position: 24px;
  background-size: 24px;
  border-color: #9a78e6;
  box-shadow: inset 0 0 0 1px #9a78e6;
}

.checkbox-field-3 {
  height: 64px;
  max-height: 64px;
  background-image: url('../../images/circle-thin.svg');
  background-position: 24px;
  background-repeat: no-repeat;
  background-size: 24px;
  align-items: center;
  margin-bottom: 0;
  padding: 16px 32px 16px 64px;
  display: flex;
  position: relative;
}

.grid-25 {
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  grid-auto-columns: auto;
  align-content: center;
  align-items: center;
}

.checkbox-label-3 {
  display: none;
}

.text-block-35 {
  font-weight: 600;
}

.image-87 {
  width: 32px;
}

.div-block-315 {
  width: 45px;
  height: 45px;
}

.tabs-2 {
  width: 100%;
}

.grid-26 {
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: end;
  justify-items: end;
  padding-right: 16px;
}

.store-image {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

@media screen and (min-width: 1280px) {
  .card-frame {
    height: 10vw;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .main-wrapper {
    grid-column-gap: 30px;
  }

  .main-wrapper.scroll {
    padding-top: 20px;
    padding-bottom: 0;
  }

  .rating-div.margin-top {
    margin-top: -4px;
  }

  .dropdown-toggle-3.dropdown-toggle__inline {
    justify-content: flex-start;
  }

  .dropdown-toggle-3.dropdown-toggle__inline.align-left:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .product-name.designer {
    text-align: left;
  }

  .third-button.small {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(209, 209, 209, .35);
    border-radius: 10px;
    font-weight: 600;
  }

  .third-button.small:hover {
    background-color: rgba(209, 209, 209, .35);
    border-color: rgba(209, 209, 209, 0);
  }

  .third-button.small.fixed {
    opacity: 0;
    cursor: auto;
  }

  .div-block-47 {
    background-color: #f3f3f3;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .div-block-50.padding-15 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .div-block-50.padding-15.scroll {
    margin-top: 0;
  }

  .checkbox-label {
    font-size: 12px;
    line-height: 100%;
  }

  .form-block {
    margin-top: 3px;
  }

  .grid-design {
    width: 100%;
    height: auto;
  }

  .design-frame {
    height: 455px;
  }

  .tab {
    height: 55px;
    color: #333;
    font-size: 14px;
    font-weight: 600;
  }

  .grid-8.drop {
    background-color: #f0f1f3;
  }

  .pane.scroll {
    height: 550px;
  }

  .div-block-61 {
    font-size: 12px;
  }

  .dropdown-3 {
    height: 44px;
    background-color: #f0f1f3;
    border-color: #f0f1f3;
    border-radius: 10px;
    margin-top: 0;
    font-size: 12px;
  }

  .dropdown-list-4.w--open {
    border-color: #f0f1f3;
  }

  /* .div-block-68 {
    height: 150px;
    min-height: 150px;
  } */

  .sub-table-pricing {
    height: 641px;
  }

  .filter-select-field.radius {
    border-radius: 15px;
  }

  .grid-container-controls {
    justify-content: flex-start;
    padding-right: 20px;
  }

  .search-3 {
    border-radius: 15px;
  }

  .input-2 {
    min-width: 235px;
  }

  .div-block-40 {
    height: auto;
  }

  .sidebar-button.notification {
    display: none;
  }

  .dropdown-4 {
    display: block;
  }

  .grid-filters {
    border-radius: 15px;
  }

  .div-block-105 {
    height: 605px;
  }

  .div-block-150 {
    color: #686868;
  }

  .sub-label-form {
    opacity: 1;
    color: #686868;
  }

  .upgrade-button, .upgrade-button:hover {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 14px;
    font-weight: 400;
  }

  .main-button-3 {
    margin-left: 0;
    margin-right: 0;
    position: static;
  }
}

@media screen and (min-width: 1440px) {
  .card-frame {
    height: 10vw;
    background-color: rgba(0, 0, 0, 0);
    flex: 100 auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
  }

  .overflow-menu__link._3:hover {
    background-color: #eee;
  }

  .dropdown-toggle__icon.dropdown-toggle__icon-inline {
    background-size: 8px;
  }

  .main-wrapper {
    align-items: center;
  }

  .main-wrapper.scroll {
    padding-top: 0;
  }

  .main-wrapper.align-left {
    align-items: flex-start;
  }

  .card {
    background-color: #fff;
  }

  .third-button.small {
    font-weight: 400;
  }

  .third-button.small.line {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
  }

  .third-button.small.line.delete {
    opacity: .63;
    background-color: rgba(0, 0, 0, 0);
    border-color: #f1f1f1;
  }

  .third-button.small.line.delete:hover {
    opacity: 1;
    border-color: rgba(255, 0, 64, .54);
  }

  .grid-design {
    height: 10vw;
  }

  .design-frame {
    height: auto;
    max-height: none;
    min-height: 32.9vw;
  }

  .tab, .tabs-menu {
    height: 45px;
  }

  .pane.scroll {
    height: 44vw;
    overflow: scroll;
  }

  .tabs-content {
    height: 100%;
    overflow: scroll;
  }

  .div-block-68 {
    /* height: auto;
    min-height: auto; */
  }

  .div-block-62-copy {
    min-height: 340px;
  }

  .div-block-74 {
    background-color: rgba(0, 0, 0, 0);
    border: 1px dashed rgba(51, 51, 51, .4);
  }

  .div-block-75.external-store.price-list {
    grid-template-columns: 0 350px 200px 1.5fr;
  }

  .pane-2 {
    border-top-left-radius: 15px;
  }

  .div-block-75-copy.external-store {
    grid-template-columns: 0 350px 200px;
  }

  .tab-2.w--current {
    display: none;
  }

  .main-button {
    background-color: #1d1d1d;
    border-radius: 15px;
  }

  .block-wrap.pane-2 {
    width: 1020px;
    height: 100%;
    max-width: 1020px;
    overflow: visible;
  }

  .pricing-grid.sub {
    flex: 0 auto;
    align-items: center;
    padding: 10px;
  }

  .sub-table-pricing {
    height: 10vw;
    flex: 100 auto;
    overflow: scroll;
  }

  .div-block-88 {
    height: 100%;
    justify-content: center;
  }

  .modal-wrapper-2 {
    display: none;
  }

  .modal-body-2 {
    border-radius: 15px;
  }

  .icon-3.small.left-margin {
    margin-left: 15px;
  }

  .filter-select-field.radius {
    box-shadow: 0 1px 3px -3px #000;
  }

  .icon-4 {
    margin-top: -2px;
  }

  .div-block-98 {
    padding-bottom: 50px;
  }

  .grid-20 {
    max-height: 100vh;
    overflow: hidden;
  }

  .input-2 {
    min-width: 300px;
  }

  .dropdown-list.w--open {
    font-weight: 400;
  }

  .div-block-105 {
    height: 10vw;
    flex: 100 auto;
  }

  .select-field-wrapper, .input-container.paper-type {
    position: relative;
  }

  ._2nd-button.main {
    font-weight: 400;
  }

  .top-grid {
    grid-template-columns: auto auto 1fr;
  }

  .top-grid.space-between {
    grid-template-columns: auto 1fr;
    justify-content: space-between;
  }

  .navbar-wrapper {
    grid-template-rows: auto;
  }

  .grid-26 {
    justify-content: end;
    justify-items: end;
  }

  .highlight {
    color: #707070;
    margin-left: 5px;
    font-weight: 400;
  }
}

@media screen and (min-width: 1920px) {
  .card-frame {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .main-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .main-wrapper.scroll {
    justify-content: flex-start;
  }

  .design-frame {
    height: 710px;
  }

  .pane.scroll {
    height: 100%;
    max-height: 880px;
  }

  .div-block-68 {
    /* height: 230px;
    min-height: 230px; */
  }

  .imagen-icon {
    width: 24px;
  }

  .div-block-74, .div-block-92 {
    max-width: 1200px;
  }

  .div-block-98 {
    height: auto;
  }

  .grid-20 {
    grid-template-columns: auto 1fr;
  }

  .input-2 {
    min-width: 400px;
  }
}

@media screen and (max-width: 991px) {
  .heading-3 {
    font-size: 24px;
  }

  .card-frame {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  .image-card {
    min-height: 150px;
  }

  .rating-div {
    grid-column-gap: 1px;
  }

  .dropdown-toggle-3.dropdown-toggle__inline {
    flex-direction: row;
    font-size: 12px;
  }

  .text-block-9-copy {
    font-size: 12px;
  }

  .product-name {
    font-size: 14px;
  }

  .div-block-39 {
    margin-top: 3px;
  }

  .checkbox-label {
    font-size: 12px;
  }

  .breadcrumb-link {
    font-size: 14px;
    line-height: 100%;
  }

  .grid-design {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 300px 1fr;
  }

  .design-frame {
    height: 50vh;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  .navigator-design {
    padding-top: 10px;
    padding-right: 5px;
  }

  .div-block-61, .dropdown-3 {
    font-size: 12px;
  }

  .grid-9 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .div-block-63 {
    width: 80%;
    height: 65%;
  }

  .grid-10 {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto;
    font-size: 12px;
  }

  .div-block-69 {
    margin-top: 10px;
  }

  .text-block-18 {
    font-size: 14px;
  }

  .block-wrap {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    grid-template-columns: 200px 1fr;
  }

  .modal-wrapper-2 {
    display: none;
  }

  .icon-3.big {
    width: 52px;
    height: 52px;
  }

  .grid-sidebar {
    padding-left: 15px;
  }

  .grid-20 {
    display: flex;
  }

  .input-2 {
    width: 100%;
    max-width: 400px;
    min-width: 200px;
  }

  .sidebar-button {
    padding-left: 15px;
  }

  .navbar-3 {
    width: 250px;
    position: fixed;
  }

  .form-select-field {
    font-size: 12px;
  }

  .screen-blocker {
    z-index: 99999999;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #f7f7f7;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .div-block-267 {
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
    display: flex;
  }

  .heading-title {
    font-weight: 600;
  }

  .grid-sidebar-2 {
    padding-left: 15px;
  }
}

@media screen and (max-width: 767px) {
  .third-button {
    padding-right: 2px;
  }

  .div-block-57 {
    width: auto;
  }

  .form-block-3 {
    width: 80%;
  }

  .main-button, .main-button-3 {
    padding-right: 2px;
  }
}

@media screen and (max-width: 479px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .heading-3 {
    font-size: 18px;
    line-height: 130%;
  }

  .card-frame {
    height: auto;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-rows: auto;
    grid-template-columns: minmax(40%, 50%) minmax(40%, 50%);
    padding: 15px;
  }

  .input, .input:hover {
    padding-left: 15px;
  }

  .dropdown-list-2.w--open {
    border-top-right-radius: 10px;
  }

  .dropdown-item {
    width: 140px;
  }

  .dropdown {
    height: 45px;
    margin-top: 10px;
    display: none;
  }

  .dropdown.mobile {
    display: block;
  }

  .dropdown-toggle__icon.dropdown-toggle__icon-inline {
    width: 12px;
    height: 12px;
    min-height: 12px;
    min-width: 12px;
    background-size: 8px;
    margin-right: 5px;
    font-size: 12px;
  }

  .main-wrapper {
    margin-top: 60px;
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .card:hover {
    box-shadow: 0 2px 3px rgba(29, 29, 29, .15);
  }

  .image-card {
    min-height: 150px;
  }

  .product-content-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    display: flex;
  }

  .rating-div {
    grid-column-gap: 2px;
    grid-template-rows: 12px;
    grid-template-columns: 12px 12px 12px 12px 12px;
    grid-auto-rows: 12px;
    grid-auto-columns: 12px;
    margin-bottom: 5px;
  }

  .rating-image {
    width: 12px;
  }

  .dropdown-toggle-3.dropdown-toggle__inline {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .text {
    font-size: 10px;
    line-height: 120%;
  }

  .text-block-9 {
    font-size: 12px;
    line-height: 100%;
  }

  .text-block-9-copy {
    font-size: 10px;
    line-height: 100%;
  }

  .text-block-9-copy.line {
    margin-left: 3px;
    margin-right: 3px;
  }

  .delivery-details-div {
    grid-column-gap: 7.5px;
  }

  .image-77 {
    width: 15px;
    height: 15px;
    border-radius: 100%;
  }

  .product-name {
    font-size: 11px;
    line-height: 150%;
  }

  .product-name.designer {
    font-size: 18px;
    line-height: 140%;
  }

  .third-button {
    padding-right: 32px;
  }

  .third-button:hover {
    text-decoration: none;
  }

  .div-block-45 {
    flex-direction: row;
  }

  .price-details-div {
    font-size: 11px;
    line-height: 100%;
  }

  .div-block-46 {
    padding: 3px 7px;
    font-size: 9px;
  }

  .lottie-heart {
    width: 50px;
    height: 50px;
    min-height: 60px;
    min-width: 60px;
  }

  .breadcrumb {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .breadcrumb-link {
    font-size: 11px;
  }

  .modal-closer.musea {
    background-color: rgba(0, 0, 0, 0);
  }

  .body-full {
    height: auto;
    padding: 20px;
  }

  .info-div {
    width: 100%;
    padding: 40px 20px;
  }

  .install-logos-div {
    max-width: none;
    margin-top: 20px;
  }

  .div-block-57 {
    width: auto;
  }

  .div-block-59 {
    max-width: none;
  }

  .grid-7 {
    width: 100%;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: column-reverse;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    display: grid;
  }

  .grid-design {
    flex-direction: column-reverse;
    margin-top: 40px;
    display: flex;
  }

  .design-frame {
    height: 300px;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-rows: auto;
    grid-template-columns: minmax(40%, 50%) minmax(40%, 50%);
    padding: 15px 15px 0;
  }

  .navigator-design {
    height: auto;
    flex-direction: row;
    margin-top: 20px;
    padding-right: 0;
    display: flex;
  }

  .pane.scroll {
    height: auto;
  }

  .div-block-63 {
    width: 80%;
  }

  .div-block-65 {
    text-align: center;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .div-block-68 {
    padding: 0;
    display: flex;
    overflow: scroll;
  }

  .imagen-icon.mobile-margin {
    margin-right: 0;
  }

  .div-block-71 {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .div-block-74 {
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .div-block-75 {
    grid-template-columns: 45px 2.5fr;
    font-size: 10px;
  }

  .text-block-standard.semi-bold {
    font-size: 14px;
    line-height: 140%;
  }

  .div-block-79 {
    width: 55px;
    height: 55px;
  }

  .text-block-18 {
    font-size: 12px;
  }

  .text-block-19 {
    font-size: 10px;
  }

  .div-block-75-copy {
    display: none;
  }

  .tab-2 {
    padding-left: 14px;
    padding-right: 14px;
  }

  .div-block-84 {
    flex-direction: column;
    margin-bottom: 35px;
    display: flex;
  }

  .form-item-3 {
    text-align: center;
    flex-direction: column;
  }

  .div-block-7 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
  }

  .image-66 {
    width: 45px;
    margin-bottom: 10px;
  }

  .div-block-85, .div-block-85.frost-blur {
    padding: 40px 30px;
  }

  .div-block-85.frost-blur.add-block, .div-block-85.add-block {
    width: 100%;
    min-width: auto;
  }

  .form-block-3 {
    width: 100%;
  }

  .main-button {
    padding-right: 32px;
  }

  .block-wrap {
    flex-direction: column;
    display: flex;
  }

  .block-wrap.pane-2 {
    width: 100%;
    overflow: scroll;
  }

  .pricing-head-table {
    padding-left: 10px;
    padding-right: 10px;
  }

  .pricing-head-table.grid {
    width: 300px;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-rows: auto;
    grid-template-columns: auto auto auto;
    grid-auto-columns: 1fr;
    justify-items: flex-start;
    display: grid;
  }

  .card-copy {
    width: 100%;
    grid-template-columns: 130px 130px 130px 300px;
  }

  .pricing-grid {
    width: 100%;
    grid-template-columns: 1fr 130px 130px 300px;
  }

  .pricing-grid.sub {
    width: auto;
    background-color: rgba(0, 0, 0, 0);
    flex: none;
    grid-template-columns: 130px 130px 130px auto;
    justify-content: flex-start;
    align-self: stretch;
  }

  .pricing-grid.sub:hover {
    box-shadow: none;
  }

  .sub-table-pricing {
    width: 100%;
  }

  .help-icon.tippy {
    margin-left: 5px;
  }

  .div-block-92 {
    width: 100%;
    max-width: none;
  }

  .modal-wrapper-2 {
    background-color: rgba(24, 24, 24, .86);
    padding: 20px;
    display: none;
  }

  .text-block-copy {
    text-align: center;
    font-size: 24px;
  }

  .modal-body-2 {
    width: auto;
    padding: 60px 30px;
  }

  .modal-body-2.select {
    width: 100%;
  }

  .inline-notification.danger {
    position: static;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .inline-notification__icon-error {
    width: 18px;
  }

  .inline-notification__title, .inline-notification__subtitle {
    font-size: 12px;
  }

  .icon-3 {
    width: 18px;
    height: 18px;
  }

  .icon-3.small, .icon-3.small.right-margin {
    width: 14px;
    height: 14px;
  }

  .icon-3.right-margin {
    width: 18px;
    height: 18px;
  }

  .div-block-35 {
    width: 100%;
    height: 60px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-content: space-between;
    display: grid;
  }

  .grid-container-controls {
    display: none;
  }

  .navbar-2 {
    background-color: #fff;
    justify-content: space-between;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    box-shadow: 0 1px 3px -3px #000;
  }

  .div-block-96 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    justify-items: end;
    display: grid;
  }

  .grid-sidebar {
    justify-items: center;
    padding-left: 0;
  }

  .text-logo {
    font-size: 20px;
  }

  .div-block-98 {
    justify-content: flex-start;
  }

  .grid-20 {
    height: auto;
    grid-template-columns: 1fr;
    position: relative;
    overflow: visible;
  }

  .div-block-36 {
    width: 40px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .nav-menu-2 {
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: 60px;
  }

  .brand {
    height: 60px;
    flex-direction: row;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .sidebar-button {
    width: auto;
    min-width: 150px;
    border: 1px #000;
    border-radius: 15px;
    justify-content: center;
    padding-right: 20px;
  }

  .sidebar-button:hover {
    padding-right: 25px;
  }

  .sidebar-button.w--current {
    border: 1px #000;
  }

  .logo-img {
    width: 32px;
    margin-right: 7.5px;
    padding-bottom: 0;
  }

  .navbar-3 {
    display: none;
  }

  .menu-button-2 {
    background-color: rgba(0, 0, 0, 0);
  }

  .menu-button-2.w--open {
    width: 60px;
    height: 60px;
    color: #333;
    background-color: #fff;
  }

  .input-container.paper-type {
    align-items: flex-start;
  }

  .sub-label-form {
    font-size: 8px;
  }

  .form-select-field {
    font-size: 12px;
  }

  ._2nd-button.main {
    height: 47px;
    font-size: 14px;
  }

  .upgrade-button {
    width: 47px;
    height: 47px;
    padding-left: 0;
    padding-right: 0;
  }

  .text-block-30 {
    font-size: 0;
  }

  .text-table {
    font-size: 12px;
  }

  .main-button-3 {
    padding-right: 32px;
  }

  .screen-blocker {
    z-index: 999999;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }

  .div-block-267 {
    width: 100%;
    max-width: 250px;
  }

  .heading-title {
    font-size: 20px;
    line-height: 140%;
  }

  .grid-sidebar-2 {
    justify-items: center;
    padding-left: 0;
  }

  .div-block-6 {
    justify-content: flex-start;
  }

  .div-block-91-copy {
    grid-template-columns: 1fr;
  }
}

#w-node-_2eab6c35-d857-7169-d069-5241949668b2-c3bda992, #w-node-_2eab6c35-d857-7169-d069-5241949668b7-c3bda992, #w-node-_2eab6c35-d857-7169-d069-5241949668bc-c3bda992, #w-node-_2eab6c35-d857-7169-d069-5241949668c1-c3bda992, #w-node-_2eab6c35-d857-7169-d069-5241949668ca-c3bda992, #w-node-d0cb4847-2066-53d3-8308-c482596abafb-c3bda992 {
  align-self: center;
}

#w-node-fc2ca85d-8024-a2bd-237a-a0dbbc8671d3-c3bda992 {
  justify-self: end;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a29-c3bda992, #w-node-bd7deded-1331-0d55-1087-7c4906c50a2a-c3bda992, #w-node-bd7deded-1331-0d55-1087-7c4906c50a2b-c3bda992, #w-node-bd7deded-1331-0d55-1087-7c4906c50a2c-c3bda992, #w-node-bd7deded-1331-0d55-1087-7c4906c50a2d-c3bda992, #w-node-bd7deded-1331-0d55-1087-7c4906c50a3a-c3bda992 {
  align-self: center;
  justify-self: center;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a3c-c3bda992 {
  justify-self: end;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a41-c3bda992 {
  align-self: center;
  justify-self: center;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a43-c3bda992 {
  justify-self: end;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a48-c3bda992 {
  align-self: center;
  justify-self: center;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a4a-c3bda992 {
  justify-self: end;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a4f-c3bda992 {
  align-self: center;
  justify-self: center;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a51-c3bda992 {
  justify-self: end;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a56-c3bda992 {
  align-self: center;
  justify-self: center;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50a58-c3bda992 {
  justify-self: end;
}

#w-node-bd7deded-1331-0d55-1087-7c4906c50c0b-c3bda992 {
  justify-self: stretch;
}

#w-node-_7864740c-0b2a-98c9-a3b5-7845ed3bcd7a-a6ba670f, #w-node-_7864740c-0b2a-98c9-a3b5-7845ed3bcd7f-a6ba670f, #w-node-_7864740c-0b2a-98c9-a3b5-7845ed3bcd84-a6ba670f, #w-node-_7864740c-0b2a-98c9-a3b5-7845ed3bcda7-a6ba670f, #w-node-_7864740c-0b2a-98c9-a3b5-7845ed3bcdb2-a6ba670f, #w-node-_7864740c-0b2a-98c9-a3b5-7845ed3bcdb7-a6ba670f {
  align-self: center;
}

#w-node-_82d4b72e-5343-5914-b47b-81f072b1d2ea-c3bda993 {
  justify-self: center;
}

#w-node-ba3d6cc3-75d9-d192-6401-98bfead6aadb-adbda994, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aae0-adbda994, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aae5-adbda994, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aaea-adbda994, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aaf3-adbda994, #w-node-_919c5dd5-b55e-3905-4218-c7d326eab2c8-adbda994 {
  align-self: center;
}

#w-node-b0d59639-ea0e-7cbf-7864-433076216406-adbda994, #w-node-_43d9d116-0eca-1bbb-dedd-a49179921fe1-adbda994, #w-node-a7bc52f5-6ec1-cffd-a951-0e4df6de2bf4-adbda994 {
  justify-self: end;
}

#w-node-_7eca6648-06e2-4776-9997-23333dfe40a2-adbda994 {
  grid-area: 1 / 5 / 2 / 6;
}

#w-node-_01a68949-69ea-ee64-18f1-83c16a4de3b2-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d35-adbda994, #w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d39-adbda994, #w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d3d-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-aeff5017-70d3-5527-b0e2-6e5d2576fd99-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_2c507577-29cc-7cf9-7d4d-9555c301a186-adbda994, #w-node-_2c507577-29cc-7cf9-7d4d-9555c301a18a-adbda994, #w-node-_2c507577-29cc-7cf9-7d4d-9555c301a18e-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_8e1e1ee1-7e90-a3bf-e22c-7423f899fbe8-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-db5ede6b-53b5-d774-4347-40a22520723e-adbda994, #w-node-db5ede6b-53b5-d774-4347-40a225207242-adbda994, #w-node-db5ede6b-53b5-d774-4347-40a225207246-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_190f6559-d829-8e02-d603-7d7fb9f9c989-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_99ee872b-ff1a-3fd0-8a25-34dab6d9db88-adbda994, #w-node-_99ee872b-ff1a-3fd0-8a25-34dab6d9db8c-adbda994, #w-node-_99ee872b-ff1a-3fd0-8a25-34dab6d9db90-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_7b8186a5-e1f7-e3b8-cc17-0bfe5c53c3cd-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-e8cee379-e3da-d714-f43c-d7d2fc3cab91-adbda994, #w-node-e8cee379-e3da-d714-f43c-d7d2fc3cab95-adbda994, #w-node-e8cee379-e3da-d714-f43c-d7d2fc3cab99-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_1126f722-3892-927f-89e2-d54082e4a8e1-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_3e7cd833-978f-e0f9-0d04-babf2d587155-adbda994, #w-node-_3e7cd833-978f-e0f9-0d04-babf2d587159-adbda994, #w-node-_3e7cd833-978f-e0f9-0d04-babf2d58715d-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_8f5e2562-86ea-da1a-7005-1ca848c791f8-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_5dc746f0-6a40-9c0b-4e28-a7636c27f091-adbda994, #w-node-_5dc746f0-6a40-9c0b-4e28-a7636c27f095-adbda994, #w-node-_1eaf7140-68f5-7ce9-ffa6-fd70fd5e3ee6-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-dee51b15-6952-a6d0-309c-3252a2836de2-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-dc96ad23-d5b2-5951-85d8-b941769d57ab-adbda994, #w-node-dc96ad23-d5b2-5951-85d8-b941769d57af-adbda994, #w-node-aeee84b8-252d-985e-47fa-870cda4cb71f-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-ee8039fd-38aa-afad-5572-f4aace6030d9-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-f3df62ae-0530-7f03-db2f-b5cc5be965fd-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-f3df62ae-0530-7f03-db2f-b5cc5be965fe-adbda994, #w-node-f3df62ae-0530-7f03-db2f-b5cc5be96602-adbda994, #w-node-c4e90651-f2f5-39e6-1e49-313ccf308a78-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_21a8b8d7-1709-8288-19d8-23fa4cf469f9-adbda994 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_7e99dc38-4939-c321-8e0d-2768f66fb5e7-adbda994, #w-node-_7e99dc38-4939-c321-8e0d-2768f66fb5eb-adbda994, #w-node-_7e99dc38-4939-c321-8e0d-2768f66fb5ef-adbda994 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_384883ce-1466-601b-3e99-4840b4cf4322-2bbda995, #w-node-_384883ce-1466-601b-3e99-4840b4cf4327-2bbda995, #w-node-_384883ce-1466-601b-3e99-4840b4cf432c-2bbda995, #w-node-_384883ce-1466-601b-3e99-4840b4cf4331-2bbda995, #w-node-_384883ce-1466-601b-3e99-4840b4cf433a-2bbda995, #w-node-fd41b655-37fb-d06c-6b9b-ba7a049b6542-2bbda995 {
  align-self: center;
}

#w-node-fd41b655-37fb-d06c-6b9b-ba7a049b654b-2bbda995 {
  justify-self: end;
}

#w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5422-2bbda995 {
  align-self: center;
  justify-self: end;
}

#w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5423-2bbda995 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5432-2bbda995 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_6054aebc-eac1-ab08-7e5e-4d644564976a-b2bda996, #w-node-_6054aebc-eac1-ab08-7e5e-4d644564976f-b2bda996, #w-node-_6054aebc-eac1-ab08-7e5e-4d6445649774-b2bda996, #w-node-_6054aebc-eac1-ab08-7e5e-4d6445649779-b2bda996, #w-node-_6054aebc-eac1-ab08-7e5e-4d6445649782-b2bda996, #w-node-fc155a37-176e-cb12-a365-67256bc46636-b2bda996 {
  align-self: center;
}

#w-node-_4964c77a-cd44-1f98-8ba6-d9fde9d5b74e-b2bda996 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa81398-b2bda996, #w-node-_2b9a0a74-be0d-a730-1d78-24814fa81399-b2bda996, #w-node-_2b9a0a74-be0d-a730-1d78-24814fa8139a-b2bda996, #w-node-_2b9a0a74-be0d-a730-1d78-24814fa8139b-b2bda996, #w-node-_2b9a0a74-be0d-a730-1d78-24814fa8139c-b2bda996, #w-node-_2b9a0a74-be0d-a730-1d78-24814fa813a9-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813ab-b2bda996 {
  justify-self: end;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b0-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b2-b2bda996 {
  justify-self: end;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b7-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813b9-b2bda996 {
  justify-self: end;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813be-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813c0-b2bda996 {
  justify-self: end;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813c5-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813c7-b2bda996 {
  justify-self: end;
}

#w-node-_2b9a0a74-be0d-a730-1d78-24814fa813dd-b2bda996 {
  align-self: center;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f53f-b2bda996, #w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f540-b2bda996, #w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f541-b2bda996, #w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f542-b2bda996, #w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f543-b2bda996, #w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f550-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f552-b2bda996 {
  justify-self: end;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f557-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f559-b2bda996 {
  justify-self: end;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f55e-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f560-b2bda996 {
  justify-self: end;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f565-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f567-b2bda996 {
  justify-self: end;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f56c-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f56e-b2bda996 {
  justify-self: end;
}

#w-node-_574a35fb-8d5a-b21b-39e0-9d2b2826f584-b2bda996 {
  align-self: center;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e430546a-b2bda996, #w-node-e3bcdb2e-4418-4872-5524-8b88e430546b-b2bda996, #w-node-e3bcdb2e-4418-4872-5524-8b88e430546c-b2bda996, #w-node-e3bcdb2e-4418-4872-5524-8b88e430546d-b2bda996, #w-node-e3bcdb2e-4418-4872-5524-8b88e430546e-b2bda996, #w-node-e3bcdb2e-4418-4872-5524-8b88e430547b-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e430547d-b2bda996 {
  justify-self: end;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305482-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305484-b2bda996 {
  justify-self: end;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305489-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e430548b-b2bda996 {
  justify-self: end;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305490-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305492-b2bda996 {
  justify-self: end;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305497-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e4305499-b2bda996 {
  justify-self: end;
}

#w-node-e3bcdb2e-4418-4872-5524-8b88e43054af-b2bda996 {
  align-self: center;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b965-b2bda996, #w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b966-b2bda996, #w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b967-b2bda996, #w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b968-b2bda996, #w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b969-b2bda996, #w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b976-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b978-b2bda996 {
  justify-self: end;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b97d-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b97f-b2bda996 {
  justify-self: end;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b984-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b986-b2bda996 {
  justify-self: end;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b98b-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b98d-b2bda996 {
  justify-self: end;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b992-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b994-b2bda996 {
  justify-self: end;
}

#w-node-_1a756679-1a2f-b770-4fb8-b8d9be25b9aa-b2bda996 {
  align-self: center;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcc6-b2bda996, #w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcc7-b2bda996, #w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcc8-b2bda996, #w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcc9-b2bda996, #w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcca-b2bda996, #w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcd7-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcd9-b2bda996 {
  justify-self: end;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcde-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dce0-b2bda996 {
  justify-self: end;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dce5-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dce7-b2bda996 {
  justify-self: end;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcec-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcee-b2bda996 {
  justify-self: end;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcf3-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dcf5-b2bda996 {
  justify-self: end;
}

#w-node-_5d989a12-d09b-d078-e173-5ed6a9c2dd0b-b2bda996 {
  align-self: center;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865effe-b2bda996, #w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865efff-b2bda996, #w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f000-b2bda996, #w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f001-b2bda996, #w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f002-b2bda996, #w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f00f-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f011-b2bda996 {
  justify-self: end;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f016-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f018-b2bda996 {
  justify-self: end;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f01d-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f01f-b2bda996 {
  justify-self: end;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f024-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f026-b2bda996 {
  justify-self: end;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f02b-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f02d-b2bda996 {
  justify-self: end;
}

#w-node-_32d3cd78-a472-f8b2-7ed4-d0d81865f043-b2bda996 {
  align-self: center;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb49-b2bda996, #w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb4a-b2bda996, #w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb4b-b2bda996, #w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb4c-b2bda996, #w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb4d-b2bda996, #w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb5a-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb5c-b2bda996 {
  justify-self: end;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb61-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb63-b2bda996 {
  justify-self: end;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb68-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb6a-b2bda996 {
  justify-self: end;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb6f-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb71-b2bda996 {
  justify-self: end;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb76-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb78-b2bda996 {
  justify-self: end;
}

#w-node-_5fe16de1-2c9d-6b87-8b84-cf95eae6cb8e-b2bda996 {
  align-self: center;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c99b-b2bda996, #w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c99c-b2bda996, #w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c99d-b2bda996, #w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c99e-b2bda996, #w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c99f-b2bda996, #w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9ac-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9ae-b2bda996 {
  justify-self: end;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9b3-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9b5-b2bda996 {
  justify-self: end;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9ba-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9bc-b2bda996 {
  justify-self: end;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9c1-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9c3-b2bda996 {
  justify-self: end;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9c8-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9ca-b2bda996 {
  justify-self: end;
}

#w-node-_6bfb9d31-6829-ce60-b85c-7ef01e49c9e0-b2bda996 {
  align-self: center;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097a8-b2bda996, #w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097a9-b2bda996, #w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097aa-b2bda996, #w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097ab-b2bda996, #w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097ac-b2bda996, #w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097b9-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097bb-b2bda996 {
  justify-self: end;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097c0-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097c2-b2bda996 {
  justify-self: end;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097c7-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097c9-b2bda996 {
  justify-self: end;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097ce-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097d0-b2bda996 {
  justify-self: end;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097d5-b2bda996 {
  align-self: center;
  justify-self: center;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097d7-b2bda996 {
  justify-self: end;
}

#w-node-_2cc48ee2-fde7-4896-4fe7-4457a42097ed-b2bda996, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aadb-2b697401, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aae0-2b697401, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aae5-2b697401, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aaea-2b697401, #w-node-ba3d6cc3-75d9-d192-6401-98bfead6aaf3-2b697401, #w-node-_919c5dd5-b55e-3905-4218-c7d326eab2c8-2b697401 {
  align-self: center;
}

#w-node-_7eca6648-06e2-4776-9997-23333dfe40a2-2b697401 {
  grid-area: 1 / 5 / 2 / 6;
}

#w-node-b9d0e18d-9295-f873-0cb3-17575d74b8cf-2b697401 {
  justify-self: center;
}

#w-node-_01a68949-69ea-ee64-18f1-83c16a4de3b2-2b697401 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-a41a7d88-c19d-dc9e-873a-6a9f4804a9e7-2b697401 {
  align-self: center;
  justify-self: center;
}

#w-node-_4cd97771-42f6-4762-282b-356bd2328ede-2b697401, #w-node-_4cd97771-42f6-4762-282b-356bd2328ee2-2b697401, #w-node-_4cd97771-42f6-4762-282b-356bd2328ee6-2b697401 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-aeff5017-70d3-5527-b0e2-6e5d2576fd99-2b697401 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-fa5920e1-4013-5828-bb2b-51351065e387-2b697401 {
  align-self: center;
  justify-self: center;
}

#w-node-_2c507577-29cc-7cf9-7d4d-9555c301a186-2b697401, #w-node-_2c507577-29cc-7cf9-7d4d-9555c301a18a-2b697401, #w-node-_2c507577-29cc-7cf9-7d4d-9555c301a18e-2b697401 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

#w-node-_8e1e1ee1-7e90-a3bf-e22c-7423f899fbe8-2b697401 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_00c6e758-8753-55a4-e452-6a32ae504b60-2b697401 {
  align-self: center;
  justify-self: center;
}

#w-node-db5ede6b-53b5-d774-4347-40a22520723e-2b697401, #w-node-db5ede6b-53b5-d774-4347-40a225207242-2b697401, #w-node-db5ede6b-53b5-d774-4347-40a225207246-2b697401 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
}

@media screen and (min-width: 1440px) {
  #w-node-fc2ca85d-8024-a2bd-237a-a0dbbc8671d3-c3bda992 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: end;
  }

  #w-node-_919c5dd5-b55e-3905-4218-c7d326eab2c8-adbda994, #w-node-_5d48a63f-509c-b40c-26c2-ea8c45c34d34-adbda994 {
    justify-self: end;
  }

  #w-node-_1eaf7140-68f5-7ce9-ffa6-fd70fd5e3ee6-adbda994 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
  }

  #w-node-dee51b15-6952-a6d0-309c-3252a2836de2-adbda994 {
    grid-area: 1 / 2 / 2 / 3;
  }

  #w-node-aeee84b8-252d-985e-47fa-870cda4cb71f-adbda994 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
  }

  #w-node-ee8039fd-38aa-afad-5572-f4aace6030d9-adbda994 {
    grid-area: 1 / 2 / 2 / 3;
  }

  #w-node-c4e90651-f2f5-39e6-1e49-313ccf308a78-adbda994 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: center;
  }

  #w-node-fd41b655-37fb-d06c-6b9b-ba7a049b654b-2bbda995 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    justify-self: end;
  }

  #w-node-_919c5dd5-b55e-3905-4218-c7d326eab2c8-2b697401 {
    justify-self: end;
  }

  #w-node-a41a7d88-c19d-dc9e-873a-6a9f4804a9e7-2b697401 {
    align-self: center;
    justify-self: center;
  }

  #w-node-_4cd97771-42f6-4762-282b-356bd2328edd-2b697401 {
    justify-self: end;
  }
}

@media screen and (min-width: 1280px) {
  #w-node-_4964c77a-cd44-1f98-8ba6-d9fde9d5b74e-b2bda996 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}

@media screen and (max-width: 991px) {
  #w-node-d0cb4847-2066-53d3-8308-c482596abaf8-c3bda992 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    justify-self: center;
  }

  #w-node-d0cb4847-2066-53d3-8308-c482596abafb-c3bda992 {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: flex-start;
  }

  #w-node-_1b06cc8c-1099-711e-2d5b-42f0252b2714-adbda994, #w-node-fd41b655-37fb-d06c-6b9b-ba7a049b653f-2bbda995 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    justify-self: center;
  }

  #w-node-fd41b655-37fb-d06c-6b9b-ba7a049b6542-2bbda995 {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: flex-start;
  }

  #w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5423-2bbda995, #w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5432-2bbda995 {
    grid-area: 1 / 1 / 2 / 2;
  }

  #w-node-fbfc016e-dba6-8fbd-9065-930ed740fcea-b2bda996 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    justify-self: center;
  }

  #w-node-fc155a37-176e-cb12-a365-67256bc46636-b2bda996 {
    grid-area: 1 / 1 / 2 / 2;
    justify-self: flex-start;
  }

  #w-node-_1b06cc8c-1099-711e-2d5b-42f0252b2714-2b697401 {
    grid-area: span 1 / span 3 / span 1 / span 3;
    justify-self: center;
  }
}

@media screen and (max-width: 479px) {
  #w-node-e36e38dd-fa67-7ec0-03e0-6e95481a907b-c3bda993 {
    grid-area: 1 / 1 / 2 / 2;
  }

  #w-node-e4dcc409-16f7-57d7-4e57-5745e5fd541c-2bbda995 {
    justify-self: auto;
  }

  #w-node-e4dcc409-16f7-57d7-4e57-5745e5fd5432-2bbda995 {
    grid-column-start: 2;
  }
}